import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-presentation-video1',
  templateUrl: './presentation-video1.component.html',
  styleUrls: ['./presentation-video1.component.scss'],
})
export class PresentationVideo1Component implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  public scrollTToFormulaireDevis(): void {
    let element = document.getElementById('formulaireDevis');
    element.scrollIntoView(true);
  }
}
