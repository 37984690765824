import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service.ts.service';

@Component({
  selector: 'app-header-title',
  templateUrl: './header-title.component.html',
  styleUrls: ['./header-title.component.scss'],
})
export class HeaderTitleComponent implements OnInit {
  constructor(
    private viewportScroller: ViewportScroller,
    private data: DataService
  ) {}

  ngOnInit(): void {}

  public showFormulaireInscription(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
    setTimeout(() => {
      this.data.changeStateFormulaireInscription(true);
    }, 600);
  }

  showSidebar() {
    this.data.changeStateSidebar(true);
    return false;
  }
}
