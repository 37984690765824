<div class="container-presentation-postes-a-pourvoir">
  <div class="div-list-avantages">
    <div class="item-avantage">
      <i class="fas fa-hand-holding-usd"></i>
      <div>
        Rémunération <span class="accent">SMIC + 20%</span> (soit 12€/heure)
      </div>
    </div>

    <div class="item-avantage">
      <i class="far fa-clock"></i>
      <div>
        A tout moment,
        <span class="accent">toute la souplesse dont vous avez besoin</span>
      </div>
    </div>

    <div class="item-avantage">
      <i class="fab fa-youtube"></i>
      <div>
        Des formations et un
        <span class="accent">accompagnement permanent</span>
      </div>
    </div>

    <div class="item-avantage">
      <i class="fas fa-mobile-alt"></i>
      <div>
        <span class="accent">Tous les services en ligne, </span> pour vous
        simplifier le job !
      </div>
    </div>
  </div>
</div>
