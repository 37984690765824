<!-- <div class="container-image-presentation">
  <h2>Wanteez révolutionne les services à la personne</h2>
  <img
    [@fadeInOnEnter]
    src="/assets/header-presentation/presentation_wanteez.png"
  />
  <div class="container-btn-demande-devis mt-3">
    <button class="btn btn-primary" (click)="goToDemandeDevis('hautFormDevis')">
      Je demande un devis
    </button>
  </div>
</div> -->

<div class="bg-primary text-center">
  <h2 class="mt-5 mb-0 pt-4 text-light">Demander un devis dès maintenant</h2>
</div>
<div class="container-search-offres" id="formulaireDevis">
  <div class="div-search-offres">
    <label class="devis-instruction"
      ><span>1</span> Choisissez une ou plusieurs prestations</label
    >
    <div class="container-types-offres" id="hautFormDevis">
      <ul class="list-types-offres mb-0">
        <li
          placement="top"
          container="body"
          [ngbTooltip]="
            item.chosen
              ? 'Cliquer pour supprimer la prestation'
              : 'Cliquer pour ajouter la prestation'
          "
          class="item-type-offre"
          [class]="item.chosen ? 'chosen' : ''"
          *ngFor="let item of servicesDatas"
          (click)="prestaChosen(item._id)"
        >
          <i [class]="item.Icon"></i>
          <div class="label-type-offre">{{ item.Name_short }}</div>
        </li>
      </ul>
    </div>

    <label class="devis-instruction" *ngIf="nbPrestaChosen > 0"
      ><span>2</span> Ajouter le nombre d'heures dont vous avez besoin</label
    >

    <div class="div-form-search" *ngIf="nbPrestaChosen > 0">
      <ngb-alert
        [type]="'danger'"
        [dismissible]="false"
        *ngIf="showFormErrorValidation && !showRetourFormSuccess"
      >
        Veuillez remplir tous les champs du formulaire et ajouter au moins 1h de
        prestation.
      </ngb-alert>
      <ngb-alert
        [type]="'danger'"
        [dismissible]="false"
        *ngIf="showFormErrorAge && !showRetourFormSuccess"
      >
        Vous devez avoir 18 ans pour postuler.
      </ngb-alert>

      <form
        [formGroup]="form"
        (ngSubmit)="onSubmit()"
        *ngIf="!showRetourFormSuccess"
      >
        <div class="list-services">
          <ng-container *ngFor="let item of servicesDatas; let index = index">
            <div class="item-service" *ngIf="item.chosen">
              <div class="item-header mb-2">
                <div class="item">
                  <div class="title-service">
                    <i [class]="item.Icon"></i> {{ item.Name }}
                  </div>
                </div>
              </div>
              <div class="item-details table-responsive">
                <!-- TABLEAU CHOIX FREQUENCE PRESTATIONS-->
                <table class="table table-details-prestas mb-0">
                  <tr *ngIf="!isMobileLayout">
                    <th class="text-primary">
                      <i class="fas fa-sun"></i> En journée<br /><span
                        class="badge bg-primary prix-unitaire"
                        >{{ item.prix_unitaire }}€/h</span
                      >
                    </th>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          [name]="'cbPrestaRecurrente' + item._id"
                          value="1"
                          [id]="'cbPrestaRecurrente' + item._id"
                          (change)="prestaRecurenteChanged(item._id, $event)"
                          [checked]="item.presta_recurente == 1"
                        />
                        <label
                          class="form-check-label"
                          [for]="'cbPrestaRecurrente' + item._id"
                        >
                          Prestation récurrente
                        </label>
                      </div>
                      <div
                        class="form-group"
                        *ngIf="item.presta_recurente == 1"
                      >
                        <label>Fréquence</label>
                        <select
                          [value]="item.presta_frequence"
                          class="form-control select-frequence"
                          (change)="prestaFrequenceChanged(item._id, $event)"
                        >
                          <option value="" disabled>Fréquence</option>
                          <option value="S" selected>
                            Toutes les semaines
                          </option>
                          <option value="15J">Tous les 15 jours</option>
                          <option value="M">1 fois par mois</option>
                        </select>
                      </div>
                    </td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          [name]="'cbPrestaRecurrente' + item._id"
                          value="0"
                          [id]="'cbPrestaPonctuelle' + item._id"
                          (change)="prestaRecurenteChanged(item._id, $event)"
                          [checked]="item.presta_recurente == 0"
                        />
                        <label
                          class="form-check-label"
                          [for]="'cbPrestaPonctuelle' + item._id"
                        >
                          Prestation ponctuelle
                        </label>
                      </div>
                    </td>
                    <td>
                      <!-- Heures normales -->
                      <div class="increment-heures">
                        <button
                          placement="top"
                          container="body"
                          ngbTooltip="Ajouter des heures"
                          class="btn btn-dark btn-no-radius"
                          (click)="updateNbHeuresService(item._id, -1)"
                        >
                          <i class="fas fa-minus"></i>
                        </button>
                        <button
                          placement="top"
                          container="body"
                          ngbTooltip="Supprimer des heures"
                          class="btn btn-primary btn-no-radius"
                          (click)="updateNbHeuresService(item._id, +1)"
                        >
                          <i class="fas fa-plus"></i>
                        </button>
                        <div class="div-nb-heures">
                          <span
                            *ngIf="item.presta_recurente == 0"
                            [style.font-weight]="
                              item.nb_heures > 0 ? 'bold' : 'normal'
                            "
                            >{{ item.nb_heures }} heure{{
                              item.nb_heures > 1 ? "s" : ""
                            }}</span
                          >
                          <span
                            *ngIf="
                              item.presta_recurente == 1 &&
                              item.presta_frequence == 'S'
                            "
                            [style.font-weight]="
                              item.nb_heures > 0 ? 'bold' : 'normal'
                            "
                            >{{ item.nb_heures }}h/sem</span
                          >
                          <span
                            *ngIf="
                              item.presta_recurente == 1 &&
                              item.presta_frequence == '15J'
                            "
                            [style.font-weight]="
                              item.nb_heures > 0 ? 'bold' : 'normal'
                            "
                            >{{ item.nb_heures }}h/15j</span
                          >
                          <span
                            *ngIf="
                              item.presta_recurente == 1 &&
                              item.presta_frequence == 'M'
                            "
                            [style.font-weight]="
                              item.nb_heures > 0 ? 'bold' : 'normal'
                            "
                            >{{ item.nb_heures }}h/mois</span
                          >
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr *ngIf="!isMobileLayout">
                    <th class="text-primary">
                      <i class="fas fa-moon"></i> Nuits / Week-end<br />/ Jours
                      fériés<br /><span class="badge bg-primary prix-unitaire"
                        >{{ item.prix_unitaire_we }}€/h</span
                      >
                    </th>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          [name]="'cbPrestaRecurrenteWe' + item._id"
                          value="1"
                          [id]="'cbPrestaRecurrenteWe' + item._id"
                          (change)="prestaRecurenteWeChanged(item._id, $event)"
                          [checked]="item.presta_recurente_we == 1"
                        />
                        <label
                          class="form-check-label"
                          [for]="'cbPrestaRecurrenteWe' + item._id"
                        >
                          Prestation récurrente
                        </label>
                      </div>
                      <div
                        class="form-group"
                        *ngIf="item.presta_recurente_we == 1"
                      >
                        <label>Fréquence</label>
                        <select
                          [value]="item.presta_frequence_we"
                          class="form-control select-frequence"
                          (change)="prestaFrequenceWeChanged(item._id, $event)"
                        >
                          <option value="" disabled>Fréquence</option>
                          <option value="S" selected>
                            Toutes les semaines
                          </option>
                          <option value="15J">Tous les 15 jours</option>
                          <option value="M">1 fois par mois</option>
                        </select>
                      </div>
                    </td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          [name]="'cbPrestaRecurrenteWe' + item._id"
                          value="0"
                          [id]="'cbPrestaPonctuelleWe' + item._id"
                          (change)="prestaRecurenteWeChanged(item._id, $event)"
                          [checked]="item.presta_recurente_we == 0"
                        />
                        <label
                          class="form-check-label"
                          [for]="'cbPrestaPonctuelleWe' + item._id"
                        >
                          Prestation ponctuelle
                        </label>
                      </div>
                    </td>
                    <td>
                      <!-- Heures nuit et weekend -->
                      <div class="increment-heures">
                        <button
                          placement="top"
                          container="body"
                          ngbTooltip="Ajouter des heures"
                          class="btn btn-dark btn-no-radius"
                          (click)="updateNbHeuresServiceWeekend(item._id, -1)"
                        >
                          <i class="fas fa-minus"></i>
                        </button>
                        <button
                          placement="top"
                          container="body"
                          ngbTooltip="Supprimer des heures"
                          class="btn btn-primary btn-no-radius"
                          (click)="updateNbHeuresServiceWeekend(item._id, +1)"
                        >
                          <i class="fas fa-plus"></i>
                        </button>
                        <div class="div-nb-heures">
                          <span
                            *ngIf="item.presta_recurente_we == 0"
                            [style.font-weight]="
                              item.nb_heures_weekend > 0 ? 'bold' : 'normal'
                            "
                            >{{ item.nb_heures_weekend }} heure{{
                              item.nb_heures_weekend > 1 ? "s" : ""
                            }}</span
                          >
                          <span
                            *ngIf="
                              item.presta_recurente_we == 1 &&
                              item.presta_frequence_we == 'S'
                            "
                            [style.font-weight]="
                              item.nb_heures_weekend > 0 ? 'bold' : 'normal'
                            "
                            >{{ item.nb_heures_weekend }}h/sem</span
                          >
                          <span
                            *ngIf="
                              item.presta_recurente_we == 1 &&
                              item.presta_frequence_we == '15J'
                            "
                            [style.font-weight]="
                              item.nb_heures_weekend > 0 ? 'bold' : 'normal'
                            "
                            >{{ item.nb_heures_weekend }}h/15j</span
                          >
                          <span
                            *ngIf="
                              item.presta_recurente_we == 1 &&
                              item.presta_frequence_we == 'M'
                            "
                            [style.font-weight]="
                              item.nb_heures_weekend > 0 ? 'bold' : 'normal'
                            "
                            >{{ item.nb_heures_weekend }}h/mois</span
                          >
                        </div>

                        <!-- <div class="title-nb-heures-weekend">
                          Nuit et/<br />ou WE
                        </div> -->
                      </div>
                    </td>
                  </tr>

                  <!-- VISION MOBILE - JOURNEE : titre ("En journée" ou "Nuits/WE") + choix heures -->
                  <tr *ngIf="isMobileLayout">
                    <th class="text-primary">
                      <i class="fas fa-sun"></i> En journée<br /><span
                        class="badge bg-primary prix-unitaire"
                        >{{ item.prix_unitaire }}€/h</span
                      >
                    </th>
                    <td>
                      <!-- Heures normales -->
                      <div class="increment-heures">
                        <button
                          placement="top"
                          container="body"
                          ngbTooltip="Ajouter des heures"
                          class="btn btn-dark btn-no-radius"
                          (click)="updateNbHeuresService(item._id, -1)"
                        >
                          <i class="fas fa-minus"></i>
                        </button>
                        <button
                          placement="top"
                          container="body"
                          ngbTooltip="Supprimer des heures"
                          class="btn btn-primary btn-no-radius"
                          (click)="updateNbHeuresService(item._id, +1)"
                        >
                          <i class="fas fa-plus"></i>
                        </button>
                        <div class="div-nb-heures">
                          <span
                            *ngIf="item.presta_recurente == 0"
                            [style.font-weight]="
                              item.nb_heures > 0 ? 'bold' : 'normal'
                            "
                            >{{ item.nb_heures }} heure{{
                              item.nb_heures > 1 ? "s" : ""
                            }}</span
                          >
                          <span
                            *ngIf="
                              item.presta_recurente == 1 &&
                              item.presta_frequence == 'S'
                            "
                            [style.font-weight]="
                              item.nb_heures > 0 ? 'bold' : 'normal'
                            "
                            >{{ item.nb_heures }}h/sem</span
                          >
                          <span
                            *ngIf="
                              item.presta_recurente == 1 &&
                              item.presta_frequence == '15J'
                            "
                            [style.font-weight]="
                              item.nb_heures > 0 ? 'bold' : 'normal'
                            "
                            >{{ item.nb_heures }}h/15j</span
                          >
                          <span
                            *ngIf="
                              item.presta_recurente == 1 &&
                              item.presta_frequence == 'M'
                            "
                            [style.font-weight]="
                              item.nb_heures > 0 ? 'bold' : 'normal'
                            "
                            >{{ item.nb_heures }}h/mois</span
                          >
                        </div>
                      </div>
                    </td>
                  </tr>

                  <!-- VISION MOBILE - JOURNEE : presta récurrente ou ponctuelle -->
                  <tr *ngIf="isMobileLayout">
                    <td colspan="2" style="border-top: none">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          [name]="'cbPrestaRecurrente' + item._id"
                          value="1"
                          [id]="'cbPrestaRecurrente' + item._id"
                          (change)="prestaRecurenteChanged(item._id, $event)"
                          [checked]="item.presta_recurente == 1"
                        />
                        <label
                          class="form-check-label"
                          [for]="'cbPrestaRecurrente' + item._id"
                        >
                          Prestation récurrente
                        </label>
                      </div>
                      <div
                        class="form-group"
                        *ngIf="item.presta_recurente == 1"
                      >
                        <label>Fréquence</label>
                        <select
                          [value]="item.presta_frequence"
                          class="form-control select-frequence"
                          (change)="prestaFrequenceChanged(item._id, $event)"
                        >
                          <option value="" disabled>Fréquence</option>
                          <option value="S" selected>
                            Toutes les semaines
                          </option>
                          <option value="15J">Tous les 15 jours</option>
                          <option value="M">1 fois par mois</option>
                        </select>
                      </div>

                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          [name]="'cbPrestaRecurrente' + item._id"
                          value="0"
                          [id]="'cbPrestaPonctuelle' + item._id"
                          (change)="prestaRecurenteChanged(item._id, $event)"
                          [checked]="item.presta_recurente == 0"
                        />
                        <label
                          class="form-check-label"
                          [for]="'cbPrestaPonctuelle' + item._id"
                        >
                          Prestation ponctuelle
                        </label>
                      </div>
                    </td>
                  </tr>

                  <!-- VISION MOBILE - NUIT/WE : titre ("En journée" ou "Nuits/WE") + choix heures -->
                  <tr *ngIf="isMobileLayout">
                    <th class="text-primary">
                      <i class="fas fa-moon"></i> Nuits / WE /<br />Jours
                      fériés<br /><span class="badge bg-primary prix-unitaire"
                        >{{ item.prix_unitaire_we }}€/h</span
                      >
                    </th>
                    <td>
                      <!-- Heures normales -->
                      <div class="increment-heures">
                        <button
                          placement="top"
                          container="body"
                          ngbTooltip="Ajouter des heures"
                          class="btn btn-dark btn-no-radius"
                          (click)="updateNbHeuresServiceWeekend(item._id, -1)"
                        >
                          <i class="fas fa-minus"></i>
                        </button>
                        <button
                          placement="top"
                          container="body"
                          ngbTooltip="Supprimer des heures"
                          class="btn btn-primary btn-no-radius"
                          (click)="updateNbHeuresServiceWeekend(item._id, +1)"
                        >
                          <i class="fas fa-plus"></i>
                        </button>
                        <div class="div-nb-heures">
                          <span
                            *ngIf="item.presta_recurente_we == 0"
                            [style.font-weight]="
                              item.nb_heures_weekend > 0 ? 'bold' : 'normal'
                            "
                            >{{ item.nb_heures_weekend }} heure{{
                              item.nb_heures_weekend > 1 ? "s" : ""
                            }}</span
                          >
                          <span
                            *ngIf="
                              item.presta_recurente_we == 1 &&
                              item.presta_frequence_we == 'S'
                            "
                            [style.font-weight]="
                              item.nb_heures_weekend > 0 ? 'bold' : 'normal'
                            "
                            >{{ item.nb_heures_weekend }}h/sem</span
                          >
                          <span
                            *ngIf="
                              item.presta_recurente_we == 1 &&
                              item.presta_frequence_we == '15J'
                            "
                            [style.font-weight]="
                              item.nb_heures_weekend > 0 ? 'bold' : 'normal'
                            "
                            >{{ item.nb_heures_weekend }}h/15j</span
                          >
                          <span
                            *ngIf="
                              item.presta_recurente_we == 1 &&
                              item.presta_frequence_we == 'M'
                            "
                            [style.font-weight]="
                              item.nb_heures_weekend > 0 ? 'bold' : 'normal'
                            "
                            >{{ item.nb_heures_weekend }}h/mois</span
                          >
                        </div>
                      </div>
                    </td>
                  </tr>

                  <!-- VISION MOBILE - NUIT/WE : presta récurrente ou ponctuelle -->
                  <tr *ngIf="isMobileLayout">
                    <td colspan="2" style="border-top: none">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          [name]="'cbPrestaRecurrenteWe' + item._id"
                          value="1"
                          [id]="'cbPrestaRecurrenteWe' + item._id"
                          (change)="prestaRecurenteWeChanged(item._id, $event)"
                          [checked]="item.presta_recurente_we == 1"
                        />
                        <label
                          class="form-check-label"
                          [for]="'cbPrestaRecurrenteWe' + item._id"
                        >
                          Prestation récurrente
                        </label>
                      </div>
                      <div
                        class="form-group"
                        *ngIf="item.presta_recurente_we == 1"
                      >
                        <label>Fréquence</label>
                        <select
                          [value]="item.presta_frequence_we"
                          class="form-control select-frequence"
                          (change)="prestaFrequenceWeChanged(item._id, $event)"
                        >
                          <option value="" disabled>Fréquence</option>
                          <option value="S" selected>
                            Toutes les semaines
                          </option>
                          <option value="15J">Tous les 15 jours</option>
                          <option value="M">1 fois par mois</option>
                        </select>
                      </div>

                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          [name]="'cbPrestaRecurrenteWe' + item._id"
                          value="0"
                          [id]="'cbPrestaPonctuelleWe' + item._id"
                          (change)="prestaRecurenteWeChanged(item._id, $event)"
                          [checked]="item.presta_recurente_we == 0"
                        />
                        <label
                          class="form-check-label"
                          [for]="'cbPrestaPonctuelleWe' + item._id"
                        >
                          Prestation ponctuelle
                        </label>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </ng-container>
        </div>

        <button
          *ngIf="!isShowFormCoordonnees && nbPrestaChosen > 0"
          type="button"
          class="btn btn-primary btn-block"
          (click)="showFormCoordonnees()"
          [disabled]="totalTTC == 0"
        >
          Demander un devis
        </button>

        <div
          id="formCoordonnees"
          *ngIf="isShowFormCoordonnees"
          [@fadeInExpandOnEnter]
        >
          <p style="color: #c84f94; margin-bottom: 5px; text-align: center">
            A propos de vous
          </p>

          <div class="form-group">
            <label>Vous êtes un</label>
            <select class="form-control" formControlName="statut">
              <option value="null" disabled selected>Vous êtes un</option>
              <option [value]="item.id" *ngFor="let item of listStatuts">
                {{ item.label }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label>Civilité</label>
            <select class="form-control" formControlName="civilite">
              <option value="null" disabled selected>Civilité</option>
              <option [value]="item.id" *ngFor="let item of listGenres">
                {{ item.label }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label>Nom</label>
            <input
              class="form-control"
              type="text"
              formControlName="nom"
              placeholder="Nom"
            />
          </div>
          <div class="form-group">
            <label>Prénom</label>
            <input
              class="form-control"
              type="text"
              formControlName="prenom"
              placeholder="Prénom"
            />
          </div>
          <div class="form-group">
            <label>Adresse</label>
            <input
              class="form-control"
              type="text"
              formControlName="adresse"
              placeholder="Adresse"
              (focus)="getLocation()"
              ngx-google-places-autocomplete
              [options]="options"
              #placesRef="ngx-places"
              (onAddressChange)="handleAddressChange($event)"
              autocomplete="off"
            />
            <div
              class="form-text geoloc-adr"
              *ngIf="lat != null && lng != null"
            >
              <i class="fas fa-location-arrow"></i> Vous avez été localisés à
              cette adresse.
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label>Code postal</label>
                <input
                  type="text"
                  class="form-control"
                  [class]="this.form.value.codep != null ? 'is-valid' : ''"
                  formControlName="codep"
                  placeholder="Code postal"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label>Ville</label>
                <input
                  type="text"
                  class="form-control"
                  [class]="this.form.value.ville != null ? 'is-valid' : ''"
                  formControlName="ville"
                  placeholder="Ville"
                  autocomplete="off"
                />
              </div>
            </div>
          </div>

          <div class="form-group">
            <label>Adresse email</label>
            <input
              type="email"
              class="form-control"
              formControlName="email"
              placeholder="Adresse email"
            />
          </div>
          <div class="form-group">
            <label>Téléphone fixe</label>
            <input
              type="text"
              pattern="^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$"
              class="form-control"
              formControlName="fixe"
              placeholder="Téléphone fixe"
              [class]="errorsPhonesRequired ? 'is-invalid' : 'is-valid'"
            />
          </div>
          <div class="form-group">
            <label>Téléphone mobile</label>
            <input
              type="text"
              pattern="^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$"
              class="form-control"
              formControlName="mobile"
              placeholder="Téléphone mobile"
              [class]="errorsPhonesRequired ? 'is-invalid' : 'is-valid'"
            />
          </div>

          <div class="form-group">
            <label>Date de naissance</label>
            <div class="input-group">
              <input
                class="form-control"
                placeholder="Date de naissance"
                formControlName="date_naissance"
                ngbDatepicker
                #d="ngbDatepicker"
                placement="bottom"
                (click)="d.toggle()"
                [minDate]="{ year: 1920, month: 1, day: 1 }"
                [markDisabled]="isDisabled"
              />
              <div class="input-group-append">
                <button
                  style="height: 38px"
                  class="btn btn-outline-secondary btn-date-picker"
                  (click)="d.toggle()"
                  type="button"
                >
                  <i class="far fa-calendar-alt"></i>
                </button>
              </div>
            </div>
          </div>

          <div class="form-check mb-2">
            <input
              class="form-check-input"
              type="checkbox"
              formControlName="conditions_ok"
              id="conditionsOk"
              [checked]="this.form.value.conditions_ok"
            />
            <label class="form-check-label" for="conditionsOk">
              Je reconnais en cochant la présente case, valider et si besoin est
              remplir l’ensemble des conditions nécessaires pour m’engager au
              titre des présentes et les accepter sans réserve.
            </label>
          </div>

          <div class="form-check mb-2">
            <input
              class="form-check-input"
              type="checkbox"
              formControlName="rgpd_ok"
              id="rgpdOk"
              [checked]="this.form.value.rgpd_ok"
            />
            <label class="form-check-label" for="rgpdOk">
              J’accepte le traitement de mes Données Personnelles conformément à
              la politique de Données Personnelles de la société H&L.
              <br /><a href="#" (click)="open($event, donnesPersonnelles)"
                ><i class="far fa-hand-point-right"></i> Prendre connaissance de
                notre Politique de traitement de données personnelles</a
              >
            </label>
          </div>

          <div
            class="d-flex justify-content-center mt-5 mb-5"
            *ngIf="showLoaderForm"
          >
            <div class="spinner-border text-primary" role="status">
              <!-- <span class="visually-hidden">Loading...</span> -->
            </div>
            <span class="text-primary ml-2"
              >Génération de votre devis en cours, veuillez patientez...</span
            >
          </div>

          <button
            type="submit"
            class="btn btn-primary btn-block"
            [disabled]="showLoaderForm"
          >
            Voir mon devis
          </button>
        </div>
      </form>

      <ngb-alert
        [type]="'success'"
        [dismissible]="false"
        *ngIf="showRetourFormSuccess"
        class="text-center"
      >
        Merci pour votre confiance ! Le devis vient de vous être envoyé par
        mail. <br />Si vous n'avez pas encore signé le devis, vous pouvez le
        faire dès maintenant et nous le retourner à l'adresse
        <a href="mailto:contact@h-et-l.com">contact@h-et-l.com</a>. <br />Notre
        service commercial vous contactera prochainement.
        <div class="mt-2">
          <button class="btn btn-primary" (click)="open($event, modalDevis)">
            Revoir mon devis
          </button>
        </div>
        <div class="mt-2">
          <button class="btn btn-outline-primary" (click)="resetForm()">
            Nouveau devis
          </button>
        </div>
      </ngb-alert>
      <ngb-alert
        [type]="'danger'"
        [dismissible]="false"
        *ngIf="showRetourFormError"
      >
        Il y a eu une erreur lors de la demande de devis, veuillez
        <strong>rééessayer</strong>.
      </ngb-alert>
    </div>
  </div>

  <div class="div-prix-offres" [style.opacity]="totalTTC == 0 ? '0.7' : ''">
    <div class="title-prix text-center bg-primary" style="color: #fff">
      <strong *ngIf="totalTTC == 0">Votre devis</strong>
      <label
        class="devis-instruction text-light pt-0 pb-0"
        *ngIf="totalTTC != 0"
        ><span class="bg-light text-primary">3</span> Votre devis</label
      >
    </div>
    <div class="title-prix">Total TTC /mois</div>
    <div class="prix">{{ totalTTC }} €</div>
    <div class="title-prix">
      <strong
        >Coût réel /mois<br /><small>Après déduction fiscale</small></strong
      >
    </div>
    <div class="prix">
      <strong
        ><i class="far fa-hand-point-right" style="margin-right: 15px"></i>
        {{ totalCoutReel }} €
        <i class="far fa-hand-point-left" style="margin-left: 15px"></i
      ></strong>
    </div>
  </div>

  <!--DEBUT DEVIS-->
  <ng-template #modalDevis let-modal2 let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title">Votre devis</h4>
      <button
        type="button"
        class="close"
        #closeButtonDevis
        id="closeButtonDevis"
        aria-label="Close"
        (click)="d('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <ngb-alert
        [type]="'success'"
        [dismissible]="false"
        *ngIf="showRetourFormSuccess"
        class="text-center"
      >
        Merci pour votre confiance !
        <br />Vous pouvez signer votre devis et le recevoir par mail dès
        maintenant. <br />Notre service commercial vous contactera
        prochainement.
      </ngb-alert>
      <ngb-alert
        [type]="'success'"
        [dismissible]="false"
        *ngIf="showRetourFormDevisSigneSuccess"
        class="text-center"
      >
        Le devis signé vient de vous être envoyé par mail. <br />Vous pouvez
        nous le retourner dès maintenant à l'adresse
        <a href="mailto:contact@h-et-l.com">contact@h-et-l.com</a>. <br />Notre
        service commercial vous contactera prochainement.
      </ngb-alert>
      <div
        class="d-flex justify-content-center mt-5 mb-5"
        *ngIf="showLoaderSignatureDevis"
      >
        <div class="spinner-border text-primary" role="status">
          <!-- <span class="visually-hidden">Signature du devis...</span> -->
        </div>
        <span class="text-primary ml-2"
          >Signature du devis en cours, veuillez patientez...</span
        >
        <div class="container-devis" [innerHtml]="htmlDevis"></div>
      </div>

      <div
        class="d-flex justify-content-center mt-5 mb-5"
        *ngIf="showLoaderEnvoiDevisNonSigne"
      >
        <div class="spinner-border text-primary" role="status">
          <!-- <span class="visually-hidden">Signature du devis...</span> -->
        </div>
        <span class="text-primary ml-2"
          >Envoi de votre devis en cours, veuillez patientez...</span
        >
      </div>
      <div class="container-devis" [innerHtml]="htmlDevis"></div>
    </div>
    <div class="modal-footer">
      <a href="#" (click)="open($event, modalCgvDevis)"
        >Lire les CGV (Conditions Générales de vente)</a
      >
      <button
        type="button"
        class="btn btn-primary"
        (click)="signerLeDevisMaintenant()"
        [disabled]="showLoaderSignatureDevis || showLoaderEnvoiDevisNonSigne"
        *ngIf="!showRetourFormDevisSigneSuccess"
      >
        <i class="fas fa-file-signature"></i> Signer le devis maintenant
      </button>
      <button
        type="button"
        class="btn btn-outline-dark"
        (click)="sendDevisClientNonSigne()"
        [disabled]="showLoaderSignatureDevis || showLoaderEnvoiDevisNonSigne"
        *ngIf="!showRetourFormDevisSigneSuccess"
      >
        Signer le devis plus tard
      </button>
      <button
        type="button"
        class="btn btn-outline-dark"
        (click)="closeModalDevis()"
        *ngIf="showRetourFormDevisSigneSuccess"
      >
        Fermer
      </button>
    </div>
  </ng-template>

  <!--FIN DEVIS-->

  <!--DEBUT CGV DEVIS-->
  <ng-template #modalCgvDevis let-modal3>
    <div class="modal-header">
      <h4 class="modal-title">Conditions Générales de vente</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal3.dismiss()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="container-cgv-devis" [innerHtml]="htmlCgvDevis"></div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-dark"
        (click)="modal3.dismiss()"
      >
        Fermer
      </button>
    </div>
  </ng-template>

  <!--FIN CGV DEVIS-->

  <ng-template #donnesPersonnelles let-modal1>
    <div class="modal-header">
      <h4 class="modal-title">
        Politique de protection des données personnelles
      </h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal1.dismiss()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <h3>1. PROPOS INTRODUCTIFS</h3>
      <p>
        Le présent document a pour objet d’informer les conditions dans
        lesquelles la société H&L PRESTATION A DOMICILE , Société par actions
        simplifiée enregistrée au registre du commerce et des sociétés de Vienne
        sous le numéro 477 934 343, dont le siège social se situe Espace Saint
        Germain-Immeuble « Le Miles », 30 avenue du Général Leclerc à Vienne
        (38200) s’engage à effectuer les opérations de traitement sur les
        données à caractère personnel qu’elle traite, en qualité de responsable
        des traitements. Cette politique de confidentialité et de protection de
        données personnelles s’adresse aux clients et prospects de la société
        H&L. En l’occurrence, la société H&L s’engage à se conformer aux
        dispositions suivantes : (ci-après les « Dispositions relatives aux
        Données Personnelles ») :
        <br />• la loi n° 78-17 du 6 janvier 1978 relative à l'informatique, aux
        fichiers et aux libertés, <br />• le Règlement Général sur la Protection
        des Données 2016/679 du 27 avril 2016 (ci -après « RGPD).
      </p>

      <h3>2. RECAPITULATIF DES DONNEES TRAITEES</h3>
      <p>
        Les données suivantes peuvent être collectées par la société H&L :
        <br />1.État-civil, identité: Nom, prénom, adresse, courriel
        <br />2.Toutes informations complémentaires concernant le prospect et/ou
        le client que ces derniers choisissent de communiquer lors de leur
        présentation et/ou dans les champs libres. <br />La collecte des données
        personnelles des clients et/ou prospects, à l’exception de celle(s)
        mentionnée(s) comme facultative(s), est obligatoire.
      </p>

      <h3>3. FINALITES ET BASES LEGALES DU TRAITEMENT</h3>
      <p>
        Les traitements de données à caractère personnel mis en œuvre ont les
        bases juridiques suivantes :
        <br />1. l’exécution de mesures précontractuelles ou du contrat lorsque
        la société H&L met en œuvre un traitement ayant pour finalité : la
        production, la gestion, le suivi des dossiers de ses clients et/ou
        prospects ; le recouvrement. <br />2. l’intérêt légitime poursuivi par
        la société H&L lorsqu’elle poursuit les finalités suivantes : o la
        gestion de la relation avec les clients et les prospects ; <br />• la
        personnalisation, l’évaluation et l’amélioration des services fournis ;
        <br />• la personnalisation, l’évaluation et l’amélioration de
        l’utilisation de la Plateforme ; <br />• la prévention et la lutte
        contre la fraude informatique ; <br />• l’utilisation de la plateforme
        de la société H&L. <br />3. le respect d’obligations légales et
        réglementaires lorsqu’il met en œuvre un traitement ayant pour finalité
        : <br />• la prévention du blanchiment et du financement du terrorisme
        et la lutte contre la corruption ; <br />• la facturation ; <br />• la
        comptabilité. <br />4. Le consentement lorsque la société H&L poursuit
        les finalités suivantes : <br />• la transmission de lettres
        d’information et/ou d’articles <br />• la prospection et l’animation
      </p>
      <h3>4. LA SECURITE ET LA CONSERVATION DES DONNEES</h3>
      <p>
        La société H&L prendra toutes précautions utiles aux fins de préserver
        la sécurité et la confidentialité des données des prospects et/ou des
        clients et, notamment, empêcher qu'elles soient déformées, endommagées,
        ou que des tiers non autorisés y aient accès.
      </p>
      <h3>5. LES DESTINATAIRES DES DONNEES PERSONNELLES</h3>
      <p>
        Les données collectées sont destinées au responsable du traitement (soit
        la société H&L). Elles pourront également être transmises aux
        destinataires suivants : <br />- l’intervenant salarié de la société
        H&L, qui effectuera sa prestation chez le client. <br />- le mandataire
        du client : Il est à cet effet précisé qu’aux fins d’être destinataire
        des données personnelles du client, la personne concernée par le
        traitement de ses données doit expressément consentir à ce que le
        mandataire puisse avoir accès à ses données personnelles, par
        l’intermédiaire d’un mandat.
      </p>
      <h3>6. LE TRANSFERT DES DONNEES PERSONNELLES</h3>
      <p>
        La société H&L n’effectue aucun transfert des données personnelles de
        ses prospects et/ou clients vers des pays étrangers (hors Union
        Européenne). Si la société H&L est tenue de procéder à un transfert de
        données vers un pays tiers ou à une organisation internationale, elle en
        informera ces derniers avant le traitement.
      </p>
      <h3>7. DUREE DE CONSERVATION DES DONNEES COLLECTEES</h3>
      <p>
        La société H&L ne conserve les données que pour la durée nécessaire aux
        opérations pour lesquelles elles ont été collectées ainsi que dans le
        respect de la règlementation en vigueur. <br />A cet égard, les données
        des clients sont conservées pendant la durée des relations
        contractuelles augmentée de 3 ans à des fins d'animation et prospection,
        sans préjudice des obligations de conservation ou des délais de
        prescription. <br />En matière de prévention du blanchiment et du
        financement du terrorisme, les données sont conservées 5 ans après la
        fin des relations avec la société H&L. <br />En matière de comptabilité,
        elles sont conservées 10 ans à compter de la clôture de l'exercice
        comptable. <br />Les données des prospects sont conservées pendant une
        durée de 3 ans si aucune participation ou inscription aux événements de
        la société H&L n’a eu lieu.
      </p>
      <h3>8. DROITS DES CLIENTS ET PROSPECTS</h3>
      <p>
        En application des Dispositions relatives aux Données Personnelles, les
        clients et/ou prospects peuvent à tout moment accéder aux données les
        concernant détenues par la société H&L, et demander leur limitation,
        portabilité, rectification ou leur effacement. <br />Ainsi, les clients
        et/ou prospects peuvent demander que soient rectifiées, complétées,
        clarifiées, mises à jour ou effacées les données personnelles les
        concernant qui sont inexactes, incomplètes, équivoques, périmées ou dont
        la collecte ou l'utilisation, la communication ou la conservation sont
        interdites. <br />Les clients et/ou les prospects disposent également
        d’un droit de s’opposer à tout moment, pour des raisons tenant à leur
        situation particulière, au traitement de leurs données à caractère
        personnel ayant comme base juridique l’intérêt légitime de la société
        H&L. <br />En l’occurrence, la société H&L garantit que ses demandes
        seront prises en compte dans les meilleurs délais. <br />Les clients
        et/ou prospects peuvent exercer leurs droits et obtenir communication
        des informations les concernant par courrier électronique en s’adressant
        à admin@wanteez.fr ou à l’adresse suivante : Wanteez by H&L 30 Avenue
        Général Leclerc Espace St Germain Bat 2 Le Miles 38200 VIENNE.<br />
        Il est par ailleurs rappelé que les clients et/ou prospects disposent
        d’un droit d'introduire une réclamation auprès de l’autorité de contrôle
        compétente en cas de non-respect par la société H&L, de ses obligations.
      </p>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-dark"
        (click)="modal1.dismiss()"
      >
        Fermer
      </button>
    </div>
  </ng-template>

  <div class="cart-bar" *ngIf="showCart && isMobileLayout" [@pulseOnEnter]>
    <div
      class="title-prix title-prix-titre text-center bg-primary"
      style="color: #fff"
    >
      <strong *ngIf="totalTTC == 0">Votre devis</strong>
      <label
        class="devis-instruction text-light pt-0 pb-0"
        *ngIf="totalTTC != 0"
        ><span class="bg-light text-primary">3</span> Votre devis</label
      >
    </div>
    <div class="container-couts">
      <div>
        <div class="title-prix">
          <strong>Total TTC /mois</strong><small>{{ totalTTC }} €</small>
        </div>
      </div>
      <div class="container-cout-reel">
        <div class="title-prix">
          <strong
            >Coût réel /mois <br /><small
              >Après déduction fiscale</small
            ></strong
          >
        </div>
        <div class="prix cout-reel">
          <strong
            ><i class="far fa-hand-point-right" style="margin-right: 15px"></i>
            <span [@fadeIn]="bounceTotalCoutReel">{{ totalCoutReel }} €</span>
            <i class="far fa-hand-point-left" style="margin-left: 15px"></i
          ></strong>
        </div>
      </div>
    </div>
  </div>
</div>
