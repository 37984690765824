import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  NgcCookieConsentService,
  NgcInitializeEvent,
  NgcNoCookieLawEvent,
  NgcStatusChangeEvent,
} from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/internal/operators';
import { DataService } from './services/data.service.ts.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Wanteez';
  villeCandidat: any;
  latitudeCandidat: any;
  longitudeCandidat: any;
  codepCandidat: any;
  windowScrolled: boolean;
  document: Document;
  codep: any;
  activeRoute;

  //keep refs to subscriptions to be able to unsubscribe later
  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;

  private dataServiceSubscription: Subscription;

  _opened: boolean = false;

  constructor(
    @Inject(DOCUMENT) private documentService: Document,
    private ccService: NgcCookieConsentService,
    private translateService: TranslateService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    private dataService: DataService
  ) {
    // console.log('document.body.scrollTop', documentService.body.scrollTop);
    this.document = documentService;

    this.router.events
      .pipe(filter((event: RouterEvent) => event instanceof NavigationEnd))
      .subscribe((event) => {
        this.activeRoute = event.url;
        if (isPlatformBrowser(this.platformId)) {
          window.scroll(0, 0);
        }
        // if (event.url == '/') {
        //   this.router.navigate(['aide-domicile']);
        // }
        if (event.url == '/recrutement') {
          this.dataService.changeStateFormulaireInscription(true);
        } else if(event.url == '/jobAround') {
          this.dataService.changeStateJobAround(true);
        } else {
          this.dataService.changeStateFormulaireInscription(false);
        }
        this.dataService.changeStateSidebar(false);
      });
  }

  ngOnChanges() {

  }

  ngOnInit() {
    this.dataServiceSubscription = this.dataService.sidebarVisible$.subscribe(
      (isVisible) => (this._opened = isVisible)
    );

    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    });

    this.initializeSubscription = this.ccService.initialize$.subscribe(
      (event: NgcInitializeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );

    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );
  }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
    this.dataServiceSubscription.unsubscribe();
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop > 300
    ) {
      this.windowScrolled = true;
    } else if (
      (this.windowScrolled && window.pageYOffset) ||
      document.documentElement.scrollTop ||
      document.body.scrollTop < 300
    ) {
      this.windowScrolled = false;
    }
  }

  scrollToTop() {
    window.scroll(0, 0);
  }

  _toggleSidebar() {
    this._opened = !this._opened;
  }
  changeVille($event){
    console.log($event);
    this.villeCandidat = $event;
  }

  changeLatitude(lat){
    console.log(lat);
    this.latitudeCandidat = lat;
  }

  changeLongitude(lng){
    console.log(lng);
    this.longitudeCandidat = lng;
  }

  changeCodep(codep){
    console.log(codep);
    this.codepCandidat = codep;
  }
}
