import { Component, OnInit, Input, Renderer2 } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { DataService } from '../../app/services/data.service.ts.service';

import { ApiService } from '../services/api.service';
import { jobArroundPost } from './job-arround-post';
import * as data from '../data/citys.json';

@Component({
  selector: 'app-job-arround',
  templateUrl: './job-arround.component.html',
  styleUrls: ['./job-arround.component.scss']
})
export class JobArroundComponent implements OnInit {

  @Input() ville: string;
  @Input() latitude: any;
  @Input() longitude: any;
  @Input() codep: any;

  villes: any = (data as any).default;

  // Between resp
  jobArroundPost: jobArroundPost;
  aArray: any;
  $dataServ: any;
  labelArray: any;
  lengthArray: any;
  servResp: boolean;
  strongArray: [];
  arrayAWithoutDouble = [];
  arrayAdresse = [];

  // Iframe
  urlIframe: string;

  // Google map Api
  department: any;
  villeName: string;
  cityFound: boolean;
  map: any;
  lat: any;
  lng: any;
  cityCircle: any;
  center: google.maps.LatLngLiteral
  markerJobs = [];

  constructor(
    private api: ApiService,
    private renderer: Renderer2,
    private viewportScroller: ViewportScroller,
    private datas: DataService

  ) {

    this.cityFound = false;

      /*this.markerJobs = [
        { lat: 44.72443155172416, lng: 4.59073454022988 },
        { lat: 44.70443155172416, lng: 4.58073454022988 },
        { lat: 44.78443155172416, lng: 4.61073454022988 },
        { lat: 44.73443155172416, lng: 4.44073454022988 },
        { lat: 44.68443155172416, lng: 4.55073454022988 },
      ];*/

   }

  ngOnChanges() {

    console.log(this.ville);
    console.log(this.codep);

    this.searchCity();
    console.log(this.lat);

    setTimeout(() => {
      console.log(this.lat);
      this.createMap();

    }, 1000);

    //google.maps.event.addDomListener(window, 'load', this.createMap);
    console.log('test');

  }

  ngOnInit() {

    // Dataset for test serv
    this.jobArroundPost = {

      location: '07',
      contract: '',
      pagingcount: '30',
      keywords: '',
      _tc: '',
      adresse: this.ville,

    };

  }

  ngAfterViewInit() {

    //let iframe2 = document.getElementsByTagName('iframe')[0]

  }

  async getHtmlFromBetween($data){
    this.$dataServ = $data;
    this.api.getJobArround($data).subscribe((resp) => {
      console.log('response', resp);

     if (resp != null) {

      this.labelArray = resp[0];
      this.aArray = resp[1];
      this.strongArray = resp[2];

      this.arrayConfig(this.aArray, this.labelArray);
      } else {}
    });

  }

  async arrayConfig(aArray, labelArray){

    setTimeout(()=>{

        labelArray.forEach(label => {

          if (label.substr(0, 8) !== 'ermanent') {
            label = label.replace('"', "");
            label = label.replace('</span>', '');
            this.arrayAdresse.push(label);

          }
        });

        this.arrayAWithoutDouble.pop();
        this.arrayAWithoutDouble.pop();
        this.arrayAWithoutDouble.pop();

        this.lengthArray = this.strongArray.length;

        aArray.forEach(a => {
          if (!this.arrayAWithoutDouble.includes(a)) {
            this.arrayAWithoutDouble.push(a);
          }
        });


        this.servResp = true;
        console.log(this.servResp);
        console.log('array A',this.arrayAWithoutDouble);
        console.log('array Aresse',this.arrayAdresse);
        console.log('array Strong',this.strongArray);

        this.counter();
     }, 3000);

  }

  counter() {
    return new Array(this.lengthArray);
  }

  // recherche les coordonées de la ville dans le fichier json
  async searchCity() {

    if (this.codep !== undefined) {
      this.cityFound = true;

      let geocoder = new google.maps.Geocoder();
      var address = this.codep;
      geocoder.geocode( { 'address': address}, function(results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
           this.lat = results[0].geometry.location.lat();
           this.lng = results[0].geometry.location.lng();
           console.log(this.lat);
           console.log(this.longitude);

        } else {
          alert("Geocode was not successful for the following reason: " + status);
        }
      });

    } else {
      this.cityFound = false;
    }


  }

  public showFormulaireInscription(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
    setTimeout(() => {
      this.datas.changeStateFormulaireInscription(true);
    }, 1500);
  }

  createMap() {

    if (this.cityFound === true) {
      setTimeout(()=>{
        this.urlIframe = 'https://partners.beetween.com/WeaselWeb/xe/jobs/h-et-l-620606971?_tc=41&lang=fr_FR&'
        +'j.location='+this.codep
        +'&j.contract=2&sortby=job.date(desc)&paging.count='+this.jobArroundPost.pagingcount
        +'&j.keywords='+this.jobArroundPost.keywords
        +'&from='+this.codep;

        document.getElementById('BetweenIframe').setAttribute('src', this.urlIframe);

        this.servResp = false;

      }, 1000);

      // api google map
      console.log(this.lat);

      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: this.lat,
          lng: this.lng,
        }


        const map = new google.maps.Map(document.getElementById("map"), {
          zoom: 10,
          center: this.center,
          mapTypeId: "terrain",
        });

        this.map = map;

        var marlkerposition = new google.maps.Marker({
          position: this.center,
          map,
          draggable: true,
          title: this.villeName,
        });

        marlkerposition.addListener("change", () => {
          console.log('testT');

        });

        this.cityCircle =  new google.maps.Circle({
          radius: 11 * 1000,
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
          draggable: true,
          map,
          center: this.center,

        });

        this.cityCircle.bindTo('center', marlkerposition, 'position');

        this.markerJobs.forEach(element => {
        console.log(element);

          this.addMarker(element, this.map);
        });


      })

    }

  }

  addMarker(location: google.maps.LatLngLiteral, map: google.maps.Map) {

    var markerJobs = new google.maps.Marker({
      position: location,
      title: this.villeName,
      map: map,
    });

    const infowindow = new google.maps.InfoWindow({
      content: 'Test',
    });

    markerJobs.addListener("click", () => {
      infowindow.open(map, markerJobs);
      setTimeout(() => {
        infowindow.close();
      }, 3000);
    });

  }

}
