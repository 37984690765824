<div class="container-presentation-services bg-light">
  <ngb-carousel [showNavigationIndicators]="false">
    <ng-template ngbSlide *ngFor="let item of listServices">
      <div
        class="picsum-img-wrapper"
        [style.background-image]="
          'url(./assets/presentation-services/' + item.img + ')'
        "
      >
        <!-- <img
          [src]="'./assets/presentation-services/' + item.img"
          [alt]="item.title"
        /> -->
      </div>
      <div class="container-service" [style.background-color]="item.color">
        <div class="service-title" [style.color]="item.color">
          <i [class]="item.icon"></i> {{ item.title }}
        </div>
        <div class="service-description">
          {{ item.description }}
        </div>
        <div class="service-btn-postuler">
          <button
            type="button"
            class="btn btn-outline-light"
            routerLink="recrutement"
          >
            Postuler
          </button>
        </div>
      </div>
    </ng-template>
  </ngb-carousel>
</div>
