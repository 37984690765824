<nav class="navbar navbar-light bg-light">
  <div style="width: 270px">
    <a href="#" class="navbar-show-menu" (click)="showSidebar()"
      ><i class="fas fa-bars"></i
    ></a>
    <button
      class="btn btn-primary button-recherche-presta"
      routerLink="aide-domicile"
    >
      Trouver mon service à domicile
    </button>
  </div>
  <div class="container-navbar-menu">
    <a class="navbar-brand" routerLink="">
      <img src="./assets/Logo_Wanteez.png" height="60" alt="Logo Wanteez" />
    </a>
  </div>
  <div class="navbar-text" style="width: 270px; justify-content: flex-end">
    <div class="div-button-candidater">
      <button
        type="button"
        class="btn btn-outline-primary"
        routerLink="recrutement"
      >
        Candidater
      </button>
    </div>
    <!-- <div class="div-contactez-nous">
      <span class="contactez-nous">Contactez-nous</span>
      <br />
      <span class="num-tel">04 74 11 05 32</span>
      <br />
      <span class="prix-appel-local">Prix appel local non surtaxé</span>
    </div> -->
  </div>
</nav>

<div class="side-menu"></div>
