<div
  class="container-inscription-formulaire"
  [class]="formulaireInscriptionVisible ? 'formulaire-ouvert' : ''"
  id="formulaireInscription"
>
  <div class="div-formulaire">
    <h2 id="titleFormulaire">Candidatez</h2>
    <ngb-alert
      [type]="'danger'"
      [dismissible]="false"
      *ngIf="showFormErrorAge && !showRetourFormSuccess"
    >
      Vous devez avoir 18 ans pour postuler.
    </ngb-alert>
    <ngb-alert
      [type]="'success'"
      [dismissible]="false"
      *ngIf="showRetourContratSuccess"
    >
      Félicitations, votre candidature est maintenant terminée.<br />Notre
      service recrutement vous contactera prochainement.
    </ngb-alert>
    <ngb-alert
      [type]="'danger'"
      [dismissible]="false"
      *ngIf="showRetourFormError"
    >
      Il y a eu une erreur lors de l'inscription, veuillez
      <strong>rééessayer</strong>.
    </ngb-alert>
    <!-- Formulaire étape 1 -->
    <div class="step-form" [class]="isPageRecrutement ? 'show-full-form' : ''">
      <form [formGroup]="form" (ngSubmit)="onSubmit(contratform)">
        <div class="form-group">
          <label>Civilité</label>
          <select class="form-control" formControlName="civilite">
            <option value="null" disabled selected>Civilité</option>
            <option [value]="item.id" *ngFor="let item of listGenres">
              {{ item.label }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label>Nom</label>
          <input
            class="form-control"
            type="text"
            formControlName="nom"
            placeholder="Nom"
          />
        </div>
        <div class="form-group">
          <label>Prénom</label>
          <input
            class="form-control"
            type="text"
            formControlName="prenom"
            placeholder="Prénom"
          />
        </div>
        <div class="form-group">
          <label>Adresse</label>
          <input
            class="form-control"
            type="text"
            formControlName="adresse"
            placeholder="Adresse"
            (focus)="getLocation()"
            ngx-google-places-autocomplete
            [options]="options"
            #placesRef="ngx-places"
            (onAddressChange)="handleAddressChange($event)"
            autocomplete="off"
          />
          <div class="form-text geoloc-adr" *ngIf="lat != null && lng != null">
            <i class="fas fa-location-arrow"></i> Vous avez été localisés à
            cette adresse.
          </div>
        </div>
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label>Code postal</label>
              <input
                type="text"
                class="form-control"
                [class]="this.form.value.codep != null ? 'is-valid' : ''"
                formControlName="codep"
                (change)="changeCodep($event.target.value)"
                placeholder="Code postal"
                autocomplete="off"
              />
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label>Ville</label>
              <input
                type="text"
                class="form-control"
                (change)="changeVille($event.target.value)"
                [class]="this.form.value.ville != null ? 'is-valid' : ''"
                formControlName="ville"
                placeholder="Ville"
                autocomplete="off"
              />
            </div>
          </div>
        </div>

        <div class="form-group">
          <label>Pays</label>
          <input
            class="form-control"
            [class]="this.form.value.country != null ? 'is-valid' : ''"
            type="text"
            formControlName="country"
            placeholder="Pays"
            autocomplete="off"
          />
        </div>

        <div class="form-group">
          <label>Adresse email</label>
          <input
            type="email"
            class="form-control"
            formControlName="email"
            placeholder="Adresse email"
          />
        </div>
        <div class="form-group">
          <label>Numéro de téléphone</label>
          <input
            type="tel"
            pattern="^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$"
            class="form-control"
            formControlName="tel"
            placeholder="Numéro de téléphone"
          />
        </div>

        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label>Date de naissance</label>
              <div class="input-group">
                <input
                  class="form-control"
                  placeholder="Date de naissance"
                  formControlName="date_naissance"
                  ngbDatepicker
                  #d="ngbDatepicker"
                  placement="right"
                  (click)="d.toggle()"
                  [minDate]="{ year: 1920, month: 1, day: 1 }"
                  [markDisabled]="isDisabled"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary btn-date-picker"
                    (click)="d.toggle()"
                    type="button"
                  >
                    <i class="far fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label>Lieu de naissance</label>
              <select class="form-control" formControlName="lieu_naissance">
                <option value="null" disabled selected>
                  Lieu de naissance
                </option>
                <option
                  [value]="item.Id"
                  *ngFor="let item of listLieuxNaissances"
                >
                  {{ item.CountryName }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>Nationalité</label>
          <select class="form-control" formControlName="nationalite">
            <option value="null" disabled selected>Nationalité</option>
            <option [value]="item.Id" *ngFor="let item of listNationalites">
              {{ item.NationalityName }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label>Poste</label>
          <select class="form-control" formControlName="poste">
            <option value="null" disabled selected>Poste</option>
            <option [value]="item.id" *ngFor="let item of listPostes">
              {{ item.label }}
            </option>
          </select>
        </div>
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label>Numéro de sécurité sociale</label>
              <input
                type="text"
                class="form-control"
                formControlName="num_securite_social"
                placeholder="Numéro de sécurité sociale"
              />
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label>Numéro du permis de travail (si hors UE)</label>
              <input
                type="text"
                class="form-control"
                formControlName="num_permis_travail"
                placeholder="Numéro du permis de travail (si hors UE)"
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>Type de transport</label>
          <select class="form-control" formControlName="type_transport">
            <option value="null" disabled selected>Type de transport</option>
            <option [value]="item.Id" *ngFor="let item of listTypesTransports">
              {{ item.TransportName }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label>Durée de travail</label>
          <select class="form-control" formControlName="duree_travail">
            <option value="null" disabled selected>Durée de travail</option>
            <option [value]="item.id" *ngFor="let item of listDureesTravails">
              {{ item.label }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label>Lieu de travail</label>
          <input
            type="text"
            class="form-control"
            formControlName="lieu_travail"
            placeholder="Lieu de travail"
          />
        </div>

        <div class="form-check mb-2">
          <input
            class="form-check-input"
            type="checkbox"
            formControlName="conditions_ok"
            id="conditionsCandidatOk"
            [checked]="this.form.value.conditions_ok"
          />
          <label class="form-check-label" for="conditionsCandidatOk">
            Je reconnais en cochant la présente case, valider et si besoin est
            remplir l’ensemble des conditions nécessaires pour m’engager au
            titre des présentes et les accepter sans réserve.
          </label>
        </div>

        <div class="form-check mb-2">
          <input
            class="form-check-input"
            type="checkbox"
            formControlName="rgpd_ok"
            id="rgpdCandidatOk"
            [checked]="this.form.value.rgpd_ok"
          />
          <label class="form-check-label" for="rgpdCandidatOk">
            J’accepte le traitement de mes Données Personnelles conformément à
            la politique de Données Personnelles de la société H&L.
            <br /><a href="#" (click)="open($event, donnesPersonnelles)"
              ><i class="far fa-hand-point-right"></i> Prendre connaissance de
              notre Politique de traitement de données personnelles</a
            >
          </label>
        </div>

        <button
          type="submit"
          class="btn btn-primary btn-block"
          [disabled]="showLoaderForm"
        >
          Je candidate et je prends connaissance du projet de contrat proposé
        </button>
      </form>
    </div>

    <div
      class="spinner-border text-primary"
      role="status"
      *ngIf="showLoaderForm"
    >
      <!-- <span class="visually-hidden">Loading...</span> -->
    </div>

    <!--Contrat app-->
    <ng-template #contratform let-modal1>
      <div class="modal-header">
        <h4 class="modal-title">Contrat de travail (spécimen)</h4>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="modal1.dismiss()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-contrat [dataClient]="dataClient" [nationality]="nationality">
        </app-contrat>
      </div>
      <div class="modal-footer">
        <div
          class="d-flex justify-content-center"
          *ngIf="showLoaderContratPostuler"
        >
          <div class="spinner-border text-primary" role="status"></div>
          <span class="text-primary ml-2">Veuillez patientez...</span>
        </div>
        <button
          type="button"
          class="btn btn-primary"
          (click)="onSubmitContrat(contratform)"
          [disabled]="showLoaderContratPostuler"
        >
          Je souhaite poursuivre ma candidature et postuler
        </button>
        <button
          type="button"
          class="btn btn-outline-dark"
          (click)="modal1.dismiss()"
          [disabled]="showLoaderContratPostuler"
        >
          Je ne souhaite pas poursuivre ma candidature.
        </button>
      </div>
    </ng-template>
    <!--Contrat app-->
  </div>
</div>

<ng-template #donnesPersonnelles let-modal1>
  <div class="modal-header">
    <h4 class="modal-title">
      Politique de protection des données personnelles
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal1.dismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h3>1. PROPOS INTRODUCTIFS</h3>
    <p>
      Le présent document a pour objet d’informer les conditions dans lesquelles
      la société H&L PRESTATION A DOMICILE , Société par actions simplifiée
      enregistrée au registre du commerce et des sociétés de Vienne sous le
      numéro 477 934 343, dont le siège social se situe Espace Saint
      Germain-Immeuble « Le Miles », 30 avenue du Général Leclerc à Vienne
      (38200) s’engage à effectuer les opérations de traitement sur les données
      à caractère personnel qu’elle traite, en qualité de responsable des
      traitements. Cette politique de confidentialité et de protection de
      données personnelles s’adresse aux clients et prospects de la société H&L.
      En l’occurrence, la société H&L s’engage à se conformer aux dispositions
      suivantes : (ci-après les « Dispositions relatives aux Données
      Personnelles ») :
      <br />• la loi n° 78-17 du 6 janvier 1978 relative à l'informatique, aux
      fichiers et aux libertés, <br />• le Règlement Général sur la Protection
      des Données 2016/679 du 27 avril 2016 (ci -après « RGPD).
    </p>

    <h3>2. RECAPITULATIF DES DONNEES TRAITEES</h3>
    <p>
      Les données suivantes peuvent être collectées par la société H&L :
      <br />1.État-civil, identité: Nom, prénom, adresse, courriel
      <br />2.Toutes informations complémentaires concernant le prospect et/ou
      le client que ces derniers choisissent de communiquer lors de leur
      présentation et/ou dans les champs libres. <br />La collecte des données
      personnelles des clients et/ou prospects, à l’exception de celle(s)
      mentionnée(s) comme facultative(s), est obligatoire.
    </p>

    <h3>3. FINALITES ET BASES LEGALES DU TRAITEMENT</h3>
    <p>
      Les traitements de données à caractère personnel mis en œuvre ont les
      bases juridiques suivantes :
      <br />1. l’exécution de mesures précontractuelles ou du contrat lorsque la
      société H&L met en œuvre un traitement ayant pour finalité : la
      production, la gestion, le suivi des dossiers de ses clients et/ou
      prospects ; le recouvrement. <br />2. l’intérêt légitime poursuivi par la
      société H&L lorsqu’elle poursuit les finalités suivantes : o la gestion de
      la relation avec les clients et les prospects ; <br />• la
      personnalisation, l’évaluation et l’amélioration des services fournis ;
      <br />• la personnalisation, l’évaluation et l’amélioration de
      l’utilisation de la Plateforme ; <br />• la prévention et la lutte contre
      la fraude informatique ; <br />• l’utilisation de la plateforme de la
      société H&L. <br />3. le respect d’obligations légales et réglementaires
      lorsqu’il met en œuvre un traitement ayant pour finalité : <br />• la
      prévention du blanchiment et du financement du terrorisme et la lutte
      contre la corruption ; <br />• la facturation ; <br />• la comptabilité.
      <br />4. Le consentement lorsque la société H&L poursuit les finalités
      suivantes : <br />• la transmission de lettres d’information et/ou
      d’articles <br />• la prospection et l’animation
    </p>
    <h3>4. LA SECURITE ET LA CONSERVATION DES DONNEES</h3>
    <p>
      La société H&L prendra toutes précautions utiles aux fins de préserver la
      sécurité et la confidentialité des données des prospects et/ou des clients
      et, notamment, empêcher qu'elles soient déformées, endommagées, ou que des
      tiers non autorisés y aient accès.
    </p>
    <h3>5. LES DESTINATAIRES DES DONNEES PERSONNELLES</h3>
    <p>
      Les données collectées sont destinées au responsable du traitement (soit
      la société H&L). Elles pourront également être transmises aux
      destinataires suivants : <br />- l’intervenant salarié de la société H&L,
      qui effectuera sa prestation chez le client. <br />- le mandataire du
      client : Il est à cet effet précisé qu’aux fins d’être destinataire des
      données personnelles du client, la personne concernée par le traitement de
      ses données doit expressément consentir à ce que le mandataire puisse
      avoir accès à ses données personnelles, par l’intermédiaire d’un mandat.
    </p>
    <h3>6. LE TRANSFERT DES DONNEES PERSONNELLES</h3>
    <p>
      La société H&L n’effectue aucun transfert des données personnelles de ses
      prospects et/ou clients vers des pays étrangers (hors Union Européenne).
      Si la société H&L est tenue de procéder à un transfert de données vers un
      pays tiers ou à une organisation internationale, elle en informera ces
      derniers avant le traitement.
    </p>
    <h3>7. DUREE DE CONSERVATION DES DONNEES COLLECTEES</h3>
    <p>
      La société H&L ne conserve les données que pour la durée nécessaire aux
      opérations pour lesquelles elles ont été collectées ainsi que dans le
      respect de la règlementation en vigueur. <br />A cet égard, les données
      des clients sont conservées pendant la durée des relations contractuelles
      augmentée de 3 ans à des fins d'animation et prospection, sans préjudice
      des obligations de conservation ou des délais de prescription. <br />En
      matière de prévention du blanchiment et du financement du terrorisme, les
      données sont conservées 5 ans après la fin des relations avec la société
      H&L. <br />En matière de comptabilité, elles sont conservées 10 ans à
      compter de la clôture de l'exercice comptable. <br />Les données des
      prospects sont conservées pendant une durée de 3 ans si aucune
      participation ou inscription aux événements de la société H&L n’a eu lieu.
    </p>
    <h3>8. DROITS DES CLIENTS ET PROSPECTS</h3>
    <p>
      En application des Dispositions relatives aux Données Personnelles, les
      clients et/ou prospects peuvent à tout moment accéder aux données les
      concernant détenues par la société H&L, et demander leur limitation,
      portabilité, rectification ou leur effacement. <br />Ainsi, les clients
      et/ou prospects peuvent demander que soient rectifiées, complétées,
      clarifiées, mises à jour ou effacées les données personnelles les
      concernant qui sont inexactes, incomplètes, équivoques, périmées ou dont
      la collecte ou l'utilisation, la communication ou la conservation sont
      interdites. <br />Les clients et/ou les prospects disposent également d’un
      droit de s’opposer à tout moment, pour des raisons tenant à leur situation
      particulière, au traitement de leurs données à caractère personnel ayant
      comme base juridique l’intérêt légitime de la société H&L. <br />En
      l’occurrence, la société H&L garantit que ses demandes seront prises en
      compte dans les meilleurs délais. <br />Les clients et/ou prospects
      peuvent exercer leurs droits et obtenir communication des informations les
      concernant par courrier électronique en s’adressant à admin@wanteez.fr ou
      à l’adresse suivante : Wanteez by H&L 30 Avenue Général Leclerc Espace St
      Germain Bat 2 Le Miles 38200 VIENNE.<br />
      Il est par ailleurs rappelé que les clients et/ou prospects disposent d’un
      droit d'introduire une réclamation auprès de l’autorité de contrôle
      compétente en cas de non-respect par la société H&L, de ses obligations.
    </p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal1.dismiss()"
    >
      Fermer
    </button>
  </div>
</ng-template>
