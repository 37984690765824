import { ViewportScroller } from '@angular/common';
import {
  Component,
  ElementRef,
  Injectable,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  ModalDismissReasons,
  NgbDate,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerI18n,
  NgbDateStruct,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import {
  fadeInLeftAnimation,
  fadeInOnEnterAnimation,
  fadeInLeftOnEnterAnimation,
  zoomInOnEnterAnimation,
  bounceInOnEnterAnimation,
  pulseOnEnterAnimation,
  bounceAnimation,
  fadeInAnimation,
  expandOnEnterAnimation,
  fadeInExpandOnEnterAnimation,
} from 'angular-animations';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../services/api.service';

declare const google: any;

export const atLeastOne =
  (validator: ValidatorFn) =>
  (group: FormGroup): ValidationErrors | null => {
    const hasAtLeastOne =
      group.controls['fixe'].value == '' &&
      group.controls['mobile'].value == '';
    return !hasAtLeastOne ? null : { atLeastOne: true };
  };

const I18N_VALUES = {
  fr: {
    weekdays: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
    months: [
      'Jan',
      'Fév',
      'Mar',
      'Avr',
      'Mai',
      'Juin',
      'Juil',
      'Aou',
      'Sep',
      'Oct',
      'Nov',
      'Déc',
    ],
    weekLabel: 'sem',
  },
  // other languages you would support
};

// Define a service holding the language. You probably already have one if your app is i18ned. Or you could also
// use the Angular LOCALE_ID value
@Injectable()
export class I18n {
  language = 'fr';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getWeekLabel(): string {
    return I18N_VALUES[this._i18n.language].weekLabel;
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
  readonly DELIMITER = '-';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[2], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[0], 10),
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date
      ? date.year + this.DELIMITER + date.month + this.DELIMITER + date.day
      : null;
  }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year
      : '';
  }
}

@Component({
  selector: 'app-header-search-offres',
  templateUrl: './header-search-offres.component.html',
  styleUrls: ['./header-search-offres.component.scss'],
  providers: [
    I18n,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
  animations: [
    fadeInOnEnterAnimation(),
    fadeInAnimation(),
    pulseOnEnterAnimation(),
    bounceAnimation(),
    expandOnEnterAnimation(),
    fadeInExpandOnEnterAnimation(),
  ],
})
export class HeaderSearchOffresComponent implements OnInit {
  lat = null;
  lng = null;
  geolocDone = false;

  @ViewChild('placesRef') placesRef: GooglePlaceDirective;
  options: {
    types: [];
    componentRestrictions: { country: 'FR' };
  };

  showLoaderForm: boolean = false;
  showLoaderSignatureDevis: boolean = false;
  showLoaderEnvoiDevisNonSigne: boolean = false;
  showRetourFormSuccess: boolean = false;
  showRetourFormDevisSigneSuccess: boolean = false;
  showRetourFormError: boolean = false;
  showFormErrorValidation: boolean = false;
  showFormErrorAge: boolean = false;
  isShowFormCoordonnees: boolean = false;

  showCalculateurDevis: boolean = false;
  showCart: boolean = false;
  bounceTotalCoutReel: boolean = false;

  htmlDevis = '';
  htmlCgvDevis = '';

  listTypeOffres = [
    {
      label: 'Maison',
      icon: 'fas fa-home',
    },
    {
      label: 'Enfance',
      icon: 'fas fa-baby',
    },
    {
      label: 'Dépendance',
      icon: 'fas fa-hands-helping',
    },
    {
      label: 'Jardin',
      icon: 'fas fa-seedling',
    },
    {
      label: 'Loisirs',
      icon: 'fas fa-puzzle-piece',
    },
  ];

  listStatuts = [
    {
      id: 1,
      label: 'Particulier',
    },
    {
      id: 2,
      label: 'Aidant',
    },
    {
      id: 3,
      label: 'Agent social',
    },
    {
      id: 4,
      label: 'Accompagnant médical',
    },
  ];

  listServices = [];

  listGenres = [
    {
      id: 'Mr',
      label: 'Monsieur',
    },
    {
      id: 'Mrs',
      label: 'Madame',
    },
  ];

  form: FormGroup;

  errorsPhonesRequired: boolean = false;

  servicesDatas = [];
  totalTTC = 0.0;
  totalCoutReel = 0.0;

  maxDateNaissance = null;

  isMobileLayout = false;
  clientExist = false;

  nbPrestaChosen: number = 0;

  isDisabled = (date: NgbDate, current) => {
    let dateJour = new Date();
    return (
      date.day > dateJour.getDate() &&
      date.month > dateJour.getMonth() &&
      date.year >= dateJour.getFullYear()
    );
  };

  @ViewChild('modalDevis') modalDevis: ElementRef;
  @ViewChild('closeButtonDevis') closeButtonDevis: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    private viewportScroller: ViewportScroller,
    private api: ApiService,
    private modalService: NgbModal
  ) {
    this.form = this.formBuilder.group(
      {
        statut: new FormControl(null, [Validators.required]),
        civilite: new FormControl(null, [Validators.required]),
        nom: new FormControl('', [Validators.required]),
        prenom: new FormControl('', [Validators.required]),
        adresse: new FormControl('', [Validators.required]),
        codep: new FormControl('', [Validators.required]),
        ville: new FormControl('', [Validators.required]),
        fixe: new FormControl('', [
          Validators.pattern(
            /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/
          ),
          Validators.minLength(10),
        ]),
        mobile: new FormControl('', [
          Validators.pattern(
            /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/
          ),
          Validators.minLength(10),
        ]),
        email: new FormControl('', [Validators.required, Validators.email]),
        conditions_ok: new FormControl(false, [Validators.requiredTrue]),
        rgpd_ok: new FormControl(false, [Validators.requiredTrue]),
        date_naissance: new FormControl(null, [Validators.required]),
      },
      { validator: atLeastOne(Validators.required) }
    );

    let dateJour = new Date();
    this.maxDateNaissance = {
      year: dateJour.getDate(),
      month: dateJour.getMonth() + 1,
      day: dateJour.getFullYear(),
    };

    this.getListOptions();
  }

  resetForm() {
    this.form.reset();
    this.totalTTC = 0.0;
    this.totalCoutReel = 0.0;
    this.showCart = false;
    this.showRetourFormSuccess = false;
    this.showFormErrorAge = false;
    this.showFormErrorValidation = false;
    this.showRetourFormError = false;
    this.isShowFormCoordonnees = false;
    this.nbPrestaChosen = 0;
    this.servicesDatas.forEach((item) => {
      item.nb_heures = 0;
      item.nb_heures_weekend = 0;
      item.presta_recurente = 1;
      item.presta_recurente_we = 1;
      // item.presta_weekend = 0;
      item.presta_frequence = 'S';
      item.presta_frequence_we = 'S';
      item.chosen = false;
    });
    this.lat = null;
    this.lng = null;
  }

  getListOptions() {
    // On fait appel au back office pour aller chercher les options de la liste
    this.api.getListsOptions().subscribe((resp) => {
      if (resp != null && resp.success && resp.datas[0].listSkills.length > 0) {
        this.listServices = resp.datas[0].listSkills;

        // On créé les champs des skills dynamiquement dans le formulaire
        for (let i = 0; i < this.listServices.length; i++) {
          let idService = this.listServices[i].Id;
          let iconService = this.listServices[i].Icon;
          let nameService = this.listServices[i].Name;
          let nameShortService = this.listServices[i].Name_short;
          this.servicesDatas.push({
            _id: i,
            Id: idService,
            Icon: iconService,
            Name: nameService,
            Name_short: nameShortService,
            nb_heures: 0,
            nb_heures_weekend: 0,
            presta_recurente: 1,
            presta_recurente_we: 1,
            // presta_weekend: 0, // TODO : on va pouvoir l'enlever finalement
            presta_frequence: 'S',
            presta_frequence_we: 'S',
            prix_unitaire: environment.tarifsPrestaTtc.recurent,
            prix_unitaire_we: environment.tarifsPrestaTtc.recurent_we,
            chosen: false,
          });
        }
      } else {
      }
      console.log('PRESTAS ', this.servicesDatas);
    });
  }

  showFormCoordonnees() {
    this.isShowFormCoordonnees = true;
    setTimeout(() => {
      let element = document.getElementById('formCoordonnees');
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }, 200);
  }

  prestaRecurenteChanged(index, event) {
    let objIndex = this.servicesDatas.findIndex((obj) => obj._id == index);
    this.servicesDatas[objIndex].presta_recurente = event.target.value;
    this.calculPrixTotal();
  }

  prestaRecurenteWeChanged(index, event) {
    let objIndex = this.servicesDatas.findIndex((obj) => obj._id == index);
    this.servicesDatas[objIndex].presta_recurente_we = event.target.value;
    this.calculPrixTotal();
  }

  prestaFrequenceChanged(index, event) {
    let objIndex = this.servicesDatas.findIndex((obj) => obj._id == index);
    this.servicesDatas[objIndex].presta_frequence = event.target.value;
    this.calculPrixTotal();
  }

  prestaFrequenceWeChanged(index, event) {
    let objIndex = this.servicesDatas.findIndex((obj) => obj._id == index);
    this.servicesDatas[objIndex].presta_frequence_we = event.target.value;
    this.calculPrixTotal();
  }

  // prestaWeekendChanged(index, event) {
  //   let objIndex = this.servicesDatas.findIndex((obj) => obj._id == index);
  //   this.servicesDatas[objIndex].presta_weekend = event.target.checked;
  //   this.calculPrixTotal();
  // }

  prestaChosen(index) {
    let objIndex = this.servicesDatas.findIndex((obj) => obj._id == index);
    this.servicesDatas[objIndex].chosen = !this.servicesDatas[objIndex].chosen;
    this.nbPrestaChosen = 0;
    for (let i = 0; i < this.servicesDatas.length; i++) {
      if (this.servicesDatas[i].chosen) {
        this.nbPrestaChosen++;
      } else {
        this.servicesDatas[i].nb_heures = 0;
        this.servicesDatas[i].nb_heures_weekend = 0;
      }
    }
    this.calculPrixTotal();
  }

  updateNbHeuresService(index, increment) {
    let objIndex = this.servicesDatas.findIndex((obj) => obj._id == index);
    let idService = this.servicesDatas[objIndex].Id;
    let nbHeures = this.servicesDatas[objIndex].nb_heures;
    let newNbHeures = nbHeures + increment;

    if (newNbHeures < 2 && idService != 127) {
      // 127 = Auxiliaire de vie : possible 1h
      if (increment > 0) {
        // Sinon, à partir de 2h
        newNbHeures = 2;
      } else {
        newNbHeures = 0;
      }
    } else if (newNbHeures < 1 && idService == 127) {
      if (increment > 0) {
        newNbHeures = 1;
      } else {
        newNbHeures = 0;
      }
    }

    this.servicesDatas[objIndex].nb_heures = newNbHeures;

    if (newNbHeures >= 0) {
      this.calculPrixTotal();
    }

    return false;
  }

  updateNbHeuresServiceWeekend(index, increment) {
    let objIndex = this.servicesDatas.findIndex((obj) => obj._id == index);
    let idService = this.servicesDatas[objIndex].Id;
    let nbHeures = this.servicesDatas[objIndex].nb_heures_weekend;
    let newNbHeures = nbHeures + increment;

    if (newNbHeures < 2 && idService != 127) {
      // 127 = Auxiliaire de vie : possible 1h
      if (increment > 0) {
        // Sinon, à partir de 2h
        newNbHeures = 2;
      } else {
        newNbHeures = 0;
      }
    } else if (newNbHeures < 1 && idService == 127) {
      if (increment > 0) {
        newNbHeures = 1;
      } else {
        newNbHeures = 0;
      }
    }

    this.servicesDatas[objIndex].nb_heures_weekend = newNbHeures;

    if (newNbHeures >= 0) {
      this.calculPrixTotal();
    }

    return false;
  }

  calculPrixTotal() {
    this.totalTTC = 0.0;
    this.totalCoutReel = 0.0;
    this.servicesDatas.forEach((item) => {
      // Journée
      let frequence = 4;
      if (item.presta_frequence == '15J') {
        frequence = 2; // Tous les 15 jours
      } else if (item.presta_frequence == 'M') {
        frequence = 1; // 1 fois par mois
      }
      if (item.presta_recurente == 1) {
        item.prix_unitaire = environment.tarifsPrestaTtc.recurent;
        this.totalTTC =
          this.totalTTC +
          environment.tarifsPrestaTtc.recurent * (item.nb_heures * frequence);
      } else {
        frequence = 1;
        item.prix_unitaire = environment.tarifsPrestaTtc.ponctuel;
        this.totalTTC =
          this.totalTTC +
          environment.tarifsPrestaTtc.ponctuel * (item.nb_heures * frequence);
      }

      // Nuit et week-end
      let frequenceWe = 4;
      if (item.presta_frequence_we == '15J') {
        frequenceWe = 2; // Tous les 15 jours
      } else if (item.presta_frequence_we == 'M') {
        frequenceWe = 1; // 1 fois par mois
      }

      if (item.presta_recurente_we == 1) {
        item.prix_unitaire_we = environment.tarifsPrestaTtc.recurent_we;
        this.totalTTC =
          this.totalTTC +
          environment.tarifsPrestaTtc.recurent_we *
            (item.nb_heures_weekend * frequenceWe);
        // console.log(
        //   'environment.tarifsPrestaTtc.ponctuel_we',
        //   environment.tarifsPrestaTtc.ponctuel_we
        // );
        // console.log('item.nb_heures_weekend', item.nb_heures_weekend);
        // console.log('frequenceWe', frequenceWe);
      } else {
        item.prix_unitaire_we = environment.tarifsPrestaTtc.ponctuel_we;
        this.totalTTC =
          this.totalTTC +
          environment.tarifsPrestaTtc.ponctuel_we *
            (item.nb_heures_weekend * frequenceWe);
      }
    });

    // Cout total Reel
    this.totalCoutReel = this.totalTTC / 2;
    if (this.totalCoutReel > 0) {
      this.showCart = true;
    } else {
      this.showCart = false;
    }
    this.bounceTotalCoutReel = true;
    setTimeout(() => {
      this.bounceTotalCoutReel = false;
    }, 100);
  }

  ngOnInit(): void {
    console.log('window.innerWidth', window.innerWidth);
    this.isMobileLayout = window.innerWidth <= 800;
    window.onresize = () => (this.isMobileLayout = window.innerWidth <= 800);
    console.log('isMobileLayout', this.isMobileLayout);
  }

  async onSubmit() {
    // Validation form
    let datas = this.form.value;
    console.warn('FORM DATAS : ', datas);
    console.warn('FORM DATAS VALID : ', this.form.valid);

    this.showFormErrorAge = false;
    if (datas.date_naissance != null) {
      let timeDiff = Math.abs(
        Date.now() - new Date(datas.date_naissance).getTime()
      );
      let age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
      if (age < 18) {
        this.showFormErrorAge = true;
        let element = document.getElementById('hautFormDevis');
        element.scrollIntoView(true);
        return false;
      }
    }

    this.errorsPhonesRequired = false;
    if (
      this.form.invalid ||
      (this.form.value.fixe == '' && this.form.value.mobile == '') ||
      this.totalTTC <= 0
    ) {
      if (this.form.value.fixe == '' && this.form.value.mobile == '') {
        this.errorsPhonesRequired = true;
      }
      this.showFormErrorValidation = true;
      let element = document.getElementById('hautFormDevis');
      element.scrollIntoView(true);
      return;
    }

    // On ajoute les données des heures de services choisies
    datas.services = this.servicesDatas;

    this.showLoaderForm = true;
    this.showCalculateurDevis = true;

    this.api.createClient(datas).subscribe((resp) => {
      this.showLoaderForm = false;
      console.log('response', resp);
      if (resp != null) {
        if (resp.success) {
          this.showRetourFormSuccess = true;
          this.modalService.open(this.modalDevis, { size: 'xl' });
          this.htmlDevis = resp.view_devis;
          this.htmlCgvDevis = resp.view_cgv;
          this.clientExist = resp.clientExist;
        } else {
          this.showRetourFormError = false;
        }
      } else {
        this.showRetourFormError = false;
      }
    });
  }

  signerLeDevisMaintenant() {
    let datas = this.form.value;
    console.log(datas);

    // On ajoute les données des heures de services choisies
    datas.services = this.servicesDatas;
    datas.clientExist = this.clientExist;

    this.htmlDevis = '';
    this.showRetourFormSuccess = false;
    this.showLoaderSignatureDevis = true;
    this.showRetourFormDevisSigneSuccess = false;

    this.api.signatureDevis(datas).subscribe((resp) => {
      console.log('response', resp);
      if (resp != null) {
        if (resp.success) {
          this.resetForm();
          this.showRetourFormDevisSigneSuccess = true;
          this.showLoaderSignatureDevis = false;
          this.htmlDevis = resp.view_devis_signe;
        }
      }
    });
  }

  sendDevisClientNonSigne() {
    let datas = this.form.value;
    console.log(datas);

    // On ajoute les données des heures de services choisies
    datas.services = this.servicesDatas;
    datas.clientExist = this.clientExist;

    this.showRetourFormSuccess = false;
    this.showLoaderEnvoiDevisNonSigne = true;
    this.htmlDevis = '';

    this.api.sendDevisClientNonSigne(datas).subscribe((resp) => {
      console.log('response', resp);
      if (resp != null) {
        if (resp.success) {
          this.showLoaderEnvoiDevisNonSigne = false;
          this.resetForm();
          this.closeModalDevis();
          setTimeout(() => {
            let element = document.getElementById('formulaireDevis');
            element.scrollIntoView(true);
          });
          console.log('DEVIS ENVOYE !');
        }
      }
    });
  }

  closeModalDevis() {
    let element: HTMLElement = document.getElementById(
      'closeButtonDevis'
    ) as HTMLElement;
    element.click();
    this.resetForm();
  }

  public goToDemandeDevis(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }

  async getLocation() {
    if (this.lat == null && this.lng == null) {
      if (navigator.geolocation) {
        await this.getPositionAsync()
          .then((position: GeolocationPosition) => {
            if (position) {
              this.lat = position.coords.latitude;
              this.lng = position.coords.longitude;
              console.log('lat', this.lat);
              console.log('lng', this.lng);
              this.geolocDone = true;

              let geocoder = new google.maps.Geocoder();
              let latlng = new google.maps.LatLng(this.lat, this.lng);
              let request = {
                latLng: latlng,
              };

              geocoder.geocode(request, (results, status) => {
                if (status == google.maps.GeocoderStatus.OK) {
                  console.log('results geocoder', results);
                  if (results[0] != null) {
                    for (var i = 0; i < results.length; i++) {
                      for (var j = 0; j < results[i].types.length; j++) {
                        if (results[i].types[j] == 'premise') {
                          this.handleAddressChange(results[i]);
                        }
                      }
                    }
                  } else {
                    alert('No address available');
                  }
                }
              });
            }
          })
          .catch((err: GeolocationPositionError) => {
            console.error(err);
            this.geolocDone = true;
          });
      } else {
        alert('Erreur lors de la géolocalisation de votre position.');
      }
    }
  }

  async getPositionAsync(): Promise<GeolocationPosition> {
    return new Promise((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(resolve, reject)
    );
  }

  public handleAddressChange(address: Address) {
    console.log('adress', address);
    let codeP = '';
    let ville = '';
    for (var i = 0; i < address.address_components.length; i++) {
      for (var j = 0; j < address.address_components[i].types.length; j++) {
        if (address.address_components[i].types[j] == 'street_number') {
          console.log('street_number', address.address_components[i].long_name);
          this.form.patchValue({
            adresse: address.address_components[i].long_name,
          });
        }
        if (address.address_components[i].types[j] == 'route') {
          console.log('route', address.address_components[i].long_name);
          this.form.patchValue({
            adresse:
              this.form.get('adresse').value +
              ' ' +
              address.address_components[i].long_name,
          });
        }

        if (address.address_components[i].types[j] == 'postal_code') {
          console.log('codep', address.address_components[i].long_name);
          this.form.patchValue({
            codep: address.address_components[i].long_name,
          });
          this.form.get('codep').updateValueAndValidity();
          codeP = address.address_components[i].long_name;
        }
        if (address.address_components[i].types[j] == 'locality') {
          console.log('locality', address.address_components[i].long_name);
          this.form.get('ville').updateValueAndValidity();
          this.form.patchValue({
            ville: address.address_components[i].long_name,
          });
          ville = address.address_components[i].long_name;
        }
      }
    }
  }

  open(event, content) {
    event.stopPropagation();
    this.modalService.open(content, { size: 'xl' });
    return false;
  }
}
