<div class="container-footer">
  <div class="div-logo">
    <img
      src="./assets/logo_wanteez-texte-blanc.png"
      height="40"
      alt="Logo Wanteez"
    />
  </div>

  <div class="div-logos-stores">
    <a href="#"><img src="./assets/footer/app_store.svg" /></a>
    <a href="#"><img src="./assets/footer/google_play.svg" /></a>
  </div>
  <div class="div-application-bientot">L'application arrive bientôt !</div>

  <div class="div-logos-reseaux-sociaux">
    <a href="https://www.facebook.com/wanteez.services/"
      ><i class="bi bi-facebook"></i
    ></a>
    <a href="https://fr.linkedin.com/company/wanteez"
      ><i class="bi bi-linkedin"></i
    ></a>
  </div>

  <div class="div-mentions-legales">
    <span
      ><a href="#" (click)="open($event, mentionsLegales)"
        >Mentions légales générales</a
      ></span
    >
    <span>|</span>
    <span
      ><a href="#" (click)="open($event, conditionsGeneralesServices)"
        >Conditions générales de services</a
      ></span
    >
    <span>|</span>
    <span><a href="mailto:contact@h-et-l.com">Nous contacter</a></span>
  </div>

  <div class="div-a-propos">
    <div>© 2021 Wanteez. All rights reserved.</div>
    <div>Une marque H&L</div>
  </div>
</div>

<ng-template #mentionsLegales let-modal1>
  <div class="modal-header">
    <h4 class="modal-title">Mentions légales générales</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal1.dismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      <strong>Propriétaire :</strong> Société H&L PRESTATION A DOMICILE, Société
      par actions simplifiée enregistrée au registre du commerce et des sociétés
      de Vienne sous le numéro 477 934 343, dont le siège social se situe Espace
      Saint Germain-Immeuble « Le Miles », 30 avenue du Général Leclerc à Vienne
      (38200).
    </p>

    <p><strong>Numéro de téléphone : </strong>04 74 11 05 32</p>
    <p><strong>Courriel :</strong> admin@wanteez.fr</p>
    <p><strong>Direction de la publication :</strong> Frédéric HARO</p>
    <p><strong>Hébergeur :</strong></p>
    <p><strong>Propriété intellectuelle : </strong></p>
    <p>
      La société H&L est propriétaire des droits de propriété intellectuelle et
      détient les droits d’usage sur tous les éléments accessibles sur le
      présent site internet, notamment les textes, images, graphismes, logos,
      vidéos et icônes. L’ensemble des éléments de ce site relève dès lors de la
      législation française et internationale sur le droit d’auteur et la
      propriété intellectuelle.
    </p>
    <p>
      Toute reproduction, représentation, modification, publication, adaptation
      de tout ou partie des éléments du pérsent site internet, quel que soit le
      moyen ou le procédé utilisé, est interdite, sauf autorisation écrite
      préalable de la société H&L.
    </p>
    <p>
      Toute exploitation non autorisée du site ou de l’un quelconque des
      éléments qu’elle contient sera considérée comme constitutive d’une
      contrefaçon et poursuivie conformément aux dispositions des articles
      L.335-2 et suivants du Code de Propriété Intellectuelle.
    </p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal1.dismiss()"
    >
      Fermer
    </button>
  </div>
</ng-template>

<ng-template #conditionsGeneralesServices let-modal2>
  <div class="modal-header">
    <h4 class="modal-title">Conditions générales de services</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal2.dismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h3>1. PREAMBULE</h3>

    <p>
      Les présentes conditions d’utilisation (ci-après « Conditions
      d’Utilisation ») s’appliquent aux personnes qui bénéficient d’une
      Prestation à travers la Plateforme (ci-après « Client »).
    </p>

    <p>
      Tout Client est réputé avoir pris connaissance des présentes Conditions
      d’Utilisation qui sont en permanence accessibles à l’adresse…... .
      L’acceptation des Conditions d’Utilisation est entière et indivisible, et
      ne saurait faire l’objet de limitation ou réserves.
    </p>

    <h3>2. DEFINITIONS</h3>

    Pour l'exécution et l'interprétation des présentes Conditions d’Utilisation,
    les mots suivants, dont la première lettre est en majuscule, qu’ils soient
    au singulier ou au pluriel ont la signification qui leur est donnée ci-après
    : Compte désigne l’espace individuel accessible sur la Plateforme, propre à
    chaque Client. Chaque Compte se verra attribuer un identifiant et un mot de
    passe. L’utilisation de ces éléments est sous la seule et entière
    responsabilité du Client. Données Personnelles désigne les données au sens
    de la loi n°78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers
    et aux libertés et au règlement général sur la protection des données
    2016/679 du 27 avril 2016. H&L et/ou société H&L désigne la Société H&L
    PRESTATION A DOMICILE, Société par actions simplifiée enregistrée au
    registre du commerce et des sociétés de Vienne sous le numéro 477934343 et
    dont le siège social se situe Espace Saint Germain-Immeuble « Le Miles », 30
    avenue du Général Leclerc à Vienne (38200). Mandat désigne l’acte par lequel
    le Client donne le droit à une tierce personne de se connecter sur la
    Plateforme en son nom et pour son compte et d’accéder à ses Données
    personnelles et/ou gérer ses Prestations. Notation désigne l’acte par lequel
    le Client émettra une note ou un commentaire sur la Prestation réalisée par
    l’intervenant. Plateforme désigne le site internet www.wanteez.fr Prestation
    désigne les services souscrits par le Client auprès d’H&L via la Plateforme,
    après validation du devis . Partie(s) désigne(nt) individuellement la
    société H&L ou le Client et collectivement les deux.

    <h3>3. OBJET</h3>

    Les présentes Conditions d’Utilisation ont pour objet de définir les
    modalités d’utilisation de la Plateforme aux fins de bénéficier des
    Prestations.

    <h3>4. PROPRIETE INTELLECTUELLE</h3>

    La société H&L est propriétaire des droits de propriété intellectuelle et
    détient les droits d’usage sur tous les éléments accessibles sur la
    Plateforme, notamment les textes, images, graphismes, logos, vidéos et
    icônes. L’ensemble des éléments de la Plateforme relève dès lors de la
    législation française et internationale sur le droit d’auteur et la
    propriété intellectuelle. Toute reproduction, représentation, modification,
    publication, adaptation de tout ou partie des éléments de la Plateforme,
    quel que soit le moyen ou le procédé utilisé, est interdite, sauf
    autorisation écrite préalable de la société H&L. Toute exploitation non
    autorisée de la Plateforme ou de l’un quelconque des éléments qu’elle
    contient sera considérée comme constitutive d’une contrefaçon et poursuivie
    conformément aux dispositions des articles L.335-2 et suivants du Code de
    Propriété Intellectuelle. Le Client s’engage expressément à respecter les
    droits de propriété intellectuelle de la société H&L.

    <h3>5. ACCES ET UTILISATION DE LA PLATEFORME</h3>

    Le Client reconnait disposer des équipements (ordinateurs, logiciels,
    tablettes, moyens de communication électronique, etc.) nécessaires à l'accès
    à la Plateforme et s’engage à assumer le coût relatif à ces équipements
    (coûts de télécommunication, souscription de forfaits Internet, etc.).

    <h4>5.1 Modalités d’accès à la Plateforme</h4>

    Le Client accède à la Plateforme et à son Compte via l’utilisation du réseau
    internet. Préalablement à toute mise en œuvre d’un accès à la Plateforme, le
    Client se voit adresser un courrier électronique à l’adresse mail qu’il aura
    communiqué préalablement à la société H&L. Ce courriel comportera : Un
    identifiant Un mot de passe La saisine de ces deux informations sera
    nécessaire pour accéder à un Compte. Le Client est informé que tout
    identifiant et mot de passe qui lui est communiqué constitue une mesure de
    sécurité destinée à limiter toute utilisation malveillante ou abusive de son
    Compte.

    <h4>5.2 Perte des identifiants - Utilisation frauduleuse du Compte</h4>

    Le Client est le seul responsable de l’utilisation frauduleuse de ses accès
    et de son mot de passe. Dans le cas où le Client confierait volontairement
    son identifiant et son mot de passe à un tiers quel qu’il soit, il est
    rappelé qu’il le fera sous sa seule et entière responsabilité. Le Client
    doit signaler à la société H&L toute tentative de violation de son compte
    et, de façon générale, toute anomalie qu’il pourrait constater par courrier
    électronique à l’adresse admin@wanteez.fr En cas de perte ou de vol d’un mot
    de passe, tout Client a la possibilité de le réinitialiser via un accès à
    une fonctionnalité dédiée de la Plateforme .

    <h4>5.3 Notification</h4>

    Pour chaque nouvelle information publiée sur la Plateforme par la société
    H&L, le Client recevra une notification par courrier électronique
    l’informant d’une actualisation de son Compte.

    <h4>5.4 Mandataire</h4>

    Seul le Client peut accéder à son Compte, qui est personnel et incessible
    sous réserve de ce qui suit. Le Client demeure libre de mandater un tiers à
    cette fin. Pour ce faire, le Client et le mandataire devront au préalable
    remplir le Mandat et suivre la procédure indiquée à l’article 10 des
    présentes.

    <h3>6. DONNEES PERSONNELLES</h3>

    La société H&L s’engage à respecter les dispositions suivantes : • la loi n°
    78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux
    libertés, • le Règlement Général sur la Protection des Données 2016/679 du
    27 avril 2016 (ci -après « RGPD). Les mentions afférentes aux Données
    Personnelles du Client sont accessibles en permanence sur le lien suivant :
    Il est impératif que le Client en prenne connaissance et accepte
    expressément la politique de données personnelles de la société H&L.

    <h3>7. OBLIGATION DES CLIENTS</h3>

    Le Client s’interdit, dans le cadre de l’utilisation de la Plateforme, de se
    livrer à des actes, de quelque nature que ce soit, qui seraient contraires
    aux dispositions législatives et règlementaires et/ou porteraient atteinte à
    l’ordre public ou aux droits d’un tiers. En particulier, sans que cette
    liste soit exhaustive le Client s’interdit :

    <ul>
      <li>
        de publier, transmettre, diffuser, éditer ou rendre accessible sur ou
        par le biais de la Plateforme tout contenu qui pourrait être
        constitutif, sans que cette liste soit limitative, de diffamation,
        d’injures, de menaces, de chantage, de harcèlement, d'incitation à la
        violence, à la haine raciale et plus généralement à la réalisation de
        crimes et délits.
      </li>
      <li>
        de tenter d'induire en erreur la société H&L notamment en usurpant
        l'identité d’une autre personne et/ ou en portant atteinte à l'image ou
        à la réputation d’autrui,
      </li>
      <li>
        de falsifier des données, messages ou documents transmis à la société
        H&L
      </li>
      <li>de transmettre de fausses informations à la société H&L</li>
      <li>
        de se livrer à une violation des droits de propriété intellectuelle
        (notamment en matière de musique, vidéo, animations, jeux, logiciels,
        bases de données, images, sons et textes) ou tout autre droit de
        propriété appartenant à autrui et notamment à la société H&L
      </li>
      <li>
        de télécharger sciemment, afficher, émettre, diffuser, transmettre ou
        rendre accessible de toute autre manière tout contenu comprenant ou
        constituant des virus informatiques ou tout autre code ou programme
        informatique conçu pour interrompre, détruire, détourner ou limiter les
        fonctionnalités ou les performances de la Plateforme
      </li>
      <li>
        de porter atteinte de quelque manière que ce soit à l’image et à la
        réputation de la société H&L et de sa Plateforme
      </li>
      <li>
        d’apporter des propos infondés, mensongers et/ou dénigrants sur un
        intervenant ayant effectué une Prestation lors de sa Notation
      </li>
      <li>
        de manipuler d’une quelconque manière le système de Notations (en
        demandant par exemple à un tiers d’écrire un commentaire positif ou
        négatif sur un autre intervenant).
      </li>
    </ul>

    En cas d’utilisation illégale ou non autorisée de la Plateforme, la société
    H&L se réserve le droit d’exercer toute action appropriée, et notamment de
    signaler l’infraction aux autorités judiciaires et/ou policières. La société
    H&L ne peut en aucun être tenue responsable des agissements des Clients.

    <h3>8. NOTATION DU CLIENT</h3>

    une fois la Prestation réalisée, le Client peut effectuer une Notation
    relative à la qualité des Prestations réalisées par l’intervenant. Les
    Notations seront diffusées sur le profil public de l’intervenant. Le Client
    s’engage à ce que les Notations soient loyales, fondées et appropriées.
    Elles contiendront des éléments objectifs tels que le résultat de la
    Prestation et/ou le respect des délais impartis par l’intervenant. Les
    Notations du Client doivent être fidèles et ne doivent en aucun contenir des
    termes injurieux et/ou diffamatoires.

    <h3>9. AGE</h3>

    Le Client ne peut en aucun cas être mineur. Toute demande provenant d’un
    mineur sera immédiatement rejetée par la société H&L. Il sera rappelé que le
    Client ne peut aucunement apporter des fausses informations afférentes à son
    âge aux fins de contourner cette interdiction.

    <h3>10. MANDAT</h3>

    Dans l’hypothèse où le Client souhaiterait etre assité pour la gestion de
    son Compte et de ses Prestations, il peut mandater une tierce personne, le
    mandataire, aux fins que ce dernier agisse sur la Plateforme en son nom et
    pour son compte. Le Mandat est accessible à l’adresse suivante, et à
    retourner dument complété et signé par le Client et le mandataire :
    L’utilisation de la Plateforme par un tiers sans conclusion d’un mandat est
    inopposable à la société H&L. En tout état de cause, le Client se porte fort
    du respect, par son mandataire, des présentes Conditions d’Utilisation.

    <h3>11. DONNEES PERSONNELLES DES INTERVENANTS</h3>

    Dans le cadre de la réalisation des Prestations, le Client s’interdit de
    tenter d’obtenir toute autre Données Personnelles des intervenants que
    celles communiquées par la société H&L via la Plateforme.

    <h3>12. NON RENONCIATION</h3>

    Le fait que l’une des Parties n’ait pas exigé l’application d’une clause
    quelconque des présentes, que ce soit de façon permanente ou temporaire, ne
    pourra en aucun cas être considéré comme une renonciation aux droits de
    cette Partie découlant de ladite clause dont l’inapplication a été tolérée.

    <h3>13. STIPULATIONS</h3>

    Si une (ou plusieurs) stipulation(s) des Conditions d’Utilisation est (sont)
    tenue(s) pour non valide(s) ou déclarée(s) comme telle(s) en application
    d'une loi, d'un règlement ou à la suite d'une décision définitive d'une
    juridiction compétente, elle(s) sera (ont) réputée(s) non écrite(s) ; les
    autres stipulations garderont toute leur force et portée.

    <h3>14. DROIT APPLICABLE</h3>

    Les Conditions d’Utilisations sont régies par la loi française. Toutes
    difficultés relatives à l'application ou à l'interprétation des Conditions
    d’Utilisation liant les Parties seront soumises, à défaut de conciliation,
    et dans la mesure où cela est autorisé par la loi, aux tribunaux compétents
    de Vienne.
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal2.dismiss()"
    >
      Fermer
    </button>
  </div>
</ng-template>
