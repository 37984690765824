<div class="container-menu">
  <ul class="list-menu">
    <li class="item-menu" [class]="activeRoute == '/' ? 'active' : ''">
      <a routerLink="">Accueil</a>
    </li>
    <li
      class="item-menu"
      [class]="activeRoute == '/aide-domicile' ? 'active' : ''"
    >
      <a
        routerLink="aide-domicile"
        (click)="goToDemandeDevis('formulaireDevis')"
        >Trouver une aide à domicile</a
      >
    </li>
    <li
      class="item-menu"
      [class]="activeRoute == '/recrutement' ? 'active' : ''"
    >
      <a routerLink="recrutement">Trouver un job</a>
    </li>
    <li
      class="item-menu"
      [class]="activeRoute == '/aides-et-financements' ? 'active' : ''"
    >
      <a routerLink="aides-et-financements">Aides et financement</a>
    </li>
    <!-- <li class="item-menu"><a href="#">Comment ça marche</a></li> -->
    <li
      class="item-menu"
      [class]="activeRoute == '/qui-sommes-nous' ? 'active' : ''"
    >
      <a routerLink="qui-sommes-nous">Qui sommes-nous ?</a>
    </li>

    <!-- <li class="item-menu"><a href="#">Télécharger l'app</a></li> -->
  </ul>
</div>
