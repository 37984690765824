<script src="https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.8.1/html2pdf.bundle.min.js"></script>
<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.1.0/jquery.min.js"></script>
<div class="container-presentation-triptyque">
  <div class="item-triptyque">
    <h3>Faire face à une longue maladie</h3>
    <div class="content-triptyque">
      <p>
        Vous êtes atteint d'une maladie longue durée et vous éprouvez des
        difficultés à assurer les tâches ménagères ?
      </p>
      <p>Vous pouvez bénéficier d'une aide financière !</p>
    </div>
    <div>
      <button type="button" class="btn btn-primary" (click)="open(triptyque1)">
        Lire la suite
      </button>
    </div>
  </div>

  <div class="item-triptyque">
    <h3>Les allocations pour personnes âgées</h3>
    <div class="content-triptyque">
      <p>
        (Allocation Personnalisée d’Autonomie) : être âgé de 60 ans ou plus –
        résider de façon stable et régulière en France – être en situation de
        perte d’autonomie nécessitant une aide pour les actions fondamentales de
        la vie.
      </p>
    </div>
    <div>
      <button type="button" class="btn btn-primary" (click)="open(triptyque2)">
        Lire la suite
      </button>
    </div>
  </div>

  <div class="item-triptyque">
    <h3>Wanteez, entreprise agréée</h3>
    <div class="content-triptyque">
      <p>
        Wanteez possède tous les agréments pour garantir un service
        d'excellence, sur l'ensemble des prestations à domicile.
      </p>
    </div>
    <div>
      <button type="button" class="btn btn-primary" (click)="open(triptyque3)">
        Lire la suite
      </button>
    </div>
  </div>
</div>
<!--PDF Tester
<div >
  <button id="displayContratFile" type="button" class="btn btn-primary" (click)="displayPdf()">
    PDF
  </button>
</div>
  <div id="contrat">
    <app-contrat></app-contrat>
  </div>
-->
<ng-template #triptyque1 let-modal1>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Faire face à une longue maladie
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal1.dismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h3>Garde malade à domicile</h3>
    <p>
      Vous êtes malade et vous recherchez une personne de confiance pour prendre
      soin de vous ? Vous souhaitez trouver une solution de garde à domicile
      pour une personne malade dans votre entourage ? Wanteez se charge de tout
      !<br />
      Nos gardes malades à domicile sont formés pour faire le ménage, la
      toilette, les courses et la préparation de repas tout en assurant le
      relais entre le patient et ses proches.
    </p>

    <h4>TROUVEZ VOTRE INTERVENANT EN 2 CLICS SEULEMENT !</h4>

    <p>
      <strong
        >INSCRIVEZ-VOUS SUR WANTEEZ <br />Votre garde malade vous attend
        !</strong
      >
    </p>

    <h4>L’aide à domicile pour les personnes malades</h4>
    <p>
      Le <strong>maintien à domicile</strong> est un sujet important pour toute
      personne atteinte d’une maladie grave ou d’un handicap. Lorsque la
      décision est prise de maintenir le malade à domicile. Ce domicile est
      aménagé pour limiter les efforts et éviter tout risque de chute. Le garde
      à domicile vient ensuite quotidiennement s’occuper de la personne malade.
    </p>

    <p>
      L’<strong>aide aux personnes malades</strong> est chargé d’effectuer les
      soins à domicile. Il ou elle aide le patient à faire sa toilette au lever
      et au coucher. Il lui prépare ses repas et s’occupe de l’entretien de la
      maison.
    </p>

    <h4>Qui est concerné par l’aide aux personnes malades ?</h4>
    <ul>
      <li>Personnes accidentées,</li>
      <li>Personnes opérées,</li>
      <li>Personnes sous traitements lourds,</li>
      <li>Personnes en convalescence,</li>
      <li>Personnes alitées (ex : AVC)</li>
      <li>Personnes atteintes de maladies physiques dégénératrices,</li>
      <li>
        Personnes atteintes de maladies mentales lourdes : Alzheimer, Parkinson,
        démence, etc.
      </li>
    </ul>

    <h4>Quels sont les métiers de l’aide aux malades ?</h4>
    <h5>L’aide-ménagère pour personne malade</h5>
    <p>
      L’<strong>aide-ménagère pour personne malade</strong> s’occupe de
      l’entretien de la maison : ménage, repassage, vaisselle, etc. Très
      méticuleuse, elle veille à faciliter le quotidien du malade. Son
      organisation permet au malade de conserver ses habitudes et son confort de
      vie.
    </p>

    <h5>L’auxiliaire de vie</h5>
    <p>
      Spécialisé dans la garde de personnes malades, l'<strong
        >auxiliaire de vie</strong
      >
      est le professionnel qui intervient obligatoirement pour des pathologies
      spécifiques : Alzheimer, parkinson, etc. L’<strong
        >auxiliaire de vie</strong
      >
      s’occupe à la fois du ménage et remplit le rôle de l’aide à domicile.
    </p>
    <p>
      Sa polyvalence permet à la personne malade d’avoir le même interlocuteur
      tout au long de la journée. Empathique, l’auxiliaire de vie est
      bienveillant et toujours à l’écoute du malade. Son organisation aide le
      malade à connaître les heures de lever, des repas, des siestes, etc.
    </p>

    <h5>Le garde malade</h5>
    <p>
      Le garde malade a un rôle d'accompagnant de la personne malade. Il veille
      sur elle et s’assure de la bonne évolution de la maladie. Il l’aide
      également à se déplacer dans la maison, à aller aux toilettes, etc. Il
      peut intervenir la journée mais aussi la nuit.
    </p>

    <h4>Les missions des aides aux personnes malades</h4>

    <h5>L’aide-ménagère est principalement en charge de :</h5>
    <ul>
      <li>Nettoyer la maison,</li>
      <li>Faire la vaisselle, la lessive, etc.</li>
      <li>Aérer la maison,</li>
      <li>Ranger les affaires de la personne malade où elle le souhaite,</li>
    </ul>

    <h5>Le garde malade est principalement en charge de :</h5>
    <ul>
      <li>Surveiller et sécuriser la personne malade,</li>
      <li>Se déplacer dans la maison,</li>
      <li>Apporter un soutien moral et physique,</li>
      <li>Signaler toutes situations préoccupantes au médecin,</li>
      <li>
        Maintenir un lien permanent avec les autres intervenants et l'équipe
        médicale,
      </li>
    </ul>

    <h5>L’auxiliaire de vie est principalement en charge de :</h5>
    <ul>
      <li>Faire les courses,</li>
      <li>Aider au lever et au coucher,</li>
      <li>Aider à la toilette, aux changes,</li>
      <li>À s’habiller et se déshabiller,</li>
      <li>Aider à se déplacer,</li>
      <li>Aider à la prise de repas...</li>
    </ul>

    <h4>Avec Wanteez, vous bénéficierez :</h4>
    <ul>
      <li>D’un intervenant proche de chez vous,</li>
      <li>
        D’un suivi à distance de la prestation avec les options de
        géolocalisation,
      </li>
      <li>D’un remplacement immédiat de l’intervenant en cas d'absence,</li>
      <li>Des formalités d’embauches simplifiées.</li>
    </ul>

    <h4>Le saviez-vous ?</h4>

    <p>
      Plusieurs possibilités de financement sont possibles lorsque vous faites
      appel à des
      <strong
        >aides financières pour le maintien à domicile des personnes
        malades</strong
      >.
      <!-- Découvrez toutes les aides financières que vous pouvez bénéficier sur Wanteez. -->
    </p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal1.dismiss()"
    >
      Fermer
    </button>
  </div>
</ng-template>

<ng-template #triptyque2 let-modal2>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Les allocations pour personnes âgées
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal2.dismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h3>Conditions</h3>
    <h4>Condition d'âge</h4>
    <p>Vous devez être âgé d'au moins 60 ans.</p>
    <h4>À noter :</h4>
    <p>
      La personne qui perçoit la prestation complémentaire pour recours à tierce
      personne (PCRTP) peut déposer sa demande d'Apa 2 mois avant son 60e
      anniversaire.
    </p>
    <h4>Condition de perte d'autonomie</h4>
    <p>
      Vous devez être dans une situation de perte d'autonomie (besoin d'aide
      pour accomplir les actes de la vie courante, ou état de santé nécessitant
      une surveillance constante).
    </p>
    <p>
      La perte d'autonomie se mesure à l'aide de la grille Aggir. Cette grille
      définit plusieurs degrés de perte d'autonomie, allant du Gir 1 (perte
      d'autonomie la plus forte) au Gir 6 (perte d'autonomie la plus faible).
    </p>
    <p>
      Seules les personnes relevant des Gir 1, Gir 2, Gir 3 ou Gir 4 peuvent
      percevoir l'Apa.
    </p>

    <h4>Condition de résidence</h4>
    <p>Vous devez résider :</p>
    <ul>
      <li>soit à votre domicile ;</li>
      <li>soit au domicile d'un proche qui vous héberge ;</li>
      <li>soit chez un accueillant familial ;</li>
      <li>
        soit dans une résidence autonomie (anciennement appelé
        "foyer-logement").
      </li>
    </ul>
    <p>Vous devez habiter en France de manière stable et régulière.</p>
    <h4>Revenus non cumulables avec l'Apa</h4>
    <p>
      L'allocation personnalisée d'autonomie (Apa) n'est pas cumulable avec
      certains revenus d'aide aux personnes en perte d'autonomie :
    </p>
    <ul>
      <li>
        l'allocation simple versée dans le cadre de l'aide ménagère à domicile,
      </li>
      <li>l'aide en nature versée sous forme d'aide-ménagère à domicile,</li>
      <li>la prestation de compensation du handicap (PCH),</li>
      <li>la majoration pour aide constante d'une tierce personne,</li>
      <li>
        la prestation complémentaire pour recours à tierce personne (PCRTP).
        Toutefois, la personne percevant déjà la PCRTP peut déposer un dossier
        de demande d'Apa afin de pouvoir ensuite choisir entre ces 2 allocations
        celle qui lui convient le mieux.
      </li>
    </ul>

    <h4>Aides fiscales</h4>
    <p>
      Réduction d’impôts de 50% pour l’emploi d’une aide à domicile, dans la
      limite de 1500 Euros pour les plus de 65 ans et de 20 000 Euros pour les
      titulaires de la carte d’invalidité.
    </p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal2.dismiss()"
    >
      Fermer
    </button>
  </div>
</ng-template>

<ng-template #triptyque3 let-modal3>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Wanteez, entreprise agréée
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal3.dismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div
      class="content-entreprise-agreee"
      style="display: flex; justify-content: space-evenly; text-align: justify"
    >
      <div>
        <h3>Un service de qualité à l’échelle nationale</h3>
        <p>
          Avec son implantation dans près de 1000 villes, la société H & L étend
          son savoir faire sur toute la France.
        </p>

        <h4>Une qualité reconnue par les professionnels…</h4>
        <p>
          H & L possède tout les agréments nécessaires pour assurer les
          interventions à votre domicile qu’il s’agisse de garde d’enfants,
          d’entretien du domicile ou d’aide aux personnes âgées.<br />
          – <strong>L’agrément simple</strong> qui est un gage de sérieux pour
          l’ensemble des prestations assurées. <br />–
          <strong>L’agrément qualité</strong> qui est signe d’exigence pour les
          services rendus aux personnes âgées et aux enfants de moins de 3 ans.
        </p>

        <h4>…et les particuliers</h4>
        <p>
          Nous intervenons sur toute la France, vous proposant ainsi des
          interventions de qualité reconnues par les aidés :<br />
          Notre objectif est de vous proposer des services adaptés à vos
          besoins, et de grande qualité. C’est grâce à l‘implication de nos
          intervenantes et aux témoignages que vous apportez que nous
          <strong>améliorons jour après jour nos services</strong>.
        </p>
      </div>

      <div style="margin-left: 20px; text-align: center">
        <img src="assets/presentation-tryptique/logosap-200x150.png" />
        <br />
        N° d’agrément :<br />
        R/170811/F/038/Q096
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal3.dismiss()"
    >
      Fermer
    </button>
  </div>
</ng-template>

