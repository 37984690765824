<script src="https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.8.1/html2pdf.bundle.min.js"></script>
<div
  class="container-contrat"
  [class]="formulaireContratVisible ? 'formulaire-ouvert' : ''"
  id="formulaireContrat"
>
  <div
    class="div-formulaire"
    id="formulaireContrat"
    style="
      background-image: url(../../../assets/contrat-header/specimen.jpg);
      background-repeat: round;
    "
  >
    <div class="d-flex flex-column justify-content-center">
      <img
        class="image-header-wanteez"
        src="../../../assets/contrat-header/wanteez-contrat.png"
        alt="Wanteez-contrat"
      />
      <br />
    </div>
    <div class="d-flex flex-column justify-content-center">
      <article>
        <div class="d-flex flex-column justify-content-center">
          <section style="text-align: center">
            <h5 class="colorPrimary">CONTRAT DE TRAVAIL A DURÉE INDÉTERMINÉE A TEMPS PARTIEL</h5>
            <br />
            <p>ENTRE</p>

            <h3 class="colorPrimary">La SAS H & L - Wanteez</h3>

            <p>ET</p>

            <h3 class="colorPrimary">{{ candidat.civilite_label }} {{ candidat.nom }}</h3>
          </section>
        </div>
      </article>
      <article>
        <div>
          <section><br /><br />
            <p style="font-weight: bold;">ENTRE LES SOUSSIGNEES :</p><br>

            <p>
              La SAS H & L sous la marque Wanteez au capital de 100 500 €, dont
              le siège social est situé à l’Espace Saint Germain, Jazz Parc,
              Bâtiment Miles, 30 avenue Général Leclerc, 38200 Vienne,
              immatriculée au Registre du Commerce et des Sociétés de Vienne
              sous le n° 477934343RCS de Vienne, représentée par JAPAO, agissant
              en sa qualité de Présidente, ayant tous pouvoirs à cet effet,
              Ci‐après désignée la « la Société H&L,
            </p><br>

            <p>D'une part,</p>

            <p>Et :</p>

            <p>
              <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong>
              demeurant au :
            </p>

            <p>
              <strong>{{ candidat.full_adresse }}, France</strong>.
            </p><br>

            <p>D'autre part,</p>

            <p>
              Il est préalablement rappelé que l’employeur de
              <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong>
              est la Société H&L
            </p>

            <p>IL A ETE CONVENU CE QUI SUIT :</p>
          </section>
        </div>
      </article>
      <article>
        <div>
          <h2>ARTICLE 1-DECLARATIONS</h2>
          <br />
          <p>
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong>
            declare :
          </p>
          <ul>
            <li>
              être né(e) le
              <strong>{{ candidat.birthday }} à {{ candidat.ville }}</strong
              >,
            </li>
            <li>
              être de nationalité <strong>{{ nationality }}</strong
              >;
            </li>
            <li>
              être immatriculée à la Sécurité Sociale sous le numéro
              <strong>{{ candidat.num_secu_soc }}</strong>
            </li>
            <li>
              être titulaire du titre valant autorisation de travail portant le
              numéro ;
            </li>
            <li>
              être titulaire d’un permis de conduire en cours de validité ou
              pouvoir se déplacer en transport en commun;
            </li>
            <li>
              n'être liée par aucune clause de non‐concurrence, tant au titre de
              contrats de travail antérieurs qu'à tout autre titre ;
            </li>
            <li>
              ne pas être en infraction aux dispositions légales relatives au
              cumul d’emplois (Article L8261‐1 du Code du travail).
            </li>
          </ul>

          <p>
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong>
            s’engage expressément à communiquer à la Société par écrit et sous 7
            jours la liste de ses autres employeurs et les durées de travail
            affectées à chacun.
          </p>
          <p>
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong>
            s’engage à communiquer sous 7 jours tout certificat médical délivré
            par la médecine du travail dans les 6 mois précédents son
            embauche.La Société en remettra une copie au Médecin du travail afin
            d’établir la nécessité de réaliser une nouvelle visite médicale.
          </p>
          <p>
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong>
            s'engage à faire connaître sans délai à la direction de la Société
            tout changement quelconque qui interviendrait dans les situations
            qu'elle a déclarées au présent article ou dans tout autre document
            administratif de la Société.En cas de fausse déclaration, le/la
            salariée a connaissance qu'elle s’expose à une mesure pouvant
            aboutir à une fin de contrat.
          </p>
        </div>
      </article>
      <article>
        <div>
          <h2>ARTICLE 2 ‐ CONDITIONS GENERALES</h2>
          <p>
            Le présent contrat de travail est conclu aux conditions générales de
            la Convention Collective Nationale des Entreprises de Services à la
            personne, dans ses dispositions étendues actuellement en vigueur,
            dont
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong>
            déclare avoir pris connaissance.
          </p>
          <p>
            L’employeur de
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong>
            est la Société H&L et non les clients bénéficiaires du service.
          </p>
        </div>
      </article>
      <article>
        <div>
          <h2>
            ARTICLE 3 – ENGAGEMENT – OBJET DU CONTRAT A DUREE INDETERMINEE
          </h2>
          <p>
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong>
            est engagée au sein de la Société H&L à compter du
            <strong>{{ dateDay }}</strong> en qualité de
            <strong>{{ candidat.poste_label }}</strong> dans le cadre d’un
            contrat de travail à durée indéterminée.
          </p>
          <p>
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong>
            bénéficiera d’une visite d’information et de prévention auprès des
            services de la médecine du travail, dans les 3 mois suivant la prise
            effective du poste de travail.
          </p>
        </div>
      </article>
      <article>
        <div>
          <h2>ARTICLE 4 – DUREE DU CONTRAT ‐ PERIODE D'ESSAI</h2>
          <p>Le présent contrat est conclu pour une durée indéterminée.</p>
          <p>
            L'embauche définitive de
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong>
            sera précédée d'une période d'essai de 2 mois, qui pourra être
            renouvelée pour une période de 2 mois d’un commun accord des
            parties.
          </p>
          <p>
            Au cours de cette période, le contrat pourra être rompu par l'une ou
            de l'autre partie, à tout moment, sous réserve du respect du délai
            de prévenance prévu aux articles L 1221‐25 ou L1221‐26 du Code du
            travail.
          </p>
        </div>
      </article>
      <article>
        <div>
          <h2>ARTICLE 5 – EMPLOI – QUALIFICATION</h2>
          <p>
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong>
            exercera la fonction de <strong>{{ candidat.poste_label }}</strong
            >, cet emploi relève du niveau de la classification de la Convention
            Collective susvisée.
          </p>
          <p>
            La présente qualification n'implique aucune restriction au contenu
            des missions de
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong>
            décrites dans la fiche de fonction annexée au contrat, dès lors que
            la nature de ces missions reste généralement compatible avec le type
            d'emploi fixé ci‐dessus.
          </p>
          <p>
            Au titre de sa fonction,
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong>
            prend l’engagement formel, dans le respect des directives qui
            peuvent lui être données par sa hiérarchie, d’accomplir, à titre
            principal, les missions et attributions définies à l’annexe du
            présent contrat de travail.
          </p>
          <p>
            En outre, sur simple directive de la Direction ou de toute personne
            qu’elle délègue à cet effet,
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong>
            peut se voir confier, temporairement ou non, l’accomplissement de
            toutes autres missions ou tâches compatibles avec la qualification
            ci‐dessus
          </p>
        </div>
      </article>
      <article>
        <div>
          <h2>ARTICLE 6 – LIEU DE TRAVAIL</h2>
          <br />
          <p>
            Les fonctions de
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong>
            sont rattachées administrativement au siège social de la Société,
            situé 30 Avenue Général Leclerc, Espace Saint germain, Immeuble
            MILES – 38200 VIENNE.
          </p>
          <p>
            Compte tenu de la nature des fonctions exercées,
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong>
            sera en permanence amenée à effectuer des déplacements
            professionnels au sein du domicile des clients, des assisteurs
            situés sur le secteur géographique suivant :
            <strong>{{ candidat.codep }}{{ candidat.lieu_travail }}</strong
            >, France et 20 kilomètres aux alentours, ce qu’il/elle accepte
            expressément.
          </p>
        </div>
      </article>
      <article>
        <div>
          <h2>ARTICLE 7 – DUREE DU TRAVAIL</h2>
          <p>
            <strong>Option 1 contrainte(s) personnelle(s):</strong> En raison des
            caractéristiques spécifiques des missions pouvant être effectuées et
            des contraintes personnelles de {{ candidat.civilite_label }}
            <strong>{{ candidat.nom }}</strong> exprimées dans son courrier du
            <strong>{{ dateDay }}</strong> , il est prévu que
            <strong>{{ candidat.civilite_label }}</strong>
            <strong> {{ candidat.nom }}</strong> travaille sur la base d’une
            durée mensuelle moyenne de travail égale à 2 heures Il est donc
            convenu entre les parties que cette durée du travail dérogatoire
            permet à
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong> de
            faire face à ses contraintes personnelles. Il est précisé que la
            durée mensuelle du travail de
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong>
            pourra être augmentée à sa demande et après formalisation d’un
            avenant.
          </p>
          <p>
            <strong>Option 2 cumul activité:</strong> En raison des caractéristiques spécifiques
            des missions pouvant être effectuées et du souhait exprimé de
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong>
            dans son courrier du <strong>{{ dateDay }}</strong
            >, de pouvoir cumuler plusieurs activités, il est prévu que
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong>
            travaille sur la base d’une durée mensuelle moyenne de travail égale
            à 2 heures. Il est donc convenu entre les parties que cette durée du
            travail dérogatoire permet à
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong> de
            cumuler plusieurs activités afin d'atteindre une durée globale
            d'activité correspondant à un temps plein ou au moins égale à 24
            heures hebdomadaires. Il est précisé que la durée mensuelle du
            travail de
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong>
            pourra être augmentée à sa demande et après formalisation d’un
            avenant.
          </p>
          <p>
            <strong>Option 3 Etudiant de moins de 26 ans :</strong> En raison des
            caractéristiques spécifiques des missions pouvant être effectuées et
            des études poursuivies par
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong>
            âgée de moins de 26 ans, il est prévu que
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong>
            travaille sur la base d’une durée mensuelle moyenne de travail égale
            à 2 heures. Il est donc convenu entre les parties que cette durée du
            travail dérogatoire permet à
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong> de
            suivre ses études. Il est précisé que la durée mensuelle du travail
            de
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong>
            pourra être augmentée à sa demande et après formalisation d’un
            avenant.
          </p>
          <p>Je sélectionne :</p><br><br>
        </div>
      </article>
      <article>
        <div>
          <h2>ARTICLE 8 – HORAIRES DE TRAVAIL</h2>
          <p>
            Les horaires de travail de
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong>
            lui seront communiqués chaque mois par le moyen d’un planning mis à
            sa disposition sur l’extranet, dont une notice d’utilisation et les
            codes d’accès personnels sont annexés au présent contrat.
          </p>
          <p>
            Les plages prévisionnelles indicatives de travail sont actuellement
            les suivantes : 9H à 12 heures et 13h10 à 16h10. Les plages
            restantes constituant celles d’indisponibilités prévisionnelles
            indicatives.
          </p>
          <p>
            Les jours habituels de repos hebdomadaire de
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong>
            seront en principe le samedi et le dimanche.
          </p>
          <p>
            Il est expressément convenu qu’en raison des particularités et des
            contraintes inhérentes aux missions d’aide à domicile, le planning
            mensuel et/ou, le jour de repos de
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong>
            pourra être modifié, sous réserve d’un délai de prévenance qui ne
            peut être inférieur à 7 jours ouvrés dans les cas actuellement prévu
            par la convention collective à savoir à titre informatif :
          </p>
          <ul>
            <li>absence non programmée d'une collègue de travail,</li>
            <li>aggravation de l'état de santé du bénéficiaire du service,</li>
            <li>décès du bénéficiaire du service,</li>
            <li>
              hospitalisation ou urgence médicale d'un bénéficiaire de service
              entraînant son absence,
            </li>
            <li>
              arrivée en urgence non programmée d'un bénéficiaire de service,
            </li>
            <li>maladie de l'enfant,</li>
            <li>maladie de l'intervenant habituel,</li>
            <li>
              carence du mode de garde habituel ou des services assurant
              habituellement cette garde,
            </li>
            <li>
              absence non prévue d'un salarié intervenant auprès d'un public âgé
              ou dépendant,
            </li>
            <li>
              besoin immédiat d'intervention auprès d'enfant dû à l'absence non
              prévisible de son parent.
            </li>
          </ul>
          <p>
            Ces modifications interviendront par le moyen d’ordres de missions
            mis à la disposition de
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong>
            sur l’extranet, ce que l’intéressée accepte sans réserve.
          </p>
          <p>
            Il est donc impératif de prendre connaissance de l’extranet
            quotidiennement.
          </p>
          <p>
            Il demeure entendu entre les parties, qu’en cas d’accord de
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong> ,
            son planning pourra être modifié dans un délai plus court et ce afin
            de permettre à la Société de pouvoir répondre aux contraintes
            inhérentes à son activité.
          </p>
        </div>
      </article>
      <article>
        <div>
          <h2>ARTICLE 9 – DECOMPTE DU TEMPS DE TRAVAIL</h2>
          <p>
            Le décompte du temps de travail se fera par le système de
            télégestion mis en place par la Société. Pour ce faire,
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong>
            utilisera exclusivement ce système mis en place par la Société, dont
            une notice explicative est annexée au contrat et mise à sa
            disposition sur l’Extranet.
          </p>
          <p>
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong>
            déclare savoir que le défaut d’utilisation du télépointage par
            télégestion constitue une faute pouvant donner lieu à une sanction
            pouvant aller jusqu’au licenciement.
          </p>
        </div>
      </article>
      <article>
        <div>
          <h2>ARTICLE 10 – HEURES COMPLEMENTAIRES</h2>
          <p>
            En fonction des besoins de la Société,
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong>
            pourra être conduite à effectuer des heures complémentaires dans la
            limite du dixième de sa durée mensuelle contractuelle de travail.
          </p>
          <p>
            Les heures complémentaires effectuées dans la limite du dixième de
            la durée contractuelle seront majorées de 10%.
          </p>
          <p>
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong>
            s'engage à effectuer ces heures complémentaires dans le cadre ainsi
            défini.
          </p>
          <br />
          <p>
            En tout état de cause, les heures complémentaires effectuées ne
            pourront avoir pour effet de porter la durée hebdomadaire de travail
            à un niveau égal ou supérieur à celui de la durée légale du travail.
          </p>
          <br />
        </div>
      </article>
      <article>
        <div>
          <h2>ARTICLE 11‐ REMUNERATION</h2>
          <p>
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong> bénéficiera d’une
            rémunération horaire brute de 12€ brut de l’heure.
          </p>
          <p>
            Les salaires sont payés par virement bancaire à la date de 15 de
            chaque mois.
          </p>
        </div>
      </article>
      <article>
        <div>
          <h2>ARTICLE 12 – ABSENCE</h2>
          <br />
          <p>
            Si l’absence est imprévisible, et notamment si elle résulte de la
            maladie ou d’un accident, il appartiendra à
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong> d’informer ou de
            faire informer immédiatement la Société et de fournir dans les 48
            heures, justification de l’absence, notamment par l’envoi d’un avis
            d’arrêt de travail et des avis de prolongation éventuelle.
          </p>
          <p>
            S’il s’agit d’une absence prévisible, elle devra être préalablement
            notifiée et motivée à la Direction afin d'obtenir son accord exprès
            et préalable.
          </p>
          <p>
            Dès que la date de reprise effective est connue de
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong>, celle‐ci s’engage
            à prévenir la Société afin que puisse être organisée, le cas
            échéant, la visite obligatoire de reprise devant le Médecin du
            Travail, dans les conditions prévues à l'article R.4624‐21 du Code
            du Travail et à solliciter l’établissement de son planning auprès de
            la Direction par le moyen de la plateforme et de l’espace
            intervenant.
          </p>
          <p>
            Compte tenu des contraintes liées à la gestion des remplacements, de
            l’activité de l’entreprise et des besoins spécifiques de la
            clientèle, les présentes obligations constituent des éléments
            essentiels du contrat de travail.
          </p>
        </div>
      </article>
      <article>
        <div>
          <h2>ARTICLE 13 – CONGES PAYES</h2>
          <br />
          <p>
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }} </strong>aura droit aux
            congés payés prévus par les articles L.3141‐1 et suivants du Code du
            Travail et par la Convention Collective.
          </p>
          <br />
          <p>
            Les dates seront fixées en accord avec la Direction, en fonction des
            impératifs d’organisation. <strong>{{ candidat.civilite_label }}
            {{ candidat.nom }} </strong>devra impérativement communiquer ses dates de
            congés souhaitées au moins deux mois avant, exclusivement par le
            moyen du formulaire disponible sur l’extranet.
          </p>
          <br />
        </div>
      </article>
      <article>
        <div>
          <h2>ARTICLE 14 – PROTECTION SOCIALE</h2>
          <p>
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }} bénéficiera des
            régimes de Retraite, de prévoyance et le cas échéant frais de santé
            applicables au sein de la Société, à savoir actuellement :</strong>
          </p>
          <ul>
            <li>
              Régime de retraite : APICIL Gestion Entreprise Retraite Compte –
              38 Rue François Peissel BP 118 69645 Caluire et Cuire cedex
            </li>
            <li>
              Régime de prévoyance : IRCEM 261 avenue des nations unies 59672
              ROUBAIX Cedex 1
            </li>
            <li>
              Régime de frais de santé APICIL Gestion Entreprise Santé – 38 Rue
              François Peissel BP 118 69645 Caluire et Cuire cedex
            </li>
            <li>
              Les quotes‐parts salariales de cotisations à ces régimes, qu'il
              s'agisse de ceux actuellement en vigueur ou de ceux pouvant être
              ultérieurement mis en place, seront précomptés sur les
              rémunérations de <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong> qui ne saurait s'y
              soustraire.
            </li>
          </ul>
        </div>
      </article>
      <article>
        <div>
          <h2>ARTICLE 15 – DEPLACEMENTS PROFESSIONNELS</h2>
          <p>
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong> pourra être amenée
            dans le cadre de ses fonctions à utiliser son véhicule personnel
            et/ou le réseau des transports en commun.
          </p>
          <p>
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong> s’engage à
            s’assurer pendant toute la durée du contrat auprès d’une compagnie
            notoirement solvable. La police d’assurance devra contenir une
            clause garantissant la responsabilité civile de la société, chaque
            fois que celle‐ci pourra être engagée et l’engagement de la
            compagnie de prévenir la Société de toute modification du contrat,
            résiliation ou non‐paiement des primes.
          </p>
        </div>
      </article>
      <article>
        <div>
          <h2>ARTICLE 16 ‐ FRAIS PROFESSIONNELS</h2>
          <p>
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong> devra justifier des
            frais qu'elle aura engagés dans l'exercice de ses fonctions pour le
            compte de la Société.
          </p>
          <p>
            Les frais engagés par le salarié à l’occasion de ses déplacements
            professionnels lui seront remboursés sur présentation des pièces
            justificatives et notes de frais, conformément aux procédures
            internes, dans les limites sociales et fiscales et selon le barème
            en vigueur au sein de la Société.
          </p>
          <p>
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong> sera indemnisée de
            la moitié du titre de transport en commun, sur présentation des
            justificatifs, pour les déplacements professionnels situés à
            l’intérieur d’une zone géographique desservie par les transports
            collectifs.
          </p>
          <p>
            Ces modalités pourront, dans le temps, être modifiées sans que cela
            constitue une modification du présent contrat.
          </p>
          <p>
            La Direction se réserve la possibilité d'authentifier et de
            contrôler les justificatifs, et d’apprécier l’opportunité du
            remboursement de certains frais.
          </p>
          <br />
        </div>
      </article>
      <article>
        <div>
          <h2>ARTICLE 17 – FIDELITE‐ LOYAUTE</h2>
          <p>
            Compte tenu de la nature particulière du secteur des services à la
            personne et des métiers exercés et de la détermination des parties
            signataires de la présente convention collective à lutter contre le
            travail illégal et le travail dissimulé sous toutes ses formes, le
            contrat de travail peut prévoir une clause par laquelle le salarié
            s'interdit tout acte contraire aux intérêts de son employeur,
            pendant toute la durée de son contrat de travail. Seront considérés
            comme des manquements à ce principe, notamment, le fait de créer une
            entreprise concurrente, ainsi que celui d'entrer au service des
            clients actifs de son employeur pour son propre compte ou pour le
            compte d'un tiers, étant précisé qu'un client est qualifié d'actif
            lorsqu'il est en contrat avec l'employeur.
          </p>
          <p>
            Indépendamment de la formalisation par une clause spécifique du
            contrat, le salarié est en tout état de cause soumis à un principe
            de loyauté. Toutefois, ce principe de loyauté n'a pas pour effet
            d'empêcher le salarié embauché à temps partiel de cumuler plusieurs
            emplois. Le contrat de travail à temps partiel rappelle que le
            salarié à temps partiel n'est pas tenu à une obligation
            d'exclusivité.
          </p>
        </div>
      </article>
      <article>
        <div>
          <h2>ARTICLE 18 – CONFIDENTIALITE – DISCRETION</h2>
          <p>
            Compte tenu de l'exécution de la prestation de travail le plus
            souvent au domicile du bénéficiaire et de la proximité de la
            relation entre le bénéficiaire et le salarié résultant de la nature
            particulière des services à la personne, le salarié s'engage à
            observer, de la façon la plus stricte, la discrétion la plus absolue
            sur l'ensemble des faits et informations dont il pourrait avoir
            connaissance à l'occasion de ses fonctions ou du fait, notamment, de
            sa présence au domicile du bénéficiaire, vis‐à‐ vis des tiers et des
            salariés de l'entreprise. Cette obligation de discrétion perdure
            après la fin de son contrat.
          </p>
          <p>
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong> s’engage à
            respecter la discrétion la plus absolue sur l’ensemble des
            informations ou renseignements donnés à titre confidentiel dans le
            cadre de ses fonctions, ou dont la diffusion serait susceptible de
            porter atteinte aux intérêts légitimes de la Société, ou de ses
            clients. Cet engagement vaut tant pendant la durée du présent
            contrat qu’auprès sa rupture éventuelle.
          </p>
        </div>
      </article>
      <article>
        <div>
          <h2>ARTICLE 19 : TRAITEMENT DES DONNES NOMINATIVES</h2>
          <p>
            Afin d’assurer, dans les meilleures conditions, la gestion du
            dossier personnel de <strong>{{ candidat.civilite_label }}
            {{ candidat.nom }}</strong>, le traitement de sa rémunération (notamment
            salaire, congés payés, absences diverses) et, plus généralement,
            afin de suivre son activité professionnelle, la Société est
            contrainte de collecter, traiter, communiquer et conserver des
            données nominatives la concernant, ce qu’il (elle) reconnaît et
            accepte expressément.
          </p>
          <p>
            Ces données ne seront traitées et utilisées que dans la mesure de ce
            qui est nécessaire à l'exécution du contrat de travail, à
            l'accomplissement par la société des obligations qui lui incombent
            et dans la limite des délais de prescription applicables en matière
            sociale.
          </p>
          <p>
            Le service des Ressources Humaines ainsi que les partenaires RH
            (prestataire paye, organismes de formation, organismes de sécurité
            sociale, frais de santé, de prévoyance et de retraite notamment) et
            le service finance ont seuls accès à ces données. Le service
            Informatique a accès à ces données de manière limitée à
            l’administration informatique. Les administrations sociales et
            fiscales peuvent également y avoir accès.
          </p>
          <p>
            Dans cette optique <strong>{{ candidat.civilite_label }}
            {{ candidat.nom }}</strong> s’engage à communiquer à la Société l’ensemble
            des données personnelles le /la concernant qui lui sont demandées et
            qui sont nécessaires à l’exécution de son contrat de travail et pour
            la gestion des salariés.
          </p>
          <p>
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong> s’engage également
            à communiquer toutes modifications intervenant dans sa situation
            personnelle (changement d’adresse, invalidité, situation
            matrimoniale...).
          </p>
          <p>
            Enfin, <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong> accepte que
            lesdites données soient recueillies et traitées par la Société et
            bénéficie d’un droit d’accès, de rectification ou d’effacement de
            ses données personnelles, ou de limitation du traitement de ses
            données ou d’opposition à ce traitement ainsi qu’un droit à la
            portabilité des données la concernant.
          </p>
          <p>
            La personne responsable de ces traitements au sein de la Société est
            M.Haro Frederic en sa qualité de Directeur.
          </p>
          <p>
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong> est informée qu’il
            (elle) a la possibilité d’introduire une réclamation auprès de la
            Commission Nationale de l’Informatique et des libertés (CNIL).
          </p>
        </div>
      </article>
      <article>
        <div>
          <h2>ARTICLE 20 – PASSAGE A TEMPS COMPLET</h2>
          <p>
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong> bénéficiera, si il
            (elle) le souhaite, d’une priorité pour l’attribution d’un emploi à
            temps complet relevant de sa catégorie professionnelle ou d’un
            emploi équivalent qui serait créé ou qui deviendrait vacant.
          </p>
        </div>
      </article>
      <article>
        <div>
          <h2>ARTICLE 21 – EGALITE DE TRAITEMENT</h2>
          <p>
            <strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong> bénéficiera de tous
            les droits et avantages reconnus aux salariés à temps complet,
            conformément aux dispositions légales et conventionnelles.
          </p>
          <p>
            Par ailleurs, la Société garantit à <strong>{{ candidat.civilite_label }}
            {{ candidat.nom }}</strong> un traitement équivalent aux salariés à temps
            complet de même ancienneté et de qualification équivalentes,
            notamment en matière de promotion, de déroulement de carrière et
            d’accès à la formation professionnelle.
          </p>
        </div>
      </article>
      <article>
        <div>
          <h2>ARTICLE 22 – RUPTURE DU CONTRAT</h2>
          <p>
            A l’issue de la période d’essai, chacune des parties signataires
            pourra mettre fin au présent contrat, sous réserve de respecter les
            dispositions légales et conventionnelles en vigueur et notamment le
            respect d’un préavis.
          </p>
          <p>
            Cependant, cette période de préavis ne s’applique pas en cas de
            licenciement pour faute grave ou faute lourde, ou en cas de rupture
            conventionnelle et sous réserve des dispositions particulières
            relatives à la retraite.
          </p>
        </div>
      </article>
      <section>
        <br /><br />
        <p>
          Ce contrat est établi en 2 exemplaires originaux et comporte 10 pages
          paraphées et signées.
        </p>
        <p>Fait à Vienne, le <strong>{{ dateDay }}</strong></p>
        <p>Pour la société H&L-Wanteez</p>
        <p>JAPAO, La Présidente<br />signature :</p><br>
        <p><strong>{{ candidat.civilite_label }} {{ candidat.nom }}<br />signature :</strong></p>
      </section>
      <section>
        <div
          class="row"
          style="
            margin-top: 70px;
            border-top: black 3px double;
            padding-top: 50px;
          "
        >
          <div class="col-md-4 d-flex flex-column">
            <strong
              >{{ candidat.civilite_label }} {{ candidat.nom }}<br />
              {{ candidat.adresse }}<br />{{ candidat.codep }}
              {{ candidat.ville }}</strong
            >
          </div>
          <div class="col-md-3"></div>
          <div class="col-md-5 d-flex flex-column">
            Société H&L<br /><br />Espace Saint Germain, Jazz Parc,<br />Bâtiment
            Miles,<br />30 avenue Général Leclerc,<br />38200 Vienne<br /><br />Le
            {{ dateDay }}
          </div>
        </div>
        <p><strong>{{ candidat.civilite_label }} {{ candidat.nom }},</strong></p>
        <label>Option 1 : contraintes personnelles</label>
        <p>
          En raison de contraintes personnelles liées à ﴾ma vie familiale/mon
          handicap/mes études﴿, je souhaiterais pouvoir travailler en deçà de la
          durée minimale légale applicable aux salariés travaillant à temps
          partiel.
        </p>
        <label>Option 2 : cumul d’activité</label>
        <p>
          Souhaitant pouvoir cumuler plusieurs activités, je souhaiterais
          pouvoir travailler en deçà de la durée minimale légale applicable aux
          salariés travaillant à temps partiel.
        </p>
        <p>
          Je vous remercie par avance de bien vouloir tenir compte de cette
          demande et d’y donner une issue favorable. Veuillez agréer, Madame,
          Monsieur, mes salutations distinguées.
        </p>
        <br />
        <p>Je sélectionne :</p>
        <br /><br /><br />
        <p><strong>{{ candidat.civilite_label }} {{ candidat.nom }}</strong></p>
        <p>Signature:</p>
        <br /><br /><br />
      </section>
      <section>
        <h2 style="display: flex; justify-content: center;">ANNEXE 1- Fiche de Fonction – Aide à domicile</h2><br />
        <p><strong>MISSIONS PRINCIPALES</strong></p>
        <br /><br />
        <p><strong>Assistante de vie niveau 1</strong></p>
        <ul>
          <li>
            Accompagner une personne dans la réalisation des tâches quotidiennes
          </li>
          <li>
            Nettoyer, entretenir, désinfecter les espaces et les sols
            (intérieurs et extérieurs), les sanitaires, les meubles, équipements
            et appareils ménagers.
          </li>
          <li>Changer le linge de lit.</li>
          <li>Utiliser le lave‐linge, étendre le linge.</li>
          <li>Repasser, plier et ranger le linge courant.</li>
          <li>Effectuer les courses</li>
          <li>Préparer des repas simples</li>
          <li>
            Effectuer les courses pour le compte de la personne et préparer des
            préparations culinaires simples ou spécifiques selon le régime
            alimentaire suivi par la personne et l'accompagner dans la prise de
            son rep
          </li>
        </ul>
        <p><strong>Garde d’enfant niveau 1</strong></p>
        <ul>
          <li>
            Assurer une présence auprès de l'enfant ou des enfants sur une
            courte durée et veiller à leur sécurité en permanence
          </li>
          <li>
            Alimenter l'enfant ou les enfants à partir de préparations réalisées
            par les parents ou par soi‐même, dans le respect des instructions
            laissées par les parents
          </li>
          <li>
            Rendre compte, de façon orale ou écrite, sur la manière dont s'est
            déroulée la garde Surveiller le ou les enfants pendant la
            réalisation de leurs devoirs
          </li>
          <li>
            Surveiller le ou les enfants pendant la réalisation de leurs devoir
          </li>
        </ul>
        <br />
        <p class="d-flex justify-content-end"><strong>LA Présidente, JAPAO,</strong></p><br>
      </section>
      <section>
        <h2 style="display: flex; justify-content: center;">ANNEXE 2- CHARTE D’ENGAGEMENT QUALITE</h2><br />
        <p>
          L’objectif de cette charte d’engagement est de garantir un
          professionnalisme, une discrétion et une qualité de service optimale
          aux clients de la société H& L Prestations à domicile.
        </p>
        <p>
          <strong>
          En tant qu’intervenant(e) de la société H & L Prestations à domicile,
          vous êtes responsable de la qualité de vos interventions, et donc de
          l’image que vous donnez de votre société.
          </strong></p>
        <p class="colorPrimary">A ce titre vous vous engagez à :</p>
        <h6 class="articleAnnexe d-flex justify-content-center">Article 1:</h6>
          <p>
            <strong>Favoriser le bien– être à domicile</strong> des clients en apportant une
            réponse sur mesure à leurs besoins et attentes.
          </p>
        <h6 class="articleAnnexe d-flex justify-content-center">Article 2:</h6>
          <p>
            Ne jamais communiquer d’informations internes et confidentielles sur
            vos conditions de travail et respecter <strong>la confidentialité</strong> des
            informations fournies par les clients.
          </p>
        <h6 class="articleAnnexe d-flex justify-content-center">Article 3:</h6>
          <p>
            Intervenir dans la plus grande <strong>discrétion</strong>, en prenant toutes les
            <strong>précautions de sécurité</strong> nécessaires.
          </p>
        <h6 class="articleAnnexe d-flex justify-content-center">Article 4:</h6>
          <p>
            Ne pas se servir <strong>pendant le temps de</strong> travail de son téléphone portable
            (pas d’envoi de SMS ou de lecture de mail).
          </p>
        <h6 class="articleAnnexe d-flex justify-content-center">Article 5:</h6>
          <p>
            <strong>Respecter l’intimité, les croyances, les opinions et les habitudes</strong> de
            nos clients (dans la mesure où la sécurité et le bien–être ne sont pas
            en jeu).
          </p>
        <h6 class="articleAnnexe d-flex justify-content-center">Article 6:</h6>
          <p>
            <strong>Informer la société</strong> de tout fait inhabituel ou d’information
            importante pouvant remettre en cause les conditions d’interventions.
          </p>
        <h6 class="articleAnnexe d-flex justify-content-center">Article 7:</h6>
          <p>
            <strong>Intervenir seul</strong> dans des conditions optimales de présentation
            physiques et morales afin de respecter l’image haut de gamme de la
            société.
          </p>
        <h6 class="articleAnnexe d-flex justify-content-center">Article 8:</h6>
          <p>
            <strong>Tenir à jour</strong> la note de frais professionnels mise à disposition s’il y
            a enchaînement de 2 interventions sans pause d’une heure et demie.
          </p>
        <h6 class="articleAnnexe d-flex justify-content-center">Article 9:</h6>
          <p>
            A la demande de certains clients (pour les gardes d’enfants et les
            auxiliaires de vie), <strong>émarger</strong> un document récapitulatif des prestations
            afin de fournir un suivi précis des heures d’interventions.
          </p>
        <h6 class="articleAnnexe d-flex justify-content-center">Article 10:</h6>
          <p>
            <strong>Déclarer auprès de la société H & L Prestations à domicile toutes les
            heures effectuées</strong> en procédant <strong>correctement</strong> à la télégestion en
            arrivant et repartant de chez chaque client.
          </p>
        <h6 class="articleAnnexe d-flex justify-content-center">Article 11:</h6>
          <p>
            <strong>Ne jamais recevoir</strong> de nos clients de délégation de pouvoir
            sur les avoirs, les biens, toute donation, dépôts de fonds, de bijoux ou autres valeurs
          </p>
        <h6 class="articleAnnexe d-flex justify-content-center">Article 12:</h6>
        <p>
          <strong>Ne jamais accepter</strong> de règlement sous quelque forme que ce soit, y
          compris le règlement de la facture de la société H & L Prestations à
          domicile en direct.
        </p>
        <h6 class="articleAnnexe d-flex justify-content-center">Article 13:</h6>
        <p>
          <strong>Remettre le jour même tous les justificatifs</strong> des dépenses effectuées
          pour le compte des clients.
        </p>
        <h6 class="articleAnnexe d-flex justify-content-center">Article 14:</h6>
        <p>
          <strong>Ne jamais devenir salarié du client</strong> ou intervenir pour le compte
          professionnel de celui‐ci.
        </p>
        <p>
          L’intervenant(e) confirme avoir pris connaissance et respecter les
          informations contenues dans la Charte d’engagement de la société H & L
          Prestations à domicile lors de chaque intervention.
        </p>
        <p>
          <strong>
          Date et signature de l’intervenant(e) précédés de la mention « lu et
          approuvés ».
          </strong>
        </p>
      </section>
    </div>
  </div>
</div>
