import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-presentation-postes-a-pourvoir',
  templateUrl: './presentation-postes-a-pourvoir.component.html',
  styleUrls: ['./presentation-postes-a-pourvoir.component.scss']
})
export class PresentationPostesAPourvoirComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
