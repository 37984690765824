<!-- Container for sidebar(s) + page content -->
<ng-sidebar-container contentClass="sidebar">
  <!-- A sidebar -->
  <ng-sidebar
    #sidebar
    [(opened)]="_opened"
    [closeOnClickOutside]="true"
    [showBackdrop]="true"
    [sidebarClass]="'sidebar-menu'"
  >
    <img src="./assets/Logo_Wanteez.png" height="60" alt="Logo Wanteez" />
    <app-header-menu [activeRoute]="activeRoute"></app-header-menu>
  </ng-sidebar>

  <!-- Page content -->
  <div ng-sidebar-content>
    <app-header-title></app-header-title>
    <!-- <app-header-menu [activeRoute]="activeRoute"></app-header-menu> -->

    <ng-container *ngIf="activeRoute == '/'">
      <app-header-presentation></app-header-presentation>

      <app-presentation-services-tuiles></app-presentation-services-tuiles>
      <!-- <app-header-recherche-emploi [villeCandidat]="villeCandidat"></app-header-recherche-emploi> -->
      <!-- <app-header-recherche-emploi></app-header-recherche-emploi> -->
      <div
        class="row mr-0 ml-0"
        style="border-top: solid 1px #c84f94; border-bottom: solid 1px #c84f94"
      >
        <div class="col-md-6 col-sm-12 col-xs-12 pl-0 pr-0">
          <app-presentation-video1></app-presentation-video1>
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12 bg-light pl-0 pr-0">
          <app-presentation-video2></app-presentation-video2>
        </div>
      </div>
      <app-presentation-services></app-presentation-services>
      <app-presentation-je-postule></app-presentation-je-postule>
      <app-presentation-postes-a-pourvoir></app-presentation-postes-a-pourvoir>
      <!-- <app-inscription-formulaire></app-inscription-formulaire> -->
      <app-presentation-temoignages></app-presentation-temoignages>
      <app-presentation-triptyque></app-presentation-triptyque>
    </ng-container>

    <ng-container *ngIf="activeRoute == '/aide-domicile'">
      <!-- <app-presentation-services-tuiles></app-presentation-services-tuiles> -->
      <app-header-search-offres></app-header-search-offres>
    </ng-container>

    <ng-container *ngIf="activeRoute == '/qui-sommes-nous'">
      <app-qui-sommes-nous></app-qui-sommes-nous>
    </ng-container>

    <ng-container *ngIf="activeRoute == '/aides-et-financements'">
      <!-- <app-page-en-construction></app-page-en-construction> -->
      <app-aides-et-financements></app-aides-et-financements>
    </ng-container>

    <ng-container *ngIf="activeRoute == '/recrutement'">
      <app-inscription-formulaire
      [villeCandidat] = villeCandidat (villeChange) = "changeVille($event)"
      [codepCandidat] = codepCandidat (codepChange) = "changeCodep($event)"
      [(latitudeCandidat)] = latitudeCandidat (latitudeChange) = "changeLatitude($event)"
      [longitudeCandidat] = longitudeCandidat (longitudeChange) = "changeLongitude($event)"
      ></app-inscription-formulaire>
      <app-presentation-je-postule></app-presentation-je-postule>
      <app-presentation-postes-a-pourvoir></app-presentation-postes-a-pourvoir>
    </ng-container>


    <ng-container *ngIf="activeRoute == '/jobAround'">
      <app-header-recherche-emploi
        [villeCandidat]="villeCandidat"
        [latitudeCandidat]="latitudeCandidat"
        [longitudeCandidat]="longitudeCandidat"
        [codepCandidat]="codepCandidat"
        ></app-header-recherche-emploi>
      <app-presentation-je-postule></app-presentation-je-postule>
      <app-presentation-postes-a-pourvoir></app-presentation-postes-a-pourvoir>
    </ng-container>

    <app-footer></app-footer>
  </div>
</ng-sidebar-container>

<button
  *ngIf="windowScrolled"
  onclick="topFunction()"
  id="btnToTop"
  title="Haut de page"
  (click)="scrollToTop()"
>
  <i class="fas fa-chevron-up"></i>
</button>
