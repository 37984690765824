import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private formulaireInscriptionVisible = new BehaviorSubject(false);
  formulaireInscriptionVisible$ =
    this.formulaireInscriptionVisible.asObservable();

  private formulaireJobAroundVisible = new BehaviorSubject(false);
  formulaireJobAroundVisible$ =
    this.formulaireJobAroundVisible.asObservable();

  private formulaireContratVisible = new BehaviorSubject(false);
  formulaireContratVisible$ = this.formulaireContratVisible.asObservable();

  private sidebarVisible = new BehaviorSubject(false);
  sidebarVisible$ = this.sidebarVisible.asObservable();

  constructor() {}

  changeStateFormulaireInscription(isVisible) {
    this.formulaireInscriptionVisible.next(isVisible);
  }

  changeStateJobAround(isVisible) {
    this.formulaireJobAroundVisible.next(isVisible);
  }

  changeStateContrat(isVisible) {
    this.formulaireContratVisible.next(isVisible);
  }

  changeStateSidebar(isVisible) {
    this.sidebarVisible.next(isVisible);
  }
}
