import { ViewportScroller } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service.ts.service';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent implements OnInit {
  @Input() activeRoute: string;

  constructor(
    private viewportScroller: ViewportScroller,
    private data: DataService
  ) {}

  ngOnInit(): void {}

  public goToDemandeDevis(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }

  public showFormulaireInscription(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
    this.data.changeStateFormulaireInscription(true);
  }
}
