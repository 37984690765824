<div class="container-qui-sommes-nous">
  <p><img src="./assets/Logo_Wanteez.png" height="120" alt="Logo Wanteez" /></p>

  <p>
    Wanteez, c'est la nouvelle offre de services <strong>digitale</strong> de la
    société H&L. Acteur de premier plan
    <strong>des Services à la personne et de la Silver-Economie</strong> en
    France, H&L intervient partout en France et depuis 2004, pour simplifier le
    quotidien de tous.
  </p>

  <p>
    <strong
      >Aides à domicile , accompagnement des personnes dépendantes, gardes
      d’enfant, entretien de la maison, auxiliaires de vie, chauffeurs,
      cuisiniers, jardiniers…</strong
    >
    Quelque soit votre besoin, quel que soit votre lieu de résidence, il y a
    sûrement une solution Wanteez pour vous. Wanteez, c’est une vision
    entièrement repensée des <strong>Services à la personne</strong> : sans
    agences physiques, totalement digitalisé, entièrement pilotée en ligne et
    via <strong>notre plateforme et nos applications</strong>, les services
    Wanteez vous garantissent toujours plus de réactivité, de qualité, de
    fiabilité et de proximité.
  </p>

  <p>
    Fort du réseau de partenaires tissé par H&L depuis maintenant 15 ans,
    Wanteez peut aujourd’hui s’appuyer sur des acteurs de renom : les mutuelles,
    les assureurs, les départements, les CCAS, les HAD… L’objectif est de se
    rapprocher au plus près de la demande.
  </p>

  <p>
    Agréée par plusieurs organismes, notre crédibilité et notre légitimité sont
    plus à faire. A votre écoute et dans l’anticipation de vos attentes, nos
    équipes s'attachent à répondre toujours mieux à vos attentes, creusant ainsi
    l’écart avec nos concurrents.
  </p>
  <p>
    Pour les familles, les célibataires, les personnes âgées, les enfants… Nous
    disposons de tous les agréments simples, qualités* et les autorisations,
    pour vous garantir des prestations de services professionnelles et de haut
    niveau.
  </p>

  <p>Wanteez ? Chez vous, comptez sur nous !</p>

  <p><em>*exclusivement sur certains département (nous consulter)</em></p>
</div>
