import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as html2pdf from 'html2pdf.js';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
@Component({
  selector: 'app-presentation-triptyque',
  templateUrl: './presentation-triptyque.component.html',
  styleUrls: ['./presentation-triptyque.component.scss'],
})
export class PresentationTriptyqueComponent implements OnInit {
  constructor(private modalService: NgbModal) {}

  pdfAccept = false;

  ngOnInit(): void {}

  open(content) {
    this.modalService.open(content, { size: 'xl' });
  }


  /* A préparer pour le pdf*/
  displayPdf(){

    const element = document.getElementById('contrat');

    html2pdf(element, {
      margin: [2, 2],
      filename: 'contrat.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2, letterRendering: true},
      //pagebreak: {mode: '', before: '.break-before', after: '.break-after', avoid: '.break-avoid' },
      jsPDF: { unit: 'pt', format: 'letter', orientation: 'portrait' }
    });


  }

}
