<div class="container-services-tuiles bg-light">
  <h2 class="mt-4 text-primary">Nos services</h2>
  <div class="content-services-tuiles mb-4">
    <div
      class="item-service"
      *ngFor="let item of listServices"
      routerLink="aide-domicile"
      [style.background-image]="
        'url(./assets/presentation-services/' + item.img + ')'
      "
    >
      <div class="item-service-title">
        {{ item.title }}
      </div>
    </div>
  </div>
</div>
