import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderTitleComponent } from './header/header-title/header-title.component';
import { HeaderMenuComponent } from './header/header-menu/header-menu.component';
import { FooterComponent } from './footer/footer/footer.component';
import { PresentationServicesComponent } from './presentation/presentation-services/presentation-services.component';
import { HeaderPresentationComponent } from './header/header-presentation/header-presentation.component';
import { HeaderSearchOffresComponent } from './header/header-search-offres/header-search-offres.component';
import { HeaderRechercheEmploiComponent } from './header/header-recherche-emploi/header-recherche-emploi.component';
import { PresentationJePostuleComponent } from './presentation/presentation-je-postule/presentation-je-postule.component';
import { PresentationPostesAPourvoirComponent } from './presentation/presentation-postes-a-pourvoir/presentation-postes-a-pourvoir.component';
import { PresentationVideo1Component } from './presentation/presentation-video1/presentation-video1.component';
import { PresentationVideo2Component } from './presentation/presentation-video2/presentation-video2.component';
import { PresentationTemoignagesComponent } from './presentation/presentation-temoignages/presentation-temoignages.component';
import { PresentationTriptyqueComponent } from './presentation/presentation-triptyque/presentation-triptyque.component';
import { InscriptionFormulaireComponent } from './inscription/inscription-formulaire/inscription-formulaire.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  CUSTOM_ERROR_MESSAGES,
  NgBootstrapFormValidationModule,
} from 'ng-bootstrap-form-validation';
import { CUSTOM_ERRORS } from './custom-errors';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from './interceptors/httperror.interceptor';
import { ContratComponent } from './contrat/contrat/contrat.component';
import {
  NgcCookieConsentConfig,
  NgcCookieConsentModule,
} from 'ngx-cookieconsent';
import { environment } from '../environments/environment';
import { TranslateModule } from '@ngx-translate/core';
import { QuiSommesNousComponent } from './qui-sommes-nous/qui-sommes-nous/qui-sommes-nous.component';
import { PageEnConstructionComponent } from './page-en-construction/page-en-construction/page-en-construction.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AidesEtFinancementsComponent } from './aides-et-financements/aides-et-financements/aides-et-financements.component';
import { JobArroundComponent } from './job-arround/job-arround.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { SidebarModule } from 'ng-sidebar';
import { PresentationServicesTuilesComponent } from './presentation/presentation-services-tuiles/presentation-services-tuiles.component';
const routes: Routes = [
  { path: 'aide-domicile', component: AppComponent },
  { path: 'qui-sommes-nous', component: AppComponent },
  { path: 'aides-et-financements', component: AppComponent },
  { path: 'recrutement', component: AppComponent },
  { path: 'jobAround', component: AppComponent },
  { path: '', component: AppComponent },
];
console.log(window.location.hostname);
const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: window.location.hostname,
  },
  position: 'bottom-left',
  theme: 'edgeless',
  palette: {
    popup: {
      background: '#000000',
      text: '#ffffff',
      link: '#ffffff',
    },
    button: {
      background: '#c84f94',
      text: '#ffffff',
      border: 'transparent',
    },
  },
  type: 'info',
  content: {
    message:
      'Ce site web utilise des cookies pour vous assurer la meilleure expérience de navigation sur notre site.',
    dismiss: "OK, j'ai compris!",
    deny: 'Refuser',
    link: null,
    href: '#',
    policy: 'Cookie Policy',
    header: 'Cookies sur le site!',
    allow: 'Autoriser les cookies',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderTitleComponent,
    HeaderMenuComponent,
    HeaderPresentationComponent,
    FooterComponent,
    PresentationServicesComponent,
    HeaderSearchOffresComponent,
    HeaderRechercheEmploiComponent,
    PresentationJePostuleComponent,
    PresentationPostesAPourvoirComponent,
    PresentationVideo1Component,
    PresentationVideo2Component,
    PresentationTemoignagesComponent,
    PresentationTriptyqueComponent,
    InscriptionFormulaireComponent,
    ContratComponent,
    QuiSommesNousComponent,
    PageEnConstructionComponent,
    AidesEtFinancementsComponent,
    JobArroundComponent,
    PresentationServicesTuilesComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    RouterModule.forRoot(routes, { anchorScrolling: 'enabled' }),
    FormsModule,
    ReactiveFormsModule,
    NgBootstrapFormValidationModule.forRoot(),
    NgBootstrapFormValidationModule,
    GooglePlaceModule,
    HttpClientModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    TranslateModule.forRoot(),
    BrowserAnimationsModule,
    GoogleMapsModule,
    SidebarModule.forRoot(),
  ],
  providers: [
    {
      provide: CUSTOM_ERROR_MESSAGES,
      useValue: CUSTOM_ERRORS,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
