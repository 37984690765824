<div class="header-presentation-container">
  <!-- <div class="text-before-img">
    Avant, votre maman se demandait tous les matins si son Aide à domicile
    serait là, ou pas...
  </div> -->
  <!-- <div class="image-container">
    <img
      src="./assets/header-presentation/GrandMereSousCloche.jpg"
      alt="Grand mère"
    />
  </div> -->
  <!-- <div class="text-after-img">
    Maintenant, elle sait que Michelle n'est plus qu'à quelques pas.
  </div> -->

  <div class="header-presentation-content">
    <div class="container-title text-center mb-5">
      <h1 class="text-primary" [@fadeInOnEnter]>
        Wanteez révolutionne les services à la personne
      </h1>
    </div>
    <!-- <div class="row-presentation-header mt-5"> -->
    <div class="row mt-5 container-title-details mr-0 ml-0">
      <div class="col-sm-12 col-md-4 pl-0 pr-0" style="display: flex">
        <div class="div-presentation-header bg-primary text-light">
          <p>Trouver les services dont vous avez besoin</p>
          <button
            class="btn btn-light text-primary mt-2"
            routerLink="aide-domicile"
          >
            Je trouve mon service
          </button>
          <div class="div-contactez-nous mt-4">
            <strong><span class="contactez-nous">Contactez-nous</span></strong>
            <br />
            <span class="num-tel">04 74 11 05 32</span>
            <br />
            <small
              ><span class="prix-appel-local"
                ><em>Prix appel local non surtaxé</em></span
              ></small
            >
          </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-4 pl-0 pr-0">
        <img
          src="./assets/header-presentation/GrandMereSousCloche.jpg"
          alt="Grand mère"
          height="200"
        />
        <p class="mt-3 pl-4 pr-4" [@bounceInLeftOnEnter]>
          <em
            >Avant, votre maman se demandait tous les matins si son Aide à
            domicile serait là, ou pas...</em
          >
        </p>
        <p class="pl-4 pr-4" [@bounceInRightOnEnter]>
          <em
            >Maintenant, elle sait que Michelle n'est plus qu'à quelques
            pas.</em
          >
        </p>
      </div>
      <div class="col-sm-12 col-md-4 pl-0 pr-0" style="display: flex">
        <div class="div-presentation-header">
          <p class="text-primary">
            Trouver un CDI dans les services à domicile
          </p>
          <button class="btn btn-primary mt-2" routerLink="recrutement">
            Je candidate
          </button>
          <div class="div-contactez-nous mt-4 text-primary">
            <strong><span class="contactez-nous">Contactez-nous</span></strong>
            <br />
            <span class="num-tel">04 74 11 05 32</span>
            <br />
            <small
              ><span class="prix-appel-local"
                ><em>Prix appel local non surtaxé</em></span
              ></small
            >
          </div>
          <button class="btn btn-primary mt-2" routerLink="jobAround">
            Nos offres à 20kms
          </button>
        </div>
      </div>
    </div>

    <!-- <div class="col">
      <ngb-carousel [showNavigationIndicators]="false">
        <ng-template ngbSlide *ngFor="let item of listServices">
          <div
            class="picsum-img-wrapper"
            [style.background-image]="
              'url(./assets/presentation-services/' + item.img + ')'
            "
          ></div> -->
    <!-- <div class="container-service" [style.background-color]="item.color">
            <div class="service-title" [style.color]="item.color">
              <i [class]="item.icon"></i> {{ item.title }}
            </div>
            <div class="service-description">
              {{ item.description }}
            </div>
            <div class="service-btn-postuler">
              <button
                type="button"
                class="btn btn-outline-light"
                routerLink="recrutement"
              >
                Postuler
              </button>
            </div>
          </div> -->
    <!-- </ng-template>
      </ngb-carousel>
    </div> -->
  </div>
</div>
