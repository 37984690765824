<div class="container-inscription-formulaire" id="formulaireTrouverJob">
  <div
    class="recherche-emploi-container"
    style="display: flex; justify-content: center; align-items: center; background-color: #c84f94;"
  >

    <br />
    <form
      [formGroup]="form"
      (ngSubmit)="codepSend()"
      style="margin-bottom: 1%;
      margin-top: 4%;
      width: 50%;
      min-width: 360px;"
    >
      <div
        class="form-group"
        style="
          display: flex;
          justify-content: center;
          flex-direction: column;

        "
      >
          <div class="input-group mb-3">
                <input
                type="text"
                class="form-control"
                placeholder="Code Postal"
                formControlName="codep"
                id="codep"
                style="margin-top: -3%; border: 3px solid #c84f95; font-size: 27px; border-radius: 15px;"
                />
              <div class="input-group-append">

          </div>
        </div>
      </div>
      <button
      class="btn mt-2"
      style="background-color: white; color: #c84f94;"
      routerLink="jobAround"
      type="submit">
        Rechercher
      </button>
    </form>
    <div style="width: 100%; margin-bottom: 2%">
      <ng-container *ngIf="jobArround">
        <app-job-arround
        [codep]="codep"
        [ville]="ville"
        [latitude]="latitude"
        [longitude]="longitude"
        > </app-job-arround>
      </ng-container>
    </div>
  </div>
</div>
