import { ErrorMessage } from 'ng-bootstrap-form-validation';

export const CUSTOM_ERRORS: ErrorMessage[] = [
  {
    error: 'required',
    format: requiredFormat,
  },
  {
    error: 'minLength(10)',
    format: minLengthFormat,
  },
  {
    error: 'email',
    format: emailFormat,
  },
  {
    error: 'dirty',
    format: invalidFormat,
  },
  {
    error: 'invalid',
    format: invalidFormat,
  },
];

export function requiredFormat(label: string, error: any): string {
  return `Le champs ${label} est obligatoire`;
}

export function invalidFormat(label: string, error: any): string {
  return `Le champs ${label} n'est pas valide`;
}

export function minLengthFormat(label: string, error: any): string {
  return `Le champs ${label} doit avoir 10 caractères`;
}

export function emailFormat(label: string, error: any): string {
  return `${label} n'est pas une adresse email valide`;
}
