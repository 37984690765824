<div class="container-aides-et-financements">
  <h2>Aides & Financement</h2>

  <h3>Les prestations concernées par le service sont :</h3>

  <p>
    <strong
      >L’entretien du logement et du linge, l’aide au lever, l’aide à la
      toilette, l’aide aux courses, la préparation et/ou prise des repas, l’aide
      au coucher. Elles sont réalisées sous le contrôle des autorités
      départementales par une habilitation à intervenir auprès des populations
      dites fragiles : familles en difficulté, enfants de moins de 3 ans,
      personnes en situation d’exclusion, personnes handicapées, personnes âgées
      en perte d’autonomie.</strong
    >
  </p>

  <p>
    <strong
      >Les services d’aide agissent en tant qu’acteur médico-social et selon le
      cadre réglementaire du conseil départemental, l’un d’entre eux prévoyant
      une tarification encadrée. Aussi, le conseil départemental a la
      possibilité de vérifier la qualité des prestations rendues.</strong
    >
  </p>

  <p>
    Notons également que la loi du 28 décembre 2015 réunit sous un régime unique
    l’autorisation du service d’aide domicile, deux régimes différents ayant
    existé auparavant (conseil départemental et direction régionales des
    entreprises).
  </p>

  <p>
    <strong
      >Il existe par ailleurs deux manières de faire appel à un service d’aide à
      domicile :</strong
    >
  </p>

  <p>
    La première consiste à
    <strong>faire appel à un service dit prestataire</strong>. Il s’agit d’être
    accompagnée par des auxiliaires de vie salariés d’un service d’aide à
    domicile, ce qui oblige le bénéficiaire à régler sa prestation à l’organisme
    en charge du service.
  </p>

  <p>
    La seconde manière est
    <strong>le recours à un service dit mandataire</strong>, où le bénéficiaire
    est lui-même l’employeur de la personne aidante. Dans ce cas, l’action du
    service d’aide à domicile se limite à la mise en relation avec l’employé, la
    rédaction des documents attenants (contrats de travail etc.) et à la
    réalisation des feuilles de salaire. Bien que le bénéficiaire doit payer ce
    service, il salarie aussi l’intervenant à domicile. Il demeure toutefois
    possible d’éviter le recours au service en employant une personne dans le
    cadre de l’emploi direct.
  </p>

  <p>
    <strong
      >Les coûts de ces différents services étant très variables, il serait trop
      approximatif de donner un ordre de grandeur.</strong
    >
  </p>

  <div class="container-list-items-aides">
    <div class="title-list-item-aide"><h4>Personnes agées</h4></div>

    <div class="container-item-aide">
      <div class="content-item-aide">
        <h5>APA (Allocation Personnalisée d'Autonomie)</h5>
        <p>
          Etre âgé de 60 ans ou plus – résider de façon stable et régulière en
          France – être en situation de perte d’autonomie nécessitant une aide
          pour les actions fondamentales de la vie.
        </p>
      </div>
      <div class="content-btn-plus">
        <button class="btn btn-primary" (click)="apa.toggle()">
          En savoir plus
        </button>
      </div>
    </div>
    <div #apa="ngbCollapse" [(ngbCollapse)]="isCollapsedApa">
      <div class="card">
        <div class="card-body">
          <p><strong>Condition d'âge</strong></p>
          <p>Vous devez être âgé d'au moins 60 ans.</p>
          <p><strong>À noter :</strong></p>
          <p>
            La personne qui perçoit la prestation complémentaire pour recours à
            tierce personne (PCRTP) peut déposer sa demande d'Apa 2 mois avant
            son 60e anniversaire.
          </p>
          <p><strong>Condition de perte d'autonomie</strong></p>
          <p>
            Vous devez être dans une situation de perte d'autonomie (besoin
            d'aide pour accomplir les actes de la vie courante, ou état de santé
            nécessitant une surveillance constante).
          </p>
          <p>
            La perte d'autonomie se mesure à l'aide de la grille Aggir. Cette
            grille définit plusieurs degrés de perte d'autonomie, allant du Gir
            1 (perte d'autonomie la plus forte) au Gir 6 (perte d'autonomie la
            plus faible).
          </p>
          <p>
            Seules les personnes relevant des Gir 1, Gir 2, Gir 3 ou Gir 4
            peuvent percevoir l'Apa.
          </p>
          <p><strong>Condition de résidence</strong></p>
          <p>Vous devez résider :</p>
          <ul>
            <li>soit à votre domicile ;</li>
            <li>soit au domicile d'un proche qui vous héberge ;</li>
            <li>soit chez un accueillant familial ;</li>
            <li>
              soit dans une résidence autonomie (anciennement appelé
              "foyer-logement").
            </li>
          </ul>
          <p>Vous devez habiter en France de manière stable et régulière.</p>
          <p><strong>Revenus non cumulables avec l'Apa</strong></p>
          <p>
            L'allocation personnalisée d'autonomie (Apa) n'est pas cumulable
            avec certains revenus d'aide aux personnes en perte d'autonomie :
          </p>
          <ul>
            <li>
              l'allocation simple versée dans le cadre de l'aide ménagère à
              domicile,
            </li>
            <li>
              l'aide en nature versée sous forme d'aide-ménagère à domicile,
            </li>
            <li>la prestation de compensation du handicap (PCH),</li>
            <li>la majoration pour aide constante d'une tierce personne,</li>
            <li>
              la prestation complémentaire pour recours à tierce personne
              (PCRTP). Toutefois, la personne percevant déjà la PCRTP peut
              déposer un dossier de demande d'Apa afin de pouvoir ensuite
              choisir entre ces 2 allocations celle qui lui convient le mieux.
            </li>
          </ul>
          <p><strong>Aides fiscales</strong></p>
          <p>
            Réduction d’impôts de 50% pour l’emploi d’une aide à domicile, dans
            la limite de 1500 Euros pour les plus de 65 ans et de 20 000 Euros
            pour les titulaires de la carte d’invalidité.
          </p>
        </div>
      </div>
    </div>

    <div class="container-item-aide">
      <div class="content-item-aide">
        <h5>ASPA (Allocation de Solidarité aux Personnes Agées)</h5>
        <p>
          L'ASPA est une allocation destinée aux personnes âgées disposant de
          faibles revenus, en vue de leur assurer un minimum de ressources.
        </p>
      </div>
      <div class="content-btn-plus">
        <button class="btn btn-primary" (click)="aspa.toggle()">
          En savoir plus
        </button>
      </div>
    </div>
    <div #aspa="ngbCollapse" [(ngbCollapse)]="isCollapsedAspa">
      <div class="card">
        <div class="card-body">
          <p><strong>Condition d'âge</strong></p>
          <p>Vous pouvez bénéficier de l'Aspa dès 65 ans</p>

          <p>
            <strong>À noter :</strong>Les sommes versées au titre de l'<strong
              >Aspa</strong
            >
            sont récupérables après le décès du bénéficiaire sur sa succession
            si, et seulement si, l'actif net de la succession excède 39 000
            euros. A noter que le <strong>remboursement</strong> de l'<strong
              >Aspa</strong
            >
            aux héritiers ne peut pas excéder un certain montant.
          </p>

          <p>
            <strong>Conditions de ressources :</strong>L’attribution de l’ASPA
            est conditionnée à un certain niveau de ressources. Celui-ci ne doit
            pas dépasser un plafond fixé le 1er janvier 2019 à :
          </p>
          <ul>
            <li>
              10.418,40 euros par an ou 868,20 euros par mois pour une personne
              vivant seule
            </li>
            <li>
              16.174,59 euros par an ou 1.347,88 euros par mois pour une
              personne vivant en couple (conjoints mariés, partenaires de PACS,
              concubins)
            </li>
          </ul>

          <p><strong>Condition de résidence</strong></p>
          <p>Vous devez résider régulièrement en France.</p>
          <p>
            Vous résidez en France de manière stable si vous vous trouvez dans
            <strong>l'une ou l'autre</strong> de ces situations :
          </p>
          <ul>
            <li>
              Vous avez votre <strong>foyer permanent</strong> en France. Le
              foyer désigne le lieu où vous habitez normalement, c'est-à-dire le
              lieu de votre résidence habituelle, à condition que cette
              résidence en France ait un caractère permanent.
            </li>
            <li>
              Le lieu de votre <strong>séjour principal</strong> se trouve en
              France. C'est le cas si vous séjournez en France pendant plus de 6
              mois (ou 180 jours) l'année concernée.
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="container-item-aide">
      <div class="content-item-aide">
        <h5>AIDE MENAGERE</h5>
        <p>
          Cette aide à domicile s’adresse aux personnes âgées qui ont des
          difficultés pour accomplir les principales tâches du quotidien. Elle
          permet de financer des heures de ménage, entretien du linge, aide à la
          toilette et préparation des repas
        </p>
      </div>
      <div class="content-btn-plus">
        <button class="btn btn-primary" (click)="aideMenagere.toggle()">
          En savoir plus
        </button>
      </div>
    </div>
    <div #aideMenagere="ngbCollapse" [(ngbCollapse)]="isCollapsedAideMenagere">
      <div class="card">
        <div class="card-body">
          <p><strong>Condition d'âge</strong></p>
          <p>
            Pour avoir droit à une aide-ménagère départementale, vous devez être
            âgé d’au moins 65 ans (ou 60 ans en cas d’inaptitude au travail)
          </p>

          <p>
            <strong>À noter : </strong>La durée de présence de la personne qui
            intervient à domicile est fixée par le département. Elle varie en
            fonction des besoins. Elle ne peut pas dépasser 30 heures par mois
          </p>

          <p>L’aide financière est versée, au choix :</p>
          <ul>
            <li>
              Au service d’aide à domicile à la condition qu’il soit habilité à
              l’aide sociale par le conseil départemental
            </li>
            <li>
              À l’assuré lorsqu’il fait appel à un salarié en qualité de
              particulier-employeur
            </li>
          </ul>

          <p>
            <strong>Conditions de ressources : </strong>Ne pas bénéficier de
            l'APA (allocation personnalisée d'autonomie) et ne pas pouvoir en
            bénéficier ; avoir des <strong>ressources</strong> mensuelles
            inférieures à 868,20 € pour une personne seule et à 1 347,88 € pour
            un couple
          </p>

          <p><strong>Condition de perte d'autonomie</strong></p>
          <p>
            Vous devez être dans une situation de perte d'autonomie (besoin
            d'aide pour accomplir les actes de la vie courante, ou état de santé
            nécessitant une surveillance constante).
          </p>
          <p>
            La perte d'autonomie se mesure à l'aide de la grille Aggir. Cette
            grille définit plusieurs degrés de perte d'autonomie, allant du Gir
            1 (perte d'autonomie la plus forte) au Gir 6 (perte d'autonomie la
            plus faible).
          </p>
          <p>
            Seules les personnes relevant des Gir 5 et Gir 6 peuvent percevoir
            l'Aide-ménagère.
          </p>
          <p><strong>Condition de résidence</strong></p>
          <p>Vous devez résider régulièrement en France.</p>
          <p>
            Vous résidez en France de manière stable si vous vous trouvez dans
            l'une ou l'autre de ces situations :
          </p>
          <ul>
            <li>
              Vous avez votre <strong>foyer permanent</strong> en France. Le
              foyer désigne le lieu où vous habitez normalement, c'est-à-dire le
              lieu de votre résidence habituelle, à condition que cette
              résidence en France ait un caractère permanent.
            </li>
            <li>
              Le lieu de votre <strong>séjour principal</strong> se trouve en
              France. C'est le cas si vous séjournez en France pendant plus de 6
              mois (ou 180 jours) l'année concernée.
            </li>
          </ul>
          <p><strong>Revenus non cumulables avec l'Aide-ménagère</strong></p>
          <p>
            L’aide-ménagère versée par le département n’est pas cumulable avec
            les aides à domicile prévues pour les bénéficiaires de l’allocation
            personnalisée d’autonomie (APA) à domicile
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="container-list-items-aides">
    <div class="title-list-item-aide"><h4>Personnes handicapées</h4></div>

    <div class="container-item-aide">
      <div class="content-item-aide">
        <h5>PCH (Prestation de Compensation de Handicap)</h5>
        <p>
          L’aide PCH (Prestation de Compensation de Handicap) est une aide
          personnalisée destinée à financer les besoins liés à la perte
          d’autonomie des personnes handicapées adultes
        </p>
      </div>
      <div class="content-btn-plus">
        <button class="btn btn-primary" (click)="pch.toggle()">
          En savoir plus
        </button>
      </div>
    </div>
    <div #pch="ngbCollapse" [(ngbCollapse)]="isCollapsedPch">
      <div class="card">
        <div class="card-body">
          <p><strong>Condition d'âge</strong></p>
          <p>
            Pour bénéficier de la PCH (Prestation de compensation de Handicap)
            il faut avoir moins de 75 ans
          </p>
          <p>
            <strong>À noter : </strong>Cette prestation couvre les aides
            humaines et les aides matérielles (aménagements du logement et du
            véhicule)
          </p>

          <p>
            <strong>Conditions de ressources :</strong>
            <strong
              >L'accès à la PCH n'est pas soumis à une condition de
              ressources</strong
            >. Cependant, pour calculer le montant de la PCH dans la limite des
            taux de prise en charge, les ressources de l'année N-1 sont prises
            en compte :
          </p>
          <ul>
            <li>
              Si vos ressources ne dépassent pas 26 926,24 € par an le taux
              maximum de prise en charge de la PCH est de 100 % des montants
              limites par type d'aide.
            </li>
            <li>
              Si vos ressources sont supérieures à 26 926,24 €, le taux maximum
              de prise de charge de la PCH est de 80 %.
            </li>
          </ul>
          <p><strong>Condition de perte d'autonomie</strong></p>
          <p>
            Pour pouvoir bénéficier de la PCH, vous devez trouver dans l'une des
            situations suivantes :
          </p>
          <ul>
            <li>
              Vous rencontrez une difficulté absolue pour la réalisation d'une
              activité (mobilité, entretien personnel, communication, tâches et
              exigences générales et relations avec autrui). La difficulté est
              qualifiée d'absolue si l'activité ne peut pas du tout être
              réalisée par vous-même,
            </li>
            <li>
              Vous avez une difficulté grave pour la réalisation d'au moins 2
              activités (mobilité, entretien personnel, communication, tâches et
              exigences générales et relations avec autrui). La difficulté à
              accomplir ces activités est qualifiée de grave lorsqu'elles sont
              réalisées difficilement et de façon altérée par vous-même
            </li>
          </ul>
          <p><strong>Condition de résidence</strong></p>
          <p>Pour pouvoir bénéficier de la PCH, il faut résider en France.</p>
          <p>
            Que ce soit à domicile ou en établissement social ou médico-social
            ou hospitalisé en établissement de santé.
          </p>
          <p>Si vous êtes étranger, vous devez détenir :</p>
          <ul>
            <li>Une carte de résident</li>
            <li>Ou un titre de séjour valide</li>
          </ul>
          <p><strong>Revenus non cumulables avec la PCH</strong></p>
          <p>
            L’aide PCH (Prestation de Compensation du Handicap) versée n’est pas
            cumulable avec les aides à domicile prévues pour les bénéficiaires
            de l’allocation personnalisée d’autonomie (APA) à domicile
          </p>

          <p>
            Cependant, il est possible de cumuler la PCH (Prestation de
            Compensation du Handicap) avec :
          </p>
          <ul>
            <li>
              Vos revenus professionnels, ceux de la personne avec laquelle vous
              vivez, ceux de votre aidant familial ou de vos parents même
              lorsque vous vivez chez eux,
            </li>
            <li>
              Votre retraite et pension d'invalidité versées par un régime
              obligatoire,
            </li>
            <li>
              Votre allocation de chômage et régime de solidarité, votre
              allocation spécifique de solidarité, votre allocation équivalent
              retraite,
            </li>
            <li>
              Vos indemnités de maladie, de maternité, d'accident du travail et
              de maladie professionnelle ou votre allocation de cessation
              anticipée d'activité en faveur des travailleurs de l'amiante,
            </li>
            <li>Vos prestations familiales,</li>
            <li>Votre allocation aux adultes handicapés (AAH),</li>
            <li>Vos allocations logement,</li>
            <li>Votre prime d'activité,</li>
            <li>Votre prime de déménagement,</li>
            <li>Votre pension attribuée en cas de divorce ou de séparation,</li>
            <li>Votre bourse d'étudiant,</li>
            <li>Vos rentes survie ou épargne handicap.</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="container-item-aide">
      <div class="content-item-aide">
        <h5>AEEH (Allocation d’Education de l’Enfant Handicapé)</h5>
        <p>
          L’aide AEEH (Allocation d’Education de l’Enfant Handicapé) est une
          prestation familiale destinée à soutenir les personnes qui assurent la
          charge d’un enfant en situation de handicap ; Prestation destinée à
          compenser les frais d'éducation et des soins apportés.
        </p>
      </div>
      <div class="content-btn-plus">
        <button class="btn btn-primary" (click)="aeeh.toggle()">
          En savoir plus
        </button>
      </div>
    </div>
    <div #aeeh="ngbCollapse" [(ngbCollapse)]="isCollapsedAeeh">
      <div class="card">
        <div class="card-body">
          <p><strong>Condition d'âge</strong></p>
          <p>
            Pour bénéficier de l’AEEH il faut avoir un enfant à charge en
            situation de handicap âgé de moins de 20 ans
          </p>
          <p>
            <strong>À noter : </strong>Un complément d’allocation peut être
            alloué sous certaines conditions définies par la CDAPH
          </p>
          <p>Les compléments sont accordés en fonction :</p>
          <ul>
            <li>Des dépenses liées au handicap</li>
            <li>
              Et/ou de la réduction ou cessation d'activité professionnelle d'un
              ou des parents,
            </li>
            <li>Ou de l'embauche d'un tiers.</li>
          </ul>

          <p>
            Les compléments de l'AEEH se répartissent en 6 niveaux de handicap,
            et le montant est défini en fonction du niveau du handicap
          </p>

          <p>
            Conditions de ressources : L'accès à la AEEH n'est pas soumis à
            condition de ressources, elle est identique pour tout le monde sauf
            pour le parent qui élève seul son enfant handicapé où l’aide sera
            plus élevée.
          </p>
          <p><strong>Condition de perte d'autonomie</strong></p>
          <p>
            La commission des droits et de l'autonomie des personnes handicapées
            (CDAPH) détermine le taux d'incapacité de l'enfant. Les conditions à
            remplir dépendent de ce taux :
          </p>
          <ul>
            <li>soit avoir un taux d’incapacité de 80 % ou de plus de 80 %</li>
            <li>
              soit avoir un taux d’incapacité entre 50 % et moins de 80 % et
              avoir besoin d’accompagnement et de soins particuliers
            </li>
          </ul>
          <p><strong>Condition de résidence</strong></p>
          <p>Etre résident Français</p>
          <p><strong>Revenus non cumulables avec l’AEEH</strong></p>
          <p>
            L'allocation d'éducation de l’enfant handicapé n'est pas dûe lorsque
            l'enfant est placé en internat avec prise en charge intégrale des
            frais de séjour par l'assurance maladie, l'Etat ou l'aide sociale,
            sauf pour les périodes de congés ou de suspension de la prise en
            charge.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="container-list-items-aides">
    <div class="title-list-item-aide"><h4>Aides pour les enfants</h4></div>

    <div class="container-item-aide">
      <div class="content-item-aide">
        <h5>PAJE (Prestation d'accueil du jeune enfant )</h5>
        <p>
          L'allocation de base de la prestation d'accueil du jeune enfant (PAJE)
          a pour objet d'aider à assurer les dépenses liées à l'entretien et
          l'éducation d'un enfant dès sa naissance.
        </p>
      </div>
      <div class="content-btn-plus">
        <button class="btn btn-primary" (click)="paje.toggle()">
          En savoir plus
        </button>
      </div>
    </div>
    <div #paje="ngbCollapse" [(ngbCollapse)]="isCollapsedPaje">
      <div class="card">
        <div class="card-body">
          <p><strong>Condition d'âge</strong></p>
          <p>Elle est destinée aux parents d'un enfant de moins de 3 ans</p>
          <p>
            <strong>À noter : </strong>Une prime est également versée à la
            naissance de l’enfant (sous réserve de respecter également le
            plafond de ressources)
          </p>
          <p>
            <strong>Conditions de ressources : </strong>La prestation d'accueil
            du jeune enfant (<strong>Paje</strong>) est versée sur l’année N en
            cours aux familles qui élèvent un enfant de moins de trois ans dont
            les <strong>ressources</strong> de l'année N-2 ne dépassent pas
            certains plafonds.
          </p>
          <p>
            Si vous remplissez les conditions exigées, vous pouvez faire votre
            demande de paje à la CAF en lui adressant
            <a
              href="https://droit-finances.commentcamarche.com/download/telecharger-42-cerfa-11423-06-declaration-de-situation-caf-2017"
              >le formulaire Cerfa 11423 (déclaration de situation)</a
            >
            et
            <a
              href="https://droit-finances.commentcamarche.com/download/telecharger-59-cerfa-10397-declaration-de-ressources-caf-2017"
              >le formulaire Cerfa 10397 (déclaration de ressources)</a
            >
          </p>
          <p><strong>Condition de résidence</strong></p>
          <p>Vous devez résider régulièrement en France.</p>
          <p>
            Vous résidez en France de manière stable si vous vous trouvez dans
            <strong>l'une ou l'autre</strong> de ces situations :
          </p>
          <ul>
            <li>
              Vous avez votre <strong>foyer permanent</strong> en France. Le
              foyer désigne le lieu où vous habitez normalement, c'est-à-dire le
              lieu de votre résidence habituelle, à condition que cette
              résidence en France ait un caractère permanent.
            </li>
            <li>
              Le lieu de votre <strong>séjour principal</strong> se trouve en
              France. C'est le cas si vous séjournez en France pendant plus de 6
              mois (ou 180 jours) l'année concernée.
            </li>
          </ul>

          <p><strong>Revenus non cumulables avec la PAJE</strong></p>
          <p>
            L’allocation de la PAJE (Prestation d'Accueil du Jeune Enfant) ne
            peut pas se cumuler avec l’allocation de la CMG (Complément de libre
            choix du Mode de Garde)
          </p>
          <p>
            Par contre elle peut être cumulée avec les Allocations familiales.
          </p>
        </div>
      </div>
    </div>

    <div class="container-item-aide">
      <div class="content-item-aide">
        <h5>CMG (Choix du Mode de Garde) - Dispositif PAJE</h5>
        <p>
          Le complément de libre choix du Mode de Garde (CMG) vise à aider les
          parents qui engagent des dépenses de garde de l’enfant, lorsqu’ils ont
          recours à une assistante maternelle, une garde à domicile ou une
          structure habilitée.
        </p>
      </div>
      <div class="content-btn-plus">
        <button class="btn btn-primary" (click)="cmg.toggle()">
          En savoir plus
        </button>
      </div>
    </div>
    <div #cmg="ngbCollapse" [(ngbCollapse)]="isCollapsedCmg">
      <div class="card">
        <div class="card-body">
          <p><strong>Condition d'âge</strong></p>
          <p>Elle est destinée aux parents d'un enfant de moins de 6 ans</p>
          <p>
            <strong>À noter : </strong>Pour bénéficier du complément libre choix
            de mode de garde (CMG), vous devez faire appel à une association,
            micro-crèches ou une entreprise habilitée employant des assistantes
            maternelles agréées (votre enfant est gardé au domicile de
            l'assistante maternelle). Les crèches traditionnelles ne sont pas
            concernées par cette aide.
          </p>
          <p>
            <strong>Conditions de ressources :</strong> Le montant varie selon
            la situation familiale et les ressources du foyer L’aide est soumise
            à condition de ressources et les plafonds varient en fonction du
            nombre d’enfants à votre charge. Les revenus pris en compte pour le
            calcul du CMG sont ceux de l’année N-2.
          </p>
          <p><strong>Condition de résidence</strong></p>
          <p>Vous devez résider régulièrement en France.</p>
          <p>
            Vous résidez en France de manière stable si vous vous trouvez dans
            <strong>l'une ou l'autre</strong> de ces situations :
          </p>
          <ul>
            <li>
              Vous avez votre <strong>foyer permanent</strong> en France. Le
              foyer désigne le lieu où vous habitez normalement, c'est-à-dire le
              lieu de votre résidence habituelle, à condition que cette
              résidence en France ait un caractère permanent.
            </li>
            <li>
              Le lieu de votre <strong>séjour principal</strong> se trouve en
              France. C'est le cas si vous séjournez en France pendant plus de 6
              mois (ou 180 jours) l'année concernée.
            </li>
          </ul>
          <p><strong>Revenus non cumulables avec la CMG</strong></p>
          <p>
            L’allocation de la CMG (Complément de libre choix du Mode de Garde)
            ne peut pas se cumuler avec l’allocation de la PAJE (Prestation
            d'Accueil du Jeune Enfant)
          </p>
          <p>
            Par contre elle peut être cumulée avec les Allocations familiales.
          </p>
        </div>
      </div>
    </div>

    <div class="container-item-aide">
      <div class="content-item-aide">
        <h5>
          PreParE (Prestation Partagée d'education de l'Enfant) - Dispositif
          PAJE
        </h5>
        <p>
          Cette aide (Prestation Partagée d’éducation de l’Enfant) est attribuée
          lorsque les parents cessent ou réduisent leur activité professionnelle
          pour élever l’enfant.
        </p>
      </div>
      <div class="content-btn-plus">
        <button class="btn btn-primary" (click)="prepare.toggle()">
          En savoir plus
        </button>
      </div>
    </div>
    <div #prepare="ngbCollapse" [(ngbCollapse)]="isCollapsedPrepare">
      <div class="card">
        <div class="card-body">
          <p><strong>Condition d'âge</strong></p>
          <p>Elle est destinée aux parents d'un enfant de moins de 3 ans</p>
          <p>
            <strong>À noter :</strong> La PreParE peut être attribuée à un seul
            parent ou aux deux à condition d’avoir réduit ou cesser leur
            activité. Ils peuvent la percevoir en même temps ou successivement.
          </p>
          <p>
            <strong>Conditions de ressources :</strong> Vous devez justifier
            d'<strong>au moins 8 trimestres</strong> de cotisation sur une
            période de référence précédant la naissance, l'adoption ou l'accueil
            de l'enfant concerné. La durée de versement de l’allocation varie en
            fonction du nombre d'enfant(s) à charge.
          </p>
          <p>
            La demande de PreParE s’effectue au moyen du formulaire
            <a
              href="https://demarchesadministratives.fr/formulaires/demande-de-prestation-partagee-deducation-de-lenfant-prepare"
              >cerfa 12324 05</a
            >
          </p>
          <p><strong>Condition de résidence</strong></p>
          <p>Vous devez résider régulièrement en France.</p>
          <p>
            Vous résidez en France de manière stable si vous vous trouvez dans
            l'une ou l'autre de ces situations :
          </p>
          <ul>
            <li>
              Vous avez votre <strong>foyer permanent</strong> en France. Le
              foyer désigne le lieu où vous habitez normalement, c'est-à-dire le
              lieu de votre résidence habituelle, à condition que cette
              résidence en France ait un caractère permanent.
            </li>
            <li>
              Le lieu de votre <strong>séjour principal</strong> se trouve en
              France. C'est le cas si vous séjournez en France pendant plus de 6
              mois (ou 180 jours) l'année concernée.
            </li>
          </ul>
          <p><strong>Revenus non cumulables avec la PreParE</strong></p>
          <p>
            La prestation partagée d’éducation de l’enfant (PreParE), qu’elle
            soit perçue à taux plein ou à taux partiel, n’est pas cumulable avec
            les <strong>indemnités de congés payés</strong> et le
            <strong>complément familial</strong>. Les parents doivent donc
            choisir entre les 2.
          </p>
          <p>
            La PreParE ne peut pas non plus être cumulée avec les
            <strong>allocations chômage</strong>.
          </p>
          <p>
            La PreParE n’est pas non plus cumulable avec les
            <strong
              >indemnités de congé maternité, paternité ou d’adoption</strong
            >, sauf pour les parents qui ont un enfant à charge et seulement
            lors du premier mois de versement de la prestation
          </p>
          <p>
            Enfin, il n’est pas possible de cumuler la PreParE et les
            <strong
              >indemnités de congé de maladie ou d’accident du travail</strong
            >, sauf pour les parents ayant un enfant à charge et uniquement
            pendant le premier mois de versement de la prestation
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
