<div class="container-presentation-temoignages">
  <ngb-carousel [showNavigationArrows]="false">
    <ng-template ngbSlide *ngFor="let item of listTemoignages">
      <div class="temoignage-content">
        {{ item.temoignage }}
      </div>
      <div class="temoignage-nom-client">{{ item.nom_client }}</div>
      <div class="marge-before-controls"></div>
    </ng-template>
  </ngb-carousel>
</div>
