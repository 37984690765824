

<!--
<div>
      <div class="list-group d-flex justify-content-center" *ngIf="servResp === true">
        <div *ngFor='let item of arrayAWithoutDouble ;let i = index'>

          <div class="row g-0 bg-light position-relative" style="border: 2px solid #c84f94; margin-bottom: 2%;">
            <div class="col-md-12 p-4 ps-md-0">
              <h5 class="mt-0">{{strongArray[i]}}</h5>
              <p>{{ arrayAdresse[i] }}</p>
              <a href="{{arrayAWithoutDouble[i]}}" class="stretched-link">Link</a>
            </div>
          </div>
        </div>

      </div>
    </div>
    Integration à faire depuis la partie backend
-->
<script src="https://polyfill.io/v3/polyfill.min.js?features=default"></script>
<script src="https://maps.googleapis.com/maps/api/js?callback=ini"
async defer></script>
<script type="text/javascript"
src="https://ajax.googleapis.com/ajax/libs/jquery/1.5.1/jquery.min.js">
  </script>
  <script type="text/javascript"
src="http://maps.google.com/maps/api/js?sensor=false">
  </script>
<script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyA7JBtyOXPcVOGm6e2M7TQ23Po2G1MxlIE"></script>
<div class="alert alert-primary" *ngIf="!cityFound" role="alert">
  Aucune ville de ce nom n'a été trouvée.
</div>
<article
  id="post-520753"
  class="post-520753 page type-page status-publish separator"
  style="display:flex;justify-content:center;flex-direction:column"
  *ngIf="cityFound">
  <div class="post-content" style="margin-top: 1%">
  <h2 style="font-weight: bold !important; color : white; margin-bottom: 1%">Découvrez nos offres d’emploi:</h2>

</div>
<div style="margin-top: 2%;" class="row">
  <!--Iframe Betwee-->
  <div class="col-md-12">
    <iframe id="BetweenIframe"
    title="BetweenIframe"
    class="BetweenIframe"
    referrerpolicy="origin-when-cross-origin"
      src="">
    </iframe>
  </div>
  </div>
  <div style="margin-top: 2%;" class="row">
    <!--Api Google Map
    <div class="col-md-12" style="display: flex; justify-content: center;">
      <div id="map" class="googleMap"></div>
    </div>-->
  </div>

  <div style="width: 100%;">
    <div class="div-button-candidater">
      <button
        type="button"
        class="btn btn-primary candidateButton"
        style="background-color: white; color: #c84f94;"
        routerLink="recrutement"
      >
        Candidater
      </button>
    </div>
  </div>

</article>



