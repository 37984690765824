<div class="container-presentation-video2">
  <div class="title-presentation-video2">
    Vous cherchez un emploi souple, durable et bien payé ?
  </div>

  <div class="video-responsive">
    <iframe
      width="786"
      height="442"
      src="https://www.youtube.com/embed/UE1d0nkA9-g"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>

  <div class="btn-presentation-video2">
    <button class="btn btn-primary" routerLink="recrutement">
      Je candidate
    </button>
  </div>
</div>
