<div class="container-je-postule">
  <div class="div-je-postule">
    <div class="container-types-offres">
      <ul class="list-types-offres">
        <li class="item-type-offre" *ngFor="let item of listTypeOffres">
          <i [class]="item.icon"></i>
          <div class="label-type-offre no-small-screen">
            {{ item.label }}
          </div>
          <div class="label-type-offre only-small-screen">
            {{ item.label_short }}
          </div>
        </li>
      </ul>
    </div>
    <div class="container-btn-je-postule">
      <button
        type="button"
        class="btn btn-primary btn-je-postule btn-no-radius"
        routerLink="recrutement"
      >
        Je postule
      </button>
    </div>
  </div>
</div>
