import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service.ts.service';

@Component({
  selector: 'app-presentation-video2',
  templateUrl: './presentation-video2.component.html',
  styleUrls: ['./presentation-video2.component.scss'],
})
export class PresentationVideo2Component implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
