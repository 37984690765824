import { Component, OnInit } from '@angular/core';
import {
  bounceInLeftOnEnterAnimation,
  bounceInRightOnEnterAnimation,
  fadeInLeftOnEnterAnimation,
  fadeInOnEnterAnimation,
} from 'angular-animations';

@Component({
  selector: 'app-header-presentation',
  templateUrl: './header-presentation.component.html',
  styleUrls: ['./header-presentation.component.scss'],
  animations: [
    bounceInLeftOnEnterAnimation(),
    bounceInRightOnEnterAnimation(),
    fadeInOnEnterAnimation(),
  ],
})
export class HeaderPresentationComponent implements OnInit {
  listServices = [
    {
      title: "Baby Sitting & garde d'enfants",
      img: 'service-garde-enfants.png',
      color: '$primary',
      icon: 'fas fa-baby',
      description:
        "Le recrutement de baby-sitters, nurses, professeurs particuliers pour l'aide aux devoirs n'a jamais été aussi simple avec Wanteez !",
    },
    {
      title: 'Entretien à domicile / repassage',
      img: 'service-manege.png',
      color: '#3875b0',
      icon: 'fas fa-broom',
      description:
        "De nombreuses offres d'emplois dans le ménage, l'aide ménagère, la gouvernance vous attendent sur Wanteez.",
    },
    {
      title: 'Accompagnement personnes Dépendantes / Auxiliaire de Vie',
      img: 'service-personnes-agees.png',
      color: '#81879e',
      icon: 'fas fa-hands-helping',
      description:
        "Wanteez vous donne accès à des offres d'emploi d'auxiliaire de vie et d'aide à domicile, dédiées spécifiquement aux ainés et aux personnes handicapées ou dépendantes.",
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
