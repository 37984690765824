import { Inject, Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, retry } from 'rxjs/internal/operators';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  urlApi = environment.urlApi;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Access-Control-Allow-Origin': '*',
      // 'Access-Control-Allow-Headers': 'Content-Type',
      // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
      // 'Access-Control-Allow-Origin': '*',
    }),
  };

  constructor(
    private http: HttpClient,
    @Inject(DOCUMENT) private document: Document
  ) {
    let url = document.location.protocol + '//' + document.location.hostname;
    console.log('url', url);
    // Si on est sur le localhost:4200, on ne fait pas appel à l'api qui est sur la même url
    // mais à l'url de l'api qui est dans environment.ts
    if (document.location.hostname != 'localhost') {
      this.urlApi = url;
    }
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // CANDIDATS
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Création d'un candidat
   * @param candidat
   */
  createCandidat(candidat: any): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/candidat', candidat, this.httpOptions)
      .pipe(catchError(this.handleError('createCandidat', null)));
  }

  /**
   * Liste des jobs aux alentours du Candidat
   */
   getJobArround(localisation: any): Observable<any> {
    return this.http
      .get<any>(this.urlApi + '/api/candidat/JobArround', localisation, /*this.httpOptions*/)
      .pipe(catchError(this.handleError('getJobArround', null)));
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // CLIENTS
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Création d'un client
   * @param client
   */
  createClient(client: any): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/client', client, this.httpOptions)
      .pipe(catchError(this.handleError('createClient', null)));
  }

  /**
   * Signature du devis client
   * @param client
   */
  signatureDevis(client: any): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi + '/api/sign-devis-client',
        client,
        this.httpOptions
      )
      .pipe(catchError(this.handleError('signatureDevis', null)));
  }

  /**
   * Envoi du devis non signé au client
   * @param client
   */
  sendDevisClientNonSigne(client: any): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi + '/api/send-devis-client-non-signe',
        client,
        this.httpOptions
      )
      .pipe(
        catchError(
          this.handleError('signatursendDevisClientNonSigneeDevis', null)
        )
      );
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // LIST OPTIONS
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Liste des options formulaire inscription candidat
   */
  getListsOptions(): Observable<any> {
    return this.http
      .get<any>(this.urlApi + '/api/list-options/', this.httpOptions)
      .pipe(catchError(this.handleError('getListsOptions', null)));
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // HANDLE ERROR
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /**
   * Handle Error http request
   * @param operation
   * @param result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  /**
   * Log
   * @param message
   */
  private log(message: string) {
    console.log(message);
  }
}
