import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aides-et-financements',
  templateUrl: './aides-et-financements.component.html',
  styleUrls: ['./aides-et-financements.component.scss'],
})
export class AidesEtFinancementsComponent implements OnInit {
  public isCollapsedApa = true;
  public isCollapsedAspa = true;
  public isCollapsedAideMenagere = true;
  public isCollapsedPch = true;
  public isCollapsedAeeh = true;
  public isCollapsedPaje = true;
  public isCollapsedCmg = true;
  public isCollapsedPrepare = true;

  constructor() {}

  ngOnInit(): void {}
}
