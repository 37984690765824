import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service.ts.service';

@Component({
  selector: 'app-presentation-services',
  templateUrl: './presentation-services.component.html',
  styleUrls: ['./presentation-services.component.scss'],
})
export class PresentationServicesComponent implements OnInit {
  listServices = [
    {
      title: 'Enfance',
      img: 'service-garde-enfants.png',
      color: '$primary',
      icon: 'fas fa-baby',
      description:
        "Le recrutement de baby-sitters, nurses, professeurs particuliers pour l'aide aux devoirs n'a jamais été aussi simple avec Wanteez !",
    },
    {
      title: 'Jardinage',
      img: 'service-jardinier.png',
      color: '#53ae3c',
      icon: 'fas fa-seedling',
      description:
        "A la recherche d'un emploi en tant que jardinier ou paysagiste ? Wanteez est la solution qui vous aide à trouver des missions où vous pourrez faire de l'entretien de jardin, de l'élagage ou du débroussaillage.",
    },
    {
      title: 'Ménage et repassage',
      img: 'service-manege.png',
      color: '#3875b0',
      icon: 'fas fa-broom',
      description:
        "De nombreuses offres d'emplois dans le ménage, l'aide ménagère, la gouvernance vous attendent sur Wanteez.",
    },
    {
      title: 'Aide aux personnes agées',
      img: 'service-personnes-agees.png',
      color: '#81879e',
      icon: 'fas fa-hands-helping',
      description:
        "Wanteez vous donne accès à des offres d'emploi d'auxiliaire de vie et d'aide à domicile, dédiées spécifiquement aux ainés et aux personnes handicapées ou dépendantes.",
    },
  ];

  constructor(
    private viewportScroller: ViewportScroller,
    private data: DataService
  ) {}

  ngOnInit(): void {}

  public showFormulaireInscription(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
    this.data.changeStateFormulaireInscription(true);
  }
}
