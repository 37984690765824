import { Component, Input, OnInit } from '@angular/core';
import { InscriptionPost } from 'src/app/inscription/inscription-formulaire/inscription-post';

@Component({
  selector: 'app-contrat',
  templateUrl: './contrat.component.html',
  styleUrls: ['./contrat.component.scss'],
})
export class ContratComponent implements OnInit {
  formulaireContratVisible: boolean = true;

  @Input() dataClient: any;
  @Input() nationality: string;

  candidat: InscriptionPost;
  dateDay: string;

  constructor() {}

  ngOnInit(): void {
    // setTimeout(() => {
    //   this.scrollToTopOfModalContrat();
    // }, 1000);

    this.candidat = {
      nom:
        Object.values(this.dataClient)[0] +
        ', ' +
        Object.values(this.dataClient)[1],
      adresse: Object.values(this.dataClient)[2],
      codep: Object.values(this.dataClient)[3],
      ville: Object.values(this.dataClient)[4],
      email: Object.values(this.dataClient)[5],
      tel: Object.values(this.dataClient)[6],
      civilite: Object.values(this.dataClient)[7],
      birthday: Object.values(this.dataClient)[8],
      num_secu_soc: Object.values(this.dataClient)[12],
      lieu_travail: Object.values(this.dataClient)[16],
      civilite_label: 'Monsieur',
      full_adresse: '',
      poste_label: this.dataClient.poste_label,
    };

    // Si Madame, on remplace
    if (this.candidat.civilite === 'Mrs') {
      this.candidat.civilite_label = 'Madame';
    }

    this.candidat.full_adresse =
      this.candidat.adresse +
      ', ' +
      this.candidat.codep +
      ' ' +
      this.candidat.ville;

    this.candidat.birthday = this.convertDate(this.candidat.birthday);

    const d = new Date();
    this.dateDay =
      d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear();
  }

  convertDate(dateToConvert) {
    let date = dateToConvert.split('-');
    return (
      parseInt(date[2], 10) +
      '/' +
      parseInt(date[1], 10) +
      '/' +
      parseInt(date[0], 10)
    );
  }
}
