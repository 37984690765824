import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataService } from '../../services/data.service.ts.service';

@Component({
  selector: 'app-presentation-je-postule',
  templateUrl: './presentation-je-postule.component.html',
  styleUrls: ['./presentation-je-postule.component.scss'],
})
export class PresentationJePostuleComponent implements OnInit {
  listTypeOffres = [
    {
      label: 'Entretien de domicile et repassage',
      label_short: 'Entretien',
      icon: 'fas fa-home',
    },
    {
      label: 'Enfance',
      label_short: 'Enfance',
      icon: 'fas fa-baby',
    },
    {
      label: 'Accompagnement personnes âgées et dépendance',
      label_short: 'Dépendance',
      icon: 'fas fa-hands-helping',
    },
    {
      label: 'Jardin',
      label_short: 'Jardin',
      icon: 'fas fa-seedling',
    },
    {
      label: '... et nos 20 autres activités',
      label_short: 'Et plus',
      icon: 'fas fa-puzzle-piece',
    },
  ];

  constructor(
    private viewportScroller: ViewportScroller,
    private data: DataService
  ) {}

  ngOnInit(): void {}

  public showFormulaireInscription(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
    this.data.changeStateFormulaireInscription(true);
  }
}
