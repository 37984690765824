import { ViewportScroller } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../../services/data.service.ts.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';import { ApiService } from '../../services/api.service';


@Component({
  selector: 'app-header-recherche-emploi',
  templateUrl: './header-recherche-emploi.component.html',
  styleUrls: ['./header-recherche-emploi.component.scss'],
})
export class HeaderRechercheEmploiComponent implements OnInit {

  @Input() villeCandidat: any;
  @Input() latitudeCandidat: any;
  @Input() longitudeCandidat: any;
  @Input() codepCandidat: any;

  form: FormGroup;
  jobArround: boolean;
  ville: any;
  latitude: any;
  longitude: any;
  codep: any;

  constructor(
    private viewportScroller: ViewportScroller,
    private formBuilder: FormBuilder,
    private data: DataService,
    private api: ApiService,
  ) {
    //this.jobArround = false;
    this.form = this.formBuilder.group({
      codep: new FormControl('', [Validators.required]),
    });
  }

  ngOnChanges() {
    this.ville = this.villeCandidat;
    this.latitude = this.latitudeCandidat;
    this.longitude = this.longitudeCandidat;
    this.codep = this.codepCandidat;
    console.log('header');

    console.log(this.latitude);
    console.log(this.longitude);
    console.log(this.codep);

    if (this.codep !== undefined) {
      this.codep = this.codep;
      this.jobArround = true;
    }

  }

  ngOnInit(): void {}

  public showFormulaireInscription(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
    this.data.changeStateFormulaireInscription(true);
  }

  async codepSend() {

    this.codep = this.form.value;
    this.codep = this.codep.codep.substring(0, 2)

    this.jobArround = true;
  }

}
