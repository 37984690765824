import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-presentation-temoignages',
  templateUrl: './presentation-temoignages.component.html',
  styleUrls: ['./presentation-temoignages.component.scss'],
})
export class PresentationTemoignagesComponent implements OnInit {
  listTemoignages = [
    {
      temoignage:
        'Mme N. K. a été très bénéfique à ma convalescence. Vis-à-vis de son travail d’aide ménagère, pour sa qualité et son assiduité, ainsi que de sa bonne humeur, je me permets de vous recommander auprès de plusieurs autres personnes.',
      nom_client: 'M. DEBALD',
    },
    {
      temoignage:
        "Nous sommes très très satisfaits de l'intervention de Me TIPHANIE : travail bien fait,  toujours à l'heure. De plus on avait pas besoins de lui dire ce qu'il y avait à faire. Je la conseille à d'autre couples car toujours plaisante et souriante.",
      nom_client: 'M. Desnouveaux',
    },
    {
      temoignage:
        'L’intervenante qui est venue était parfaite, dynamique, rapide et travailleuse. Je vous remercie pour cette mise en place parfaite.',
      nom_client: 'C. P',
    },
    {
      temoignage:
        'Je vous contacte pour vous dire que Marion est très professionnelle et efficace, nous sommes très contents de sa prestation.',
      nom_client: 'Mme V.',
    },
    {
      temoignage:
        'Très bon travail, ponctuelle dans ses rendez-vous, ce que nous aimons mon épouse et moi-même. Très plaisante à vivre, apporte la Joie de Vivre.',
      nom_client: 'M. Desert',
    },
    {
      temoignage:
        'Travail parfait. Brigitte est exceptionnelle, elle ne laisse rien passer. Serviable, autonome, d’une très grande gentillesse et d’une amabilité incomparable. Il s’agit d’une vraie pro',
      nom_client: 'M. Wallet',
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
