import {
  Component,
  EventEmitter,
  Injectable,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import {
  NgbDate,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerI18n,
  NgbDateStruct,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/internal/operators';
import { ApiService } from '../../services/api.service';
import { DataService } from '../../services/data.service.ts.service';
import { InscriptionPost } from './inscription-post';
import * as html2pdf from 'html2pdf.js';

declare const google: any;

const I18N_VALUES = {
  fr: {
    weekdays: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
    months: [
      'Jan',
      'Fév',
      'Mar',
      'Avr',
      'Mai',
      'Juin',
      'Juil',
      'Aou',
      'Sep',
      'Oct',
      'Nov',
      'Déc',
    ],
    weekLabel: 'sem',
  },
  // other languages you would support
};

// Define a service holding the language. You probably already have one if your app is i18ned. Or you could also
// use the Angular LOCALE_ID value
@Injectable()
export class I18n {
  language = 'fr';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getWeekLabel(): string {
    return I18N_VALUES[this._i18n.language].weekLabel;
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
  readonly DELIMITER = '-';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[2], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[0], 10),
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date
      ? date.year + this.DELIMITER + date.month + this.DELIMITER + date.day
      : null;
  }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    // console.log('date.month', date.month);
    // let month = '';
    // if (date.month < 10) {
    //   month = '0' + date.month.toString();
    // }
    // console.log('month', month);
    return date
      ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year
      : '';
  }
}

@Component({
  selector: 'app-inscription-formulaire',
  templateUrl: './inscription-formulaire.component.html',
  styleUrls: ['./inscription-formulaire.component.scss'],
  providers: [
    I18n,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class InscriptionFormulaireComponent implements OnInit {
  dataServiceSubscription: Subscription;
  formulaireInscriptionVisible: boolean = false;

  @Input() contratAccept: boolean;
  @Input() villeCandidat: number;
  @Input() ville: any;
  @Input() latitudeCandidat: any;
  @Input() longitudeCandidat: any;
  @Input() codepCandidat: any;

  @Output() adressChange = new EventEmitter<any>();
  @Output() villeChange = new EventEmitter<any>();
  @Output() codepChange = new EventEmitter<any>();
  @Output() latitudeChange = new EventEmitter<any>();
  @Output() longitudeChange = new EventEmitter<any>();

  form: FormGroup;

  showLoaderForm: boolean = false;
  showLoaderContratPostuler: boolean = false;
  showRetourFormSuccess: boolean = false;
  showRetourFormError: boolean = false;
  showRetourContratSuccess: boolean = false;
  showRetourContratError: boolean = false;
  showFormErrorAge: boolean = false;

  lat = null;
  lng = null;
  geolocDone = false;

  dataClient: InscriptionPost[] = [];
  nationality: string;

  @ViewChild('placesRef') placesRef: GooglePlaceDirective;
  options: {
    types: [];
    componentRestrictions: { country: 'FR' };
  };

  listGenres = [
    {
      id: 'Mr',
      label: 'Monsieur',
    },
    {
      id: 'Mrs',
      label: 'Madame',
    },
  ];

  listNationalites = [
    {
      id: 1,
      label: 'France',
    },
  ];

  listPostes = [
    {
      id: 1,
      label: 'Aide à domicile',
    },
    {
      id: 2,
      label: "Garde d'enfants",
    },
    {
      id: 3,
      label: 'Auxiliaire de vie',
    },
    {
      id: 4,
      label: 'Aide soignante',
    },
  ];

  listTypesTransports = [
    {
      id: 1,
      label: 'Véhiculé',
    },
    {
      id: 2,
      label: 'Transports en commun',
    },
    {
      id: 3,
      label: 'Deux roues',
    },
    {
      id: 4,
      label: 'Vélo',
    },
    {
      id: 5,
      label: 'A pied',
    },
  ];

  listDureesTravails = [
    {
      id: 1,
      label: 'CDI à temps partiel',
    },
  ];

  listLieuxNaissances = [];

  maxDateNaissance = null;

  isPageRecrutement: boolean = false;

  isDisabled = (date: NgbDate, current) => {
    let dateJour = new Date();
    return (
      date.day > dateJour.getDate() &&
      date.month > dateJour.getMonth() &&
      date.year >= dateJour.getFullYear()
    );
  };

  constructor(
    private data: DataService,
    private formBuilder: FormBuilder,
    private api: ApiService,
    private modalService: NgbModal,
    private router: Router
  ) {
    this.form = this.formBuilder.group({
      nom: new FormControl(null, [Validators.required]),
      prenom: new FormControl(null, [Validators.required]),
      adresse: new FormControl(null, [Validators.required]),
      codep: new FormControl(null, [Validators.required]),
      ville: new FormControl(null, [Validators.required]),
      country: new FormControl(null, [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      tel: new FormControl('', [
        Validators.required,
        Validators.pattern(
          /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/
        ),
        Validators.minLength(10),
      ]),
      civilite: new FormControl(null, [Validators.required]),
      date_naissance: new FormControl(null, [Validators.required]),
      lieu_naissance: new FormControl(null, [Validators.required]),
      nationalite: new FormControl(null, [Validators.required]),
      poste: new FormControl(null, [Validators.required]),
      num_securite_social: new FormControl(null, []),
      num_permis_travail: new FormControl(null, []),
      type_transport: new FormControl(null, [Validators.required]),
      duree_travail: new FormControl(null, [Validators.required]),
      lieu_travail: new FormControl(null, [Validators.required]),
      conditions_ok: new FormControl(false, [Validators.requiredTrue]),
      rgpd_ok: new FormControl(false, [Validators.requiredTrue]),
    });

    let dateJour = new Date();
    this.maxDateNaissance = {
      year: dateJour.getDate(),
      month: dateJour.getMonth() + 1,
      day: dateJour.getFullYear(),
    };

    if (this.router.url == '/recrutement') {
      this.isPageRecrutement = true;
    } else {
      this.isPageRecrutement = false;
    }

    this.getListOptions();
  }

  ngOnInit(): void {
    this.dataServiceSubscription =
      this.data.formulaireInscriptionVisible$.subscribe(
        (isVisible) => (this.formulaireInscriptionVisible = isVisible)
      );
  }

  ngOnDestroy() {
    this.dataServiceSubscription.unsubscribe();
  }

  async onSubmit(contentNext) {
    let datas = this.form.value ;

    this.showFormErrorAge = false;
    if (datas.date_naissance != null) {
      let timeDiff = Math.abs(
        Date.now() - new Date(datas.date_naissance).getTime()
      );
      let age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
      if (age < 18) {
        this.showFormErrorAge = true;
        let element = document.getElementById('titleFormulaire');
        element.scrollIntoView(true);
        return false;
      }
    }

    let posteLabel = '';
    if (datas.poste == 1) {
      posteLabel = 'AIDE A DOMICILE';
    } else if (datas.poste == 2) {
      posteLabel = "GARDE D'ENFANTS";
    } else if (datas.poste == 3) {
      posteLabel = 'AUXILIAIRE DE VIE';
    } else if (datas.poste == 4) {
      posteLabel = 'AIDE SOIGNANTE';
    }
    datas.poste_label = posteLabel;

    this.dataClient = datas;

    console.warn('FORM DATAS : ', datas);
    console.warn('FORM DATAS VALID : ', this.form.valid);

    if (this.form.invalid) {
      return;
    }

    // On ajoute la latitude et la longitude
    if (this.lat == null || this.lng == null) {
      await this.getLocation();
    }
    datas.lat = this.lat;
    datas.lng = this.lng;

    this.latitudeCandidat = this.lat;
    this.longitudeCandidat = this.lng;

    this.changeLatitude(this.lat);
    this.changeLongitude(this.lng);

    console.warn('FORM DATAS : ', datas);

    var nationalityText = this.listNationalites.find((obj) => {
      if (Object.values(obj)[0] === datas.nationalite) {
        this.nationality = Object.values(obj)[1].toString();
      }
    });

    this.showRetourFormSuccess = false;
    this.showRetourContratError = false;
    this.showRetourFormError = false;
    this.showFormErrorAge = false;
    // this.show

    this.modalService.open(contentNext, { size: 'xl' });
  }

  async onSubmitContrat(contentNext) {
    let datas = this.form.value;

    console.log(datas);

    if (this.form.invalid) {
      return;
    }

    // On ajoute la latitude et la longitude
    if (this.lat == null || this.lng == null) {
      await this.getLocation();
    }
    datas.lat = this.lat;
    datas.lng = this.lng;

    this.latitudeCandidat = this.lat;
    this.longitudeCandidat = this.lng;

    this.changeLatitude(this.lat);
    this.changeLongitude(this.lng);

    console.warn('FORM DATAS : ', datas);

    this.showLoaderContratPostuler = true;
    this.showRetourContratSuccess = false;
    this.showRetourContratError = false;
    this.showRetourFormError = false;
    this.showFormErrorAge = false;
    this.showRetourFormSuccess = false;

    // On fait appel au back office pour créer le candidat dans Ximi
    this.api.createCandidat(datas).subscribe((resp) => {
      this.showLoaderContratPostuler = false;
      console.log('response', resp);
      if (resp != null) {
        if (resp.success) {
          this.showRetourFormSuccess = true;
          this.showRetourContratSuccess = true;
          this.modalService.dismissAll(contentNext);
          this.form.reset();
          setTimeout(() => {
            let element = document.getElementById('titleFormulaire');
            element.scrollIntoView(true);
          }, 200);
        } else {
          this.showRetourContratError = false;
        }
      } else {
        this.showRetourContratError = false;
      }
    });
  }

  getListOptions() {
    // On fait appel au back office pour aller chercher les options de la liste
    this.api.getListsOptions().subscribe((resp) => {
      if (resp != null && resp.success) {
        this.listNationalites = resp.datas[0].listNationalites;
        this.listTypesTransports = resp.datas[0].listTransportMode;
        this.listLieuxNaissances = resp.datas[0].listBirthCountry;
      } else {
      }
    });
  }

  async getLocation() {
    if (this.lat == null && this.lng == null) {
      if (navigator.geolocation) {
        await this.getPositionAsync()
          .then((position: GeolocationPosition) => {
            if (position) {
              this.lat = position.coords.latitude;
              this.lng = position.coords.longitude;
              console.log('lat', this.lat);
              console.log('lng', this.lng);

              this.changeLatitude(this.lat);
              this.changeLongitude(this.lng);

              this.geolocDone = true;

              let geocoder = new google.maps.Geocoder();
              let latlng = new google.maps.LatLng(this.lat, this.lng);
              let request = {
                latLng: latlng,
              };

              geocoder.geocode(request, (results, status) => {
                if (status == google.maps.GeocoderStatus.OK) {
                  console.log('results geocoder', results);
                  if (results[0] != null) {
                    for (var i = 0; i < results.length; i++) {
                      for (var j = 0; j < results[i].types.length; j++) {
                        if (results[i].types[j] == 'premise') {
                          this.handleAddressChange(results[i]);
                        }
                      }
                    }
                  } else {
                    alert('No address available');
                  }
                }
              });
            }
          })
          .catch((err: GeolocationPositionError) => {
            console.error(err);
            this.geolocDone = true;
          });
      } else {
        alert('Erreur lors de la géolocalisation de votre position.');
      }
    }
  }

  async getPositionAsync(): Promise<GeolocationPosition> {
    return new Promise((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(resolve, reject)
    );
  }

  public handleAddressChange(address: Address) {
    console.log('adress', address);
    let codeP = '';
    let ville = '';
    for (var i = 0; i < address.address_components.length; i++) {
      for (var j = 0; j < address.address_components[i].types.length; j++) {
        if (address.address_components[i].types[j] == 'street_number') {
          console.log('street_number', address.address_components[i].long_name);
          this.form.patchValue({
            adresse: address.address_components[i].long_name,
          });
          this.form.patchValue({
            lieu_travail: address.address_components[i].long_name,
          });
        }
        if (address.address_components[i].types[j] == 'route') {
          console.log('route', address.address_components[i].long_name);
          this.form.patchValue({
            adresse:
              this.form.get('adresse').value +
              ' ' +
              address.address_components[i].long_name,
          });
          this.form.patchValue({
            lieu_travail:
              this.form.get('lieu_travail').value +
              ' ' +
              address.address_components[i].long_name,
          });
        }

        if (address.address_components[i].types[j] == 'postal_code') {
          console.log('codep', address.address_components[i].long_name);
          this.form.patchValue({
            codep: address.address_components[i].long_name,
          });
          this.form.get('codep').updateValueAndValidity();
          codeP = address.address_components[i].long_name;
          this.codepChange.emit(this.codepCandidat);
        }
        if (address.address_components[i].types[j] == 'locality') {
          console.log('locality', address.address_components[i].long_name);
          this.form.get('ville').updateValueAndValidity();
          this.form.patchValue({
            ville: address.address_components[i].long_name,
          });
          ville = address.address_components[i].long_name;
          this.villeChange.emit(this.villeCandidat);
        }

        // Pays
        if (address.address_components[i].types[j] == 'country') {
          console.log('country', address.address_components[i].long_name);
          this.form.get('country').updateValueAndValidity();
          this.form.patchValue({
            country: address.address_components[i].long_name,
          });
        }
      }
    }
    // On mets à jour le lieu de travail avec l'adresse du candidat
    this.form.patchValue({
      lieu_travail:
        this.form.get('lieu_travail').value + ', ' + codeP + ' ' + ville,
    });
    this.adressChange.emit(
      address.address_components[0].long_name +
        ' ' +
        address.address_components[1].long_name
    );
  }

  public handleLieuNaissanceChange(address: Address) {
    console.log('adress', address);
    for (var i = 0; i < address.address_components.length; i++) {
      for (var j = 0; j < address.address_components[i].types.length; j++) {
        if (address.address_components[i].types[j] == 'locality') {
          console.log('locality', address.address_components[i].long_name);
          this.form.patchValue({
            lieu_naissance: address.address_components[i].long_name,
          });
        }
      }
    }
  }

  open(event, content) {
    event.stopPropagation();
    this.modalService.open(content, { size: 'xl' });
    return false;
  }

    /* A préparer pour le pdf*/
    displayPdf(){

      const element = document.getElementsByClassName('contratPDF');
      console.log(element[0]);

      html2pdf(element[0], {
        margin: [2, 2],
        filename: 'contrat.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2, letterRendering: true},
        pagebreak: {mode: '', before: '.break-before', after: '.break-after', avoid: '.break-avoid' },
        jsPDF: { unit: 'pt', format: 'letter', orientation: 'portrait' }
      });

    }

    changeVille(ville){
      this.villeChange.emit(ville);
      console.log(ville);

    };

    changeCodep(codep){
      this.codepChange.emit(codep);
      console.log(codep);

    };

    changeLatitude(lat){
      this.latitudeChange.emit(lat);
      console.log(lat);

    };

    changeLongitude(long){
      this.longitudeChange.emit(long);
      console.log(long);

    };



}
